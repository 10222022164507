import { TUTORIALS_LABEL } from 'constants/tracker';

import { SEND_TRACKING_DATA } from '../types';

export const trackOpenTutorial = (tutorialId) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: TUTORIALS_LABEL,
  gtmAction: 'Open Tutorial',
  tutorialId,
});
