import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Question } from 'phosphor-react';
import { useDispatch } from 'react-redux';

import BaseModal from 'components/common/BaseModal';
import CommonBackdrop from 'components/common/CommonBackdrop';
import { getProjectGraphDataByStatsId } from 'services/projects';
import { Loader } from 'components/common/Loader';
import { palette } from 'common/theme';
import { UNSUPPORTED_GRAPH_TYPE } from 'constants/errors';
import {
  GRAPHS_CONFIG_BY_TYPE,
  PROP_NAMES_BY_PERIODICITY,
  WIDGETS_PREPARE_FUNCTIONS_BY_PERIODICITY,
  EXTREME_ESTIMATE_TYPE,
} from 'constants/graphsConfig';
import { VCLASS_TYPE_REGEXP } from 'constants/regexp';
import {
  getExpectedWidgetsByStatsIdsString,
  sortStatsIdsByPriority,
} from 'helpers/statistics';
import ExtremeValuesModal from 'components/modals/ExtremeValuesModal';
import { CHART_CONTAINER_ID } from 'constants/graphs';
import { SUPPORT_EMAIL } from 'common/config';
import NativeLink from 'components/common/NativeLink';
import { EOL } from 'constants/common';
import { INTERNAL_SERVER_ERROR_STATUS, NOT_FOUND_STATUS } from 'constants/http';
import { trackStatsHelp } from 'ducks/trackers/actions/workzone';

import { useStyles } from './styles';

/**
 * StatsModal component.
 *
 * @note prepareFunction result normally would be passed as `annualData` or `monthlyData` props
 * if there're widgets (vclass widgets like dp or thetaw) list of widgets would be passed as `widgets` prop
 * widgets data would be passed as `${widgetName}Widget` (ex: `thetawWidget`, `dpWidget`)
 * if you want to pass any other extra props not using described above,
 * you can return `extraProps` object in your prepare function result, and it would be injected to props
 *
 * @param { function } closeModal -  handler for close modal button
 * @param { function } onError - handler for error, takes error message as argument
 * @param { string } projectId - project id
 * @param { array } statsIds - statsIds to be requested
 * @param { string } helpLink - link to help page with info about current statistics
 */
const StatsModal = ({ closeModal, projectId, statsIds, helpLink, onError }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [graphData, setGraphData] = useState({
    props: {},
    type: null,
    pngFileName: null,
    criticalProp: null,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const widgets = getExpectedWidgetsByStatsIdsString(statsIds.join(''));

    statsIds.sort(sortStatsIdsByPriority).forEach((statsId) =>
      getProjectGraphDataByStatsId({
        projectId,
        statsId,
      })
        .then((response) => {
          const graphParams = GRAPHS_CONFIG_BY_TYPE[response.type];
          if (!graphParams) {
            throw Error(UNSUPPORTED_GRAPH_TYPE);
          }
          const { periodicity, point } = response;
          const propName = PROP_NAMES_BY_PERIODICITY[periodicity];
          const [, vclassType] = VCLASS_TYPE_REGEXP.exec(response.id) || [];
          const { criticalProp = null } = graphParams;

          if (!vclassType) {
            const { pngFileName } = graphParams;
            const prepareFunction = graphParams.prepareFunctions[periodicity];
            const preparedData = prepareFunction(response);
            const extraProps = preparedData.extraProps ?? {};

            return setGraphData(({ props, type }) => ({
              criticalProp,
              pngFileName: pngFileName.replace('{{POINT}}', point?.name ?? ''),
              type: type || response.type,
              props: {
                ...props,
                ...extraProps,
                widgets,
                [propName]: preparedData,
              },
            }));
          }

          const prepareFunction =
            WIDGETS_PREPARE_FUNCTIONS_BY_PERIODICITY[periodicity];
          const { extraProps, ...preparedData } = prepareFunction(response);

          const widgetPropName = `${propName}Widget`;
          setGraphData(({ props, ...prevState }) => ({
            ...prevState,
            criticalProp,
            props: {
              ...props,
              ...(extraProps || {}),
              widgets,
              [widgetPropName]: preparedData,
            },
          }));
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error('Error while rendering stat', e);
          const errorMessage = [
            NOT_FOUND_STATUS,
            INTERNAL_SERVER_ERROR_STATUS,
          ].includes(e.status)
            ? t(`errors.graphs.status${e.status}`)
            : t('errors.common');

          const notification = (
            <div>
              {errorMessage}
              {EOL}
              Please,&nbsp;
              <NativeLink
                style={{ color: palette.lightGreen.main }}
                to={`mailto:${SUPPORT_EMAIL}`}
              >
                <strong>{t('common.contactUs')}</strong>
              </NativeLink>
            </div>
          );

          onError(notification);
        })
    );
    // eslint-disable-next-line
  }, []);

  const { props, type, criticalProp, pngFileName } = graphData;
  const isLoaded = criticalProp
    ? !!props[criticalProp]
    : !!Object.keys(props).length;

  if (!isLoaded) {
    return (
      <CommonBackdrop open>
        <Loader center color={palette.lightGrey.main} />
      </CommonBackdrop>
    );
  }

  const { component: GraphComponent } = GRAPHS_CONFIG_BY_TYPE[type];
  const isExtreme = type === EXTREME_ESTIMATE_TYPE;

  const ModalComponent = isExtreme ? ExtremeValuesModal : BaseModal;
  const modalProps = isExtreme
    ? {
        closeModal,
        width: props.modalWidth,
      }
    : {
        onClose: closeModal,
        modalClass: classes.container,
      };

  const handleLinkClick = () => {
    dispatch(trackStatsHelp('Stats modal', helpLink));
  };

  return (
    <ModalComponent {...modalProps}>
      <div className={classes.helpLinkContainer}>
        <Link to={helpLink} target="_blank" onClick={handleLinkClick}>
          <Question size={30} />
        </Link>
      </div>
      <div id={CHART_CONTAINER_ID}>
        <GraphComponent
          {...props}
          projectId={projectId}
          statsIds={statsIds}
          pngFileName={pngFileName}
        />
      </div>
    </ModalComponent>
  );
};

StatsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  helpLink: PropTypes.string,
  statsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

StatsModal.defaultProps = {
  helpLink: '',
};

export default React.memo(StatsModal);
