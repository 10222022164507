import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ArrowsHorizontal, Clock } from 'phosphor-react';
import Fade from '@material-ui/core/Fade';

import { useUrlQuery } from 'hooks/useUrlQuery';
import { trackNumericalSimulations } from 'ducks/trackers/actions/datavalidation';
import { prepareGridForDatasetTable } from 'helpers/zones';
import { toSnakeUpperCase } from 'helpers/camelizer';
import DatasetIcon from 'components/common/DatasetIcon';

import { useStyles } from './styles';

/**
 * DatasetCard
 * @param {Object} item Dataset info
 * @param {array} zoneDatasetList List of Metadata for dataset
 * @param {boolean} selected
 * @returns {jsx}
 */
const DatasetCard = ({ item, zoneDatasetList, selected }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { setUrlQuery } = useUrlQuery();
  const [datasetMetadata, setDatasetMetadata] = useState(null);

  /* Init zoneDataset info for selected dataset */
  useEffect(() => {
    const selectedDataset = zoneDatasetList.find(
      (dataset) => dataset.field === toSnakeUpperCase(item.id)
    );
    if (selectedDataset) {
      const selectedDatasetTable = prepareGridForDatasetTable(selectedDataset);
      setDatasetMetadata(selectedDatasetTable);
    }
  }, [zoneDatasetList, item]);

  const onSelectDataset = useCallback(() => {
    setUrlQuery({ ns: item.id });
    dispatch(trackNumericalSimulations(item.id));
  }, []);

  return (
    datasetMetadata && (
      <Fade timeout={800} in>
        <div
          id={item.id}
          className={classNames(classes.datasetCard, {
            [classes.datasetCardSelected]: selected,
          })}
          onClick={onSelectDataset}
        >
          <div className={classes.datasetCardHeader}>
            <div className={classes.datasetCardHeaderSection}>
              <div className={classes.datasetIconContainer}>
                <DatasetIcon id="database" />
              </div>
              <div className={classes.datasetCardHeaderLabel}>
                <div>Dataset</div>
                <div>{datasetMetadata.name}</div>
              </div>
            </div>
            <div className={classes.datasetCardHeaderSection}>
              <div className={classes.datasetIconContainer}>
                <DatasetIcon id={item.id} />
              </div>
              <div className={classes.datasetCardHeaderLabel}>
                <div>Simulations</div>
                <div>{item.name}</div>
              </div>
            </div>
          </div>
          <div className={classes.datasetCardContent}>
            {/* section 1 */}
            <div className={classes.datasetCardContentSection}>
              {/* spatial extent */}
              <div className={classes.datasetCardContentSectionItemContainer}>
                <div>
                  <ArrowsHorizontal size={24} color="#3643BA" />
                </div>
                <div className={classes.datasetCardContentSectionItem}>
                  <span className={classes.datasetCardContentSectionItemTitle}>
                    {t('dataset.spatialExtent')}
                  </span>
                  <span>
                    {t('dataset.coordinateRange', {
                      min: datasetMetadata.minCoordinates.lat,
                      max: datasetMetadata.maxCoordinates.lat,
                    })}
                  </span>
                  <span>
                    {t('dataset.coordinateRange', {
                      min: datasetMetadata.minCoordinates.lng,
                      max: datasetMetadata.maxCoordinates.lng,
                    })}
                  </span>
                </div>
              </div>
              {/* period covered */}
              <div className={classes.datasetCardContentSectionItemContainer}>
                <div>
                  <DatasetIcon id="calendar" />
                </div>
                <div className={classes.datasetCardContentSectionItem}>
                  <span className={classes.datasetCardContentSectionItemTitle}>
                    {t('dataset.periodCovered')}
                  </span>
                  <span>
                    {datasetMetadata.dateBegin}
                    {' - '}
                  </span>
                  <span>{datasetMetadata.dateEnd}</span>
                </div>
              </div>
              {/* spatial resolution */}
              <div className={classes.datasetCardContentSectionItemContainer}>
                <div>
                  <DatasetIcon id="resolution" />
                </div>
                <div className={classes.datasetCardContentSectionItem}>
                  <span className={classes.datasetCardContentSectionItemTitle}>
                    {t('dataset.spatialResolution')}
                  </span>
                  <span>
                    {datasetMetadata.spatialResolution}
                    {datasetMetadata.spatialResolutionUnits}
                  </span>
                </div>
              </div>
              {/* temporal resolution */}
              <div className={classes.datasetCardContentSectionItemContainer}>
                <div>
                  <Clock size={24} color="#3643BA" />
                </div>
                <div className={classes.datasetCardContentSectionItem}>
                  <span className={classes.datasetCardContentSectionItemTitle}>
                    {t('dataset.temporalResolution')}
                  </span>
                  <span>
                    {datasetMetadata.timeStep}
                    {datasetMetadata.timeStepUnits}
                  </span>
                </div>
              </div>
            </div>
            {/* section 2 */}
            <div className={classes.datasetCardContentSection}>
              {/* origin */}
              <div className={classes.datasetCardContentSectionItemContainer}>
                <div className={classes.datasetCardContentNoIcon}></div>
                <div className={classes.datasetCardContentSectionItem}>
                  <span className={classes.datasetCardContentSectionItemTitle}>
                    {t('dataset.origin')}
                  </span>
                  <span>{datasetMetadata.origin}</span>
                </div>
              </div>
            </div>
            {/* notes */}
            <div className={classes.datasetCardContentFullSection}>
              <div className={classes.datasetCardContentNoIcon}></div>
              <div className={classes.datasetCardContentSectionItem}>
                <span className={classes.datasetCardContentSectionItemTitle}>
                  {t('dataset.notes')}
                </span>
                <span>{datasetMetadata.notes}</span>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    )
  );
};

/**
 * DatasetCardList
 * @param {array} numericalSimulationsItems List of dataset
 * @param {string} numericalSimulation Selected dataset
 * @param {array} zoneDatasetList List of Metadata for dataset
 * @returns {jsx}
 */
const DatasetCardList = ({
  numericalSimulationsItems,
  numericalSimulation,
  zoneDatasetList,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.datasetCardsListContainer}>
      <div className={classes.datasetCardsList}>
        {numericalSimulationsItems.map(
          (item) =>
            item.id === numericalSimulation && (
              <DatasetCard
                item={item}
                key={item.id}
                zoneDatasetList={zoneDatasetList}
                selected
              ></DatasetCard>
            )
        )}
        {numericalSimulationsItems.map(
          (item) =>
            item.id !== numericalSimulation && (
              <DatasetCard
                item={item}
                key={item.id}
                zoneDatasetList={zoneDatasetList}
              ></DatasetCard>
            )
        )}
      </div>
    </div>
  );
};

DatasetCardList.propTypes = {
  numericalSimulationsItems: PropTypes.array.isRequired,
  numericalSimulation: PropTypes.string.isRequired,
  zoneDatasetList: PropTypes.array.isRequired,
};

export default React.memo(DatasetCardList);
