import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  row: {
    marginBottom: 5,
  },
  fullWidth: {
    width: '100%',
  },
  coordinateText: {
    // Overwrite leaflet styles
    margin: '10px 0 !important',
    textAlign: 'center',
    fontSize: 12,
  },
  coordinateField: {
    '& .Mui-disabled': {
      background: palette.lightGrey.main,
    },
  },
  coordinateFieldNumber: {
    position: 'absolute',
  },
  helperText: {
    height: 20,
    wordWrap: 'break-word',
  },
}));
