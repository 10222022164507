import uniq from 'lodash/uniq';
import get from 'lodash/get';

import {
  INVALID_GRAPH_DATA,
  INVALID_LEVEL_TYPE,
  INVALID_MIN_MAX_RANGE,
  INVALID_NUMBER_FORMAT,
  INVALID_TICKS_AMOUNT,
  INVALID_VALUES_FORMAT,
  EQUAL_XY_VALUES_AMOUNT_EXPECTED,
} from 'constants/errors';
import {
  ALTITUDE_TYPE,
  DEPTH_TYPE,
  INFINITY_LABEL,
  RAW_DATA_TITLE_PATH,
  VALUES_KEY,
  WIND_STATS_ID_PATTERN,
  STATS_API_UNITS_TO_COMMON_UNITS,
  LEGAL_GRID_TICKS,
  VALID_GRID_TICKS_AMOUNT,
  XY_NESTING_ORDER,
  PROBABILITY_KEYS,
  RAW_DATA_GRAPH_PARAMS_PATH,
  DEFAULT_GRAPH_CANVAS_WIDTH,
  DEFAULT_GRAPH_CANVAS_HEIGHT,
  FROM_SEA_SURFACE_POSITIVE_UP,
  FROM_SEA_SURFACE_POSITIVE_DOWN,
} from 'constants/graphs';
import { ASC } from 'constants/common';
import {
  FIRST_CAMEL_CASE_WORD_REGEXP,
  STATS_TEMPLATE_VARIABLE_REGEXP,
  UNIT_REGEXP,
} from 'constants/regexp';
import { getPrettyNumber } from 'helpers/data';
import {
  capitalizeFirstLetter,
  floatRound,
  getIntervalStringByMask,
  inRange,
} from 'helpers/common';
import { toCamelCase } from 'helpers/camelizer';

/**
 * returns intervals to show in graphs based on given possible values
 * @param { array } values
 * @param { string } mask (should contain `{from}` and `{to}`)
 * @param { number } precision
 * @param { bool } fixedMax - whether last value is max or Infinity is posiible
 * @param { bool } isCyclic - if true, last value makes interval with first
 * @returns { string[] }
 * @example in: [0, 1, 2] mask: '{from} - {to}', out: ['0.00 - 1.00', '1.00 - 2.00', '2.00 - Infinity']
 * @note empty intervals like 1 - 1 wouldn't be counted
 */
export const getIntervalsFromValues = ({
  mask = '{from} - {to}',
  values,
  fixedMax = true,
  precision = 2,
  isCyclic = false,
}) => {
  if (!Array.isArray(values)) {
    throw Error(INVALID_VALUES_FORMAT);
  }
  const firstValue = parseFloat(values[0]);
  if (!firstValue && firstValue !== 0) {
    throw Error(INVALID_NUMBER_FORMAT);
  }

  const preparedValues = uniq(values);
  if (isCyclic) {
    preparedValues.push(preparedValues[0]);
  }
  const lastIndex = preparedValues.length - 1;

  return preparedValues.reduce((acc, currentValue, index, array) => {
    const nextIndex = index + 1;
    const isLast = nextIndex > lastIndex;
    if (fixedMax && isLast) {
      return acc;
    }
    const startValue = `${(+currentValue).toFixed(precision)}`;
    const endValue = isLast
      ? INFINITY_LABEL
      : `${(+array[nextIndex]).toFixed(precision)}`;

    acc.push(getIntervalStringByMask([startValue, endValue], mask));
    return acc;
  }, []);
};

/**
 * Always returns positive angle
 * 2Pi * n angles are converted to less than 360
 * @param { number } angle
 * @returns { number }
 */
export const getSimplePositiveAngle = (angle) => {
  const positiveAngle = angle < 0 ? 360 + angle : angle;

  return positiveAngle >= 360 ? positiveAngle : positiveAngle % 360;
};

/**
 * returns label part and units part from given axis label
 * @param axisLabel
 * @returns {{label: *, units: *}}
 */
export const getPartsFromAxisLabel = (axisLabel) => {
  const [, label, units] = UNIT_REGEXP.exec(axisLabel) || [];
  return {
    label,
    units,
  };
};

/**
 * returns angles pairs(start, end) from given bisectors collection
 * @param { array } bisectors
 * @note angles between bisectors should be equal for correct results
 * @note unlike `getSectorsFromAngles` doesn't care about drawing,
 * (angles are not interpreted for clockwise direction)
 * @example
 *   in: [10, 30, 50, 70, 90, ... 350]
 *   out: [[0, 20], [20, 40], [40, 60], ...[340, 360]]
 */
export const getAnglesFromBisectorsCollection = (bisectors) => {
  const bisectorsAmount = bisectors.length;
  const sectorArc = 360 / bisectorsAmount;
  const halfArc = sectorArc / 2;

  return bisectors.map((bisector) => [
    getSimplePositiveAngle(bisector - halfArc),
    getSimplePositiveAngle(bisector + halfArc),
  ]);
};

/**
 * returns sectors by given angle values
 * @param { array } anglesValues in degrees. Should have angles for whole 2Pi round
 * @param { number } offset - offset angle, 0 by default
 * @param { string } direction, ASC by default
 * @returns { number[] }
 * @note angles shouldn't be duplicated. Last angle in array makes sector with first.
 * if angle value is greater than 360 it would be considered as -2Pi equivalent (400 -> 40)
 * @note start and end angles are converted to clockwise angles order
 * so you can correctly use them with svg paths/recharts drawing sectors (these libs draw clockwise by default)
 * @example
 *  in: [0, 120, 280]
 *  out: [
 *    { sector: 120, start: 0, end: 120 },
 *    { sector: 160, start: 120, end: 280 },
 *    { sector: 80, start: -80, end: 0 }
 *  ]
 */
export const getSectorsFromAngles = ({
  anglesValues,
  offset = 0,
  direction = ASC,
}) => {
  if (!Array.isArray(anglesValues)) {
    throw Error(INVALID_VALUES_FORMAT);
  }
  const firstValue = parseFloat(anglesValues[0]);
  if (!firstValue && firstValue !== 0) {
    throw Error(INVALID_NUMBER_FORMAT);
  }

  const isAsc = direction === ASC;
  const isDesc = !isAsc;
  const uniqAnglesValues = uniq(anglesValues);
  const preparedAnglesValues = uniqAnglesValues.map(
    (angle) => (offset + (isAsc ? angle : -angle)) % 360
  );

  preparedAnglesValues.push(preparedAnglesValues[0]);
  const lastIndex = preparedAnglesValues.length - 1;

  return preparedAnglesValues.reduce((acc, angle, index, array) => {
    const nextIndex = index + 1;
    if (nextIndex > lastIndex) {
      return acc;
    }

    const nextAngle = array[nextIndex];
    const start = {
      [isAsc]: nextAngle > angle ? angle : -(360 - angle),
      [isDesc]: nextAngle,
    }.true;
    const end = {
      [isAsc]: nextAngle,
      [isDesc]: nextAngle < angle ? angle : 360 + angle,
    }.true;
    const sector = Math.abs(end - start);
    acc.push({ sector, start, end });

    return acc;
  }, []);
};

/**
 * returns prepared title (template variables and extra spaces trimmed) from graph data
 * @param rawGraphData
 * @returns { string }
 */
export const getTitleFromRawGraphData = (rawGraphData) => {
  const title = get(rawGraphData, RAW_DATA_TITLE_PATH, '').replace(
    STATS_TEMPLATE_VARIABLE_REGEXP,
    ''
  );
  return title.trim();
};

export const getProbabilitiesForSelectFromRawGraphData = (rawGraphData) => {
  const rawGraphParams = get(rawGraphData, RAW_DATA_GRAPH_PARAMS_PATH, null);
  const probabilityKey = PROBABILITY_KEYS[rawGraphData.type];
  if (!rawGraphParams || !probabilityKey) {
    throw Error(INVALID_GRAPH_DATA);
  }
  const probabilitiesKey = toCamelCase(rawGraphParams[probabilityKey]);
  const { [VALUES_KEY]: probabilities } = rawGraphData[probabilitiesKey];

  return probabilities;
};

export const getThetawValuesForSelectFromRawGraphData = (rawGraphData) => {
  const rawGraphParams = get(rawGraphData, RAW_DATA_GRAPH_PARAMS_PATH, null);
  const { xName, yName, cName } = rawGraphParams;
  if (!rawGraphParams) {
    throw Error(INVALID_GRAPH_DATA);
  }
  const probabilitiesKey =
    cName && xName && yName ? toCamelCase(cName) : toCamelCase(yName);
  const { [VALUES_KEY]: probabilities } = rawGraphData[probabilitiesKey];

  return probabilities;
};

/**
 * Returns prepared limit values and their params for usage in graphs
 * (some graphs has variables with Limit postfix like `hsLimit`, `magwLimit`, etc.)
 * @param { object } rawGraphData - graph data from api
 * @returns {{ limitsParams: array, limitsValues: object }}
 */
export const getPreparedLimitVariablesFromGraphData = (rawGraphData) => {
  const {
    variables: { names = [], variables = [] },
  } = rawGraphData;
  if (!names.length || !variables.length) {
    throw Error(INVALID_GRAPH_DATA);
  }
  const limitKeys = names.map((name) => `${toCamelCase(name)}Limit`);
  const limitsParams = variables.reduce((acc, { name, units, hidden }) => {
    if (hidden) {
      return acc;
    }
    acc.push({
      name: `${toCamelCase(name)}Limit`,
      label: `${name} <`,
      units,
    });
    return acc;
  }, []);
  const limitsValues = limitKeys.reduce((acc, key) => {
    if (!rawGraphData[key]) {
      throw Error(INVALID_GRAPH_DATA);
    }
    acc[key] = rawGraphData[key][VALUES_KEY];
    return acc;
  }, {});

  return { limitsParams, limitsValues };
};

/**
 * returns type for levels selector depends on stats id
 * @param statsId
 * @returns {string}
 */
export const getLevelsTypeByStatsId = (statsId) =>
  statsId.substring(0, 3) === WIND_STATS_ID_PATTERN
    ? ALTITUDE_TYPE
    : DEPTH_TYPE;
/**
 * returns type for levels selector depends on stats id
 * and level convention
 * @param { string } statsId
 * @param { string } convention (as returned by backend)
 * @returns {string}
 */
export const getLevelsTypeByStatsIdAndConvention = (statsId, convention) => {
  if (convention === FROM_SEA_SURFACE_POSITIVE_UP) {
    return ALTITUDE_TYPE;
  }
  if (convention === FROM_SEA_SURFACE_POSITIVE_DOWN) {
    return DEPTH_TYPE;
  }
  return getLevelsTypeByStatsId(statsId);
};

/**
 * get title addition with current level value based on level type
 * @param { string } levelType
 * @param { number } value
 * @returns { string }
 */
export const getTitleAdditionByLevelType = (levelType, value) => {
  if (![ALTITUDE_TYPE, DEPTH_TYPE].includes(levelType)) {
    throw Error(INVALID_LEVEL_TYPE);
  }
  if (levelType === DEPTH_TYPE && +value === 0) {
    return 'Surface';
  }
  const ucFirstLevelType = `${levelType[0].toUpperCase()}${levelType.slice(1)}`;
  const preparedString = `${ucFirstLevelType} = ${getPrettyNumber(value, 1)} m`;
  return levelType === ALTITUDE_TYPE
    ? `${preparedString} from sea level`
    : preparedString;
};

/**
 * returns prepared units from statistics api
 * @param { string } units
 * @returns { string }
 */
export const getPreparedUnits = (units) =>
  STATS_API_UNITS_TO_COMMON_UNITS[units] || units;

/**
 * returns suitable grid tick for given min max values and minimal ticks amount
 * legal ticks: 1, 2, 5, 10 multipled by 10^n (like money denomination: 0.01, 1, 2, 5, 100, 200, ...)
 * @param { number } min - minValue, 0 by default
 * @param { number } max - maxValue
 * @param { number } minTicks - minimum ticks amount
 * @returns { number }
 * @example
 *   in: ({ max: 25, minTicks: 4 })  out: 5
 *   in: ({ max: 1, minTicks: 8 }) out: 0.1
 *   in: ({ max: 800, minTicks: 30 }) out: 20
 *   in: { min: 5, max: 20, minTicks: 4 } out: 2
 */
export const getGridTickByMinMax = ({ min = 0, max, minTicks }) => {
  if (max <= min) {
    throw Error(INVALID_MIN_MAX_RANGE);
  }
  const { min: minTicksValid, max: maxTicksValid } = VALID_GRID_TICKS_AMOUNT;
  if (!inRange(minTicks, minTicksValid, maxTicksValid)) {
    throw Error(INVALID_TICKS_AMOUNT);
  }

  const maxAllowedTick = floatRound((max - min) / minTicks);
  const tenPow = Math.floor(Math.log10(maxAllowedTick));
  const factor = 10 ** tenPow;
  const legalTick = LEGAL_GRID_TICKS.find(
    (tick) => maxAllowedTick >= tick * factor
  );

  return floatRound(factor * legalTick);
};

/**
 * returns path based on given xValues yValues and canvas size
 * x, y domain can be passed to limit calculated values
 * @note x and y values array should have equal sizes
 * @param { number[] } xValues
 * @param { number[] } yValues
 * @param { number } canvasWidth
 * @param { number } canvasHeight
 * @param { number[] } xDomain
 * @param { number[] } yDomain
 * @returns { string }
 */
export const getLinePathByValues = ({
  xValues,
  yValues,
  canvasWidth,
  canvasHeight,
  xDomain = [],
  yDomain = [],
}) => {
  if (xValues.length !== yValues.length) {
    throw Error(EQUAL_XY_VALUES_AMOUNT_EXPECTED);
  }
  const xMin = Number.isFinite(xDomain[0])
    ? xDomain[0]
    : Math.min.apply(null, xValues);
  const xMax = Number.isFinite(xDomain[1])
    ? xDomain[1]
    : Math.max.apply(null, xValues);
  const yMin = Number.isFinite(yDomain[0])
    ? yDomain[0]
    : Math.min.apply(null, yValues);
  const yMax = Number.isFinite(yDomain[1])
    ? yDomain[1]
    : Math.max.apply(null, yValues);

  const xScaleDivision = canvasWidth / (xMax - xMin);
  const yScaleDivision = canvasHeight / (yMax - yMin);
  let path = 'M';

  for (let i = 0; i < xValues.length; i += 1) {
    if (
      xValues[i] < xMin ||
      xValues[i] > xMax ||
      yValues[i] < yMin ||
      yValues[i] > yMax
    ) {
      continue;
    }
    path += `${floatRound((xValues[i] - xMin) * xScaleDivision, 1)},`;
    path += floatRound(canvasHeight - (yValues[i] - yMin) * yScaleDivision, 1);
    path += 'L';
  }

  return path.slice(0, -1);
};

/**
 * returns grid ticks collection based on given max value and min ticks amount
 * @note `floatRound` is necessary to avoid float calculations issues
 * @see getGridTickByMinMax method for algorithm details
 * @param { number } min - min value
 * @param { number } max - max
 * @param { number } minTicks - minimal wanted ticks amount (not including min value tick)
 * @param { boolean } valueAsMaxTick - if true, last tick would be max value, otherwise next nearest tick
 * @returns { number[] }
 */
export const getGridTicksByMinMax = ({
  min = 0,
  max,
  minTicks,
  valueAsMaxTick = false,
}) => {
  if (max <= min) {
    throw Error(INVALID_MIN_MAX_RANGE);
  }
  const { min: minTicksValid, max: maxTicksValid } = VALID_GRID_TICKS_AMOUNT;
  if (!inRange(minTicks, minTicksValid, maxTicksValid)) {
    throw Error(INVALID_TICKS_AMOUNT);
  }

  const tick = getGridTickByMinMax({ min, max, minTicks });
  const ticksAmount = Math.ceil(floatRound((max - min) / tick));
  const ticks = Array(ticksAmount)
    .fill(null)
    .reduce(
      (acc, _, index) => {
        acc.push(floatRound(min + (index + 1) * tick));
        return acc;
      },
      [floatRound(min)]
    );

  if (valueAsMaxTick) {
    ticks[ticksAmount] = floatRound(max);
  }

  return ticks;
};

/**
 * returns matrix of rectangles params by given x-axis and y-axis points (start points)
 * matrix is 2-dimensional array, by default output nesting order is x -> y
 * @note all rectangles have same width and same height. (it could be squares, but not necessarily)
 * @param { number[] } xValues
 * @param { number[] } yValues
 * @param { number } canvasWidth
 * @param { number } canvasHeight
 * @param { string } nestingOrder - nesting order x -> y or y -> x
 * @example
 *   in: { xValues: [0, 5, 10, 15], yValues: [0, 2, 4] }
 *   output: [
 *    [
 *      {width: 100, height: 110, x: 0, y: 0},
 *      {width: 100, height: 110, x: 0, y: 110},
 *      {width: 100, height: 110, x: 0, y: 220}
 *    ],
 *    [
 *      {width: 100, height: 110, x: 100, y: 0},
 *      {width: 100, height: 110, x: 100, y: 110},
 *      {width: 100, height: 110, x: 100, y: 220}
 *    ],
 *    ...
 * ]
 */
export const getRectanglesMatrixByValues = ({
  xValues,
  yValues,
  canvasWidth = DEFAULT_GRAPH_CANVAS_WIDTH,
  canvasHeight = DEFAULT_GRAPH_CANVAS_HEIGHT,
  nestingOrder = XY_NESTING_ORDER,
}) => {
  const xAmount = xValues.length;
  const yAmount = yValues.length;
  const xStep = (xValues[xValues.length - 1] - xValues[0]) / (xAmount - 1);
  const yStep = (yValues[yValues.length - 1] - yValues[0]) / (yAmount - 1);
  const width = floatRound(canvasWidth / xAmount);
  const height = floatRound(canvasHeight / yAmount);
  const yStart = canvasHeight - height;
  const yMin = yValues[0];
  const xMin = xValues[0];
  const xScaleDivision = width / xStep;
  const yScaleDivision = height / yStep;

  if (!xStep || !yStep) {
    return [];
  }

  const [parentArray, childArray] = XY_NESTING_ORDER
    ? [xValues, yValues]
    : [yValues, xValues];

  const getCoordinatesByValues =
    nestingOrder === XY_NESTING_ORDER
      ? (x, y) => ({
          x: floatRound((x - xMin) * xScaleDivision),
          y: floatRound(yStart - (y - yMin) * yScaleDivision),
        })
      : (y, x) => ({
          x: floatRound((x - xMin) * xScaleDivision),
          y: floatRound(yStart - (y - yMin) * yScaleDivision),
        });

  const matrix = [];
  for (let i = 0; i < parentArray.length; i += 1) {
    const rectangles = [];
    for (let j = 0; j < childArray.length; j += 1) {
      rectangles.push({
        ...getCoordinatesByValues(parentArray[i], childArray[j]),
        width,
        height,
      });
    }
    matrix.push(rectangles);
  }

  return matrix;
};

/**
 * returns joint selector label from given variable name
 * first camelCase part is always wanted variable name in stats api response
 * (3d api format, what can I do :( )
 * @param { string } fullVariableName
 * @returns { string }
 * @example in: 'hsBin' out: 'Hs every'
 */
export const getJointSelectLabelFromVariableName = (fullVariableName) => {
  const [name] = FIRST_CAMEL_CASE_WORD_REGEXP.exec(fullVariableName);

  return `${name[0].toUpperCase()}${name.slice(1)} every`;
};

/**
 * returns dual key based on x and y related value
 * @param x
 * @param y
 * @returns {string}
 */
export const getKeyByXY = (x, y) => `x${x}y${y}`;

/**
 * Concat axis label text
 * @param {String} [longName]
 * @param {String} [name]
 * @param {String} units
 * @return {string}
 */
const prepareReturnValueLabel = ({ longName, name, units }) =>
  `${capitalizeFirstLetter(longName || name)} [${units}]`;

/**
 * Prepare axis information for extreme charts
 * @param {Object} xVariable
 * @param {Object} yVariable
 * @return {{
 *   axisLabels: { x: string, y: string },
 *   axisInfo: { x: object, y: object }
 * }}
 */
export const getAxisDataByVariables = ({ xVariable, yVariable }) => ({
  axisInfo: {
    x: xVariable,
    y: yVariable,
  },
  axisLabels: {
    x: prepareReturnValueLabel(xVariable),
    y: prepareReturnValueLabel(yVariable),
  },
});

/**
 * Generate all ticks by given min max and custom step.
 * @param {Number} min
 * @param {Number} max
 * @param {Number} step
 * @return {number[]}
 */
export const getTicksByMinMaxAndStep = (min, max, step = 0.5) =>
  Array(Math.floor((max - min) / step) + 1)
    .fill(null)
    .map((_, index) => min + index * step);

/**
 * returns appropriate tick precision for given values range
 * @param { number } valuesRange
 * @returns {number}
 */
export const getTickPrecisionByRange = (valuesRange) =>
  ({
    true: 0,
    [valuesRange < 50]: 1,
    [valuesRange < 5]: 2,
  }.true);

/**
 * returns suitable tick formatter by values range
 * @param { number } valuesRange
 * @returns { function }
 */
export const getTickFormatterByValuesRange = (valuesRange) => {
  const precision = getTickPrecisionByRange(valuesRange);

  return precision > 0
    ? (tick) => getPrettyNumber(tick, precision)
    : Math.round;
};
