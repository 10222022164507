import { makeStyles } from '@material-ui/core/styles';

import { RED_CURSOR } from 'constants/icons';

export const useStyles = makeStyles(() => ({
  map: {
    width: '100%',
    height: `100%`,
  },
  addPointCursor: {
    cursor: `url(${RED_CURSOR}), crosshair !important`,
  },
}));
