import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, buttons }) => ({
  loader: {
    '& div': {
      margin: 5,
    },
  },
  downloadProgress: {
    marginLeft: '45%',
  },
  progressBar: {
    textAlign: 'center',
  },
  help: {
    width: '80%',
    maxHeight: '650px',
  },
  container: {
    padding: 20,
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    background: palette.primary.light,
    borderRadius: 16,
    border: `1px solid ${palette.sinay.primary['80']}`,
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    textAlign: 'justify',
    marginBottom: 0,
  },
  tutorialInfoContent: {
    fontSize: 13,
    marginBottom: 0,
    color: '#777680',
  },
  bold: {
    color: '#3643BA',
  },
  floatingButton: {
    width: '100%',
    borderRadius: 50,
    backgroundColor: '#3643BA',
    padding: '4px 12px',
  },
  fillButton: {
    width: '100%',
    borderRadius: 50,
    color: '#3643BA',
    backgroundColor: '#FFFFFF',
    padding: '4px 12px',
  },
  label: {
    color: 'white',
    fontWeight: 650,
    textTransform: 'none',
  },
  fillLabel: {
    color: '#3643BA',
    fontWeight: 650,
    textTransform: 'none',
  },
  btnContainer: {
    marginRight: '-10px',
    marginLeft: '-10px',
    display: 'flex',
    justifyContent: 'space-between',
    gap: 16,
  },
  displayStatsButton: {
    ...buttons.outlined.secondary,
    margin: '10px 0',
  },
  clearButton: {
    ...buttons.elevated.neutral,
    margin: '10px 0',
  },
}));
