import React, { memo, useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  GLOBAL_MAP_DEFAULT_POSITION,
  GLOBAL_MAP_MIN_ZOOM_POSITION,
  MAP_PROPERTIES,
  MAP_LAYERS,
  POINT_POSITION,
  DEFAULT_CENTERING_MAP_DELAY,
  EDIT_POINT_Y_OFFSET,
} from 'constants/map';
import { SEARCH_API_URL } from 'common/config';
import { centeringMapByLatLng } from 'helpers/map';
import NewProjectZonesLayers from 'components/projects/NewProject/NewProjectZonesLayers';
import ProjectMarker from 'components/maps/ProjectMarker';
import NewPointForm from 'components/projects/NewProject/NewPointForm';
import {
  Map,
  MapGraticule,
  MapFeatureGroup,
  MapMouseCoordinates,
  MapSearchControl,
  MapCustomControlLayers,
  MapPopup,
} from 'components/leaflet';
import { selectProjectsCollection } from 'ducks/projects/selectors';

import { useStyles } from './styles';

const NewProjectMap = ({
  zones,
  selectedMatchingZoneId,
  handleAddPointToList,
  handleSelectedMarker,
  pointsList,
  toggleMapDataset,
  toggleMapProject,
  handleAutoNextStepTutorialCreatePoint,
}) => {
  const classes = useStyles();
  const [newPoint, setNewPoint] = useState(null);
  const [allowForceZoom, setAllowForceZoom] = useState(false);
  const existingProjects = useSelector(selectProjectsCollection);

  const existingProjectsZones = useMemo(() => {
    const zonesIds = [];
    const existingZonesList = [];
    existingProjects.forEach((element) => {
      if (!zonesIds.includes(element.zone.id)) {
        zonesIds.push(element.zone.id);
        existingZonesList.push(element.zone);
      }
    });
    return existingZonesList;
  }, [existingProjects]);

  useEffect(() => {
    const regionalZones = zones?.filter((zone) => !zone.isWorld);
    const worldZones = zones?.filter((zone) => zone.isWorld);
    const allowZoom =
      toggleMapDataset && regionalZones.length === 0 && worldZones.length > 0;
    setAllowForceZoom(allowZoom);
  }, [toggleMapDataset, zones]);

  const handleMapClick = useCallback(
    ({ latlng, layer: { _map: map } }) => {
      const pointAlreadyExists = pointsList?.find(
        (point) => point.lat === latlng.lat && point.lng === latlng.lng
      );
      if (pointAlreadyExists) {
        handleSelectedMarker(pointAlreadyExists);
        setNewPoint(null); // reset
      } else {
        handleSelectedMarker(null); // reset
        setTimeout(centeringMapByLatLng, DEFAULT_CENTERING_MAP_DELAY, {
          map,
          latLng: latlng,
          offset: { y: EDIT_POINT_Y_OFFSET },
        });
        handleAutoNextStepTutorialCreatePoint(); // for tutorial
        setNewPoint(latlng);
      }
    },
    [setNewPoint, pointsList]
  );

  const handleAddNewPointToList = useCallback(
    (point) => {
      handleAddPointToList(point);
      setNewPoint(null); // reset
    },
    [setNewPoint]
  );

  const handleRemoveMarker = useCallback(() => {
    setNewPoint(null); // reset
  });

  return (
    <Map
      wrapperClass={classes.map}
      positionType={POINT_POSITION}
      position={
        toggleMapDataset
          ? GLOBAL_MAP_MIN_ZOOM_POSITION
          : GLOBAL_MAP_DEFAULT_POSITION
      }
      defaultLayer={MAP_LAYERS.worldImagery}
      minZoom={1}
      forceUpdateZoom={allowForceZoom}
    >
      <MapSearchControl
        url={SEARCH_API_URL}
        propertyLoc={[MAP_PROPERTIES.latitude, MAP_PROPERTIES.longitude]}
      />
      <MapCustomControlLayers />
      <MapGraticule />
      <MapFeatureGroup onClick={handleMapClick}>
        <NewProjectZonesLayers
          zones={zones}
          projectZones={existingProjectsZones}
          selectedMatchingZoneId={selectedMatchingZoneId}
          className={classes.addPointCursor}
          toggleMapDataset={toggleMapDataset}
          toggleMapProject={toggleMapProject}
        />
        {pointsList.map((point) => (
          <ProjectMarker
            key={point.lat + point.lng}
            lat={point.lat}
            lng={point.lng}
          />
        ))}
        {newPoint && (
          <ProjectMarker
            key={newPoint.lat + newPoint.lng}
            lat={newPoint.lat}
            lng={newPoint.lng}
          >
            <MapPopup isOpen onClose={handleRemoveMarker}>
              <NewPointForm
                pointsList={pointsList}
                newPoint={newPoint}
                handleAddNewPointToList={handleAddNewPointToList}
              />
            </MapPopup>
          </ProjectMarker>
        )}
      </MapFeatureGroup>
      <MapMouseCoordinates />
    </Map>
  );
};

NewProjectMap.propTypes = {
  zones: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      geom: PropTypes.string,
      isWorld: PropTypes.bool,
      isDemo: PropTypes.bool,
    })
  ).isRequired,
  selectedMatchingZoneId: PropTypes.number,
  handleAddPointToList: PropTypes.func,
  handleSelectedMarker: PropTypes.func,
  pointsList: PropTypes.array,
  toggleMapDataset: PropTypes.bool,
  toggleMapProject: PropTypes.bool,
  handleAutoNextStepTutorialCreatePoint: PropTypes.func,
};

export default memo(NewProjectMap);
