import React, { useEffect } from 'react';
import { WarningCircle } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { Grid, Button, CircularProgress, Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { ProgressLoader } from 'components/common/ProgressLoader';
import {
  selectPointTaskByIdAndType,
  selectPointTaskIsDownloading,
  selectPointTasksRunning,
} from 'ducks/points/selectors';
import {
  startUpdatePointTaskJob,
  stopUpdatePointTaskJob,
} from 'ducks/points/actions';
import { FINISHED_TASK_STATUS, RUNNING_TASK_STATUS } from 'constants/projects';

import { useStyles } from './styles';

const TaskButton = ({
  statsIds,
  title,
  pointId,
  handleOnRun,
  handleOnDownload,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const isTasksRunning = useSelector((state) =>
    selectPointTasksRunning(pointId)(state)
  );
  const taskPoint = useSelector((state) =>
    selectPointTaskByIdAndType(pointId, statsIds)(state)
  );
  const taskIsDownloading = useSelector((state) =>
    selectPointTaskIsDownloading(taskPoint?.id)(state)
  );

  useEffect(() => {
    if (isTasksRunning) {
      dispatch(startUpdatePointTaskJob(pointId));
      return () => dispatch(stopUpdatePointTaskJob());
    }
  }, [dispatch, pointId, isTasksRunning]);

  switch (taskPoint?.status) {
    case RUNNING_TASK_STATUS:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid className={classes.progressBar}>
            <ProgressLoader
              nomargin
              style={{
                top: '0px',
                left: '50px',
                fontSize: '20px',
                color: '#10416C',
              }}
              value={taskPoint?.taskInfo?.progress || 0}
              variant="determinate"
            />
          </Grid>
          <Grid item>
            <Button
              className={classes.actionButtons}
              data-stats-ids={statsIds}
              disabled
            >
              {t('points.runningStatisticButton')}
            </Button>
          </Grid>
        </Grid>
      );
    case FINISHED_TASK_STATUS:
      return taskIsDownloading ? (
        <CircularProgress size={25} className={classes.downloadProgress} />
      ) : (
        <Button
          className={classes.actionButtons}
          data-stats-ids={statsIds}
          onClick={() => {
            handleOnDownload(taskPoint.id);
          }}
        >
          {t('points.downloadButton', { format: title })}
        </Button>
      );
    default:
      return (
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Button
              className={classes.actionButtons}
              data-stats-ids={statsIds}
              onClick={handleOnRun}
            >
              {title.toUpperCase()}
            </Button>
          </Grid>
          {taskPoint?.error && (
            <Grid item>
              <Tooltip title={<h6>{taskPoint.error}</h6>} arrow>
                <WarningCircle size={24} color="error" />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      );
  }
};

export default React.memo(TaskButton);
