import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tab } from '@material-ui/core';
import { get } from 'lodash';

import DatasetCardList from 'components/projects/DataValidation/DatasetCardList';
import DatasourceSummary from 'components/projects/DataValidation/DatasourceSummary';
import ZoneInfo from 'components/projects/DataValidation/ZoneInfo';

import { useStyles } from './styles';

/**
 * DataValidationOverview
 * @param {array} numericalSimulationsItems List of dataset
 * @param {Object} zone
 * @param {array} zoneDatasetList
 * @param {string} numericalSimulation
 * @returns {jsx}
 */
const DataValidationOverview = ({
  numericalSimulationsItems,
  zone,
  zoneDatasetList,
  numericalSimulation,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [descriptionTab, setDescriptionTab] = useState('datasourceSummary');

  const handleChangeDescriptionTab = (_e) => {
    const id = _e.target.offsetParent.id
      ? _e.target.offsetParent.id
      : _e.target.id;
    setDescriptionTab(id);
  };

  const selectedZoneDataset = useMemo(
    () => get(zone, [numericalSimulation, 0]),
    [zone, numericalSimulation]
  );

  return (
    <div id="DV-overview-container">
      <DatasetCardList
        numericalSimulation={numericalSimulation}
        numericalSimulationsItems={numericalSimulationsItems}
        zoneDatasetList={zoneDatasetList}
      />
      <div className={classes.overviewContentContainer}>
        <Tab
          label={t('dataValidation.datasourceSummary')}
          key="datasourceSummary"
          id="datasourceSummary"
          selected={descriptionTab === 'datasourceSummary'}
          onClick={handleChangeDescriptionTab}
          classes={{
            root: classes.tabRoot,
            selected: classes.tabSelected,
          }}
        />
        <Tab
          label={t('dataValidation.environmentalConditions')}
          key="environmentalConditions"
          id="environmentalConditions"
          selected={descriptionTab === 'environmentalConditions'}
          onClick={handleChangeDescriptionTab}
          classes={{
            root: classes.tabRoot,
            selected: classes.tabSelected,
          }}
        />
        <div className={classes.contentContainer}>
          {descriptionTab === 'environmentalConditions' && (
            <ZoneInfo zone={zone} />
          )}
          {descriptionTab === 'datasourceSummary' && (
            <DatasourceSummary selectedZoneDataset={selectedZoneDataset} />
          )}
        </div>
      </div>
    </div>
  );
};

DataValidationOverview.propTypes = {
  numericalSimulationsItems: PropTypes.array.isRequired,
  zone: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  zoneDatasetList: PropTypes.array.isRequired,
  numericalSimulation: PropTypes.string.isRequired,
};

export default React.memo(DataValidationOverview);
