const prefix = '@@zones';

export const REQUEST_ZONES = `${prefix}/REQUEST_ZONES`;
export const REQUEST_ZONES_IF_NEED = `${prefix}/REQUEST_ZONES_IF_NEED`;
export const REQUEST_ZONES_SUCCESS = `${prefix}/REQUEST_ZONES_SUCCESS`;
export const REQUEST_ZONES_ERROR = `${prefix}/REQUEST_ZONES_ERROR`;

export const REQUEST_ZONE = `${prefix}/REQUEST_ZONE`;
export const REQUEST_ZONE_SUCCESS = `${prefix}/REQUEST_ZONE_SUCCESS`;
export const REQUEST_ZONE_ERROR = `${prefix}/REQUEST_ZONE_ERROR`;

export const REQUEST_MATCHING_ZONES = `${prefix}/REQUEST_MATCHING_ZONES`;
export const REQUEST_MATCHING_ZONES_SUCCESS = `${prefix}/REQUEST_MATCHING_ZONES_SUCCESS`;
export const REQUEST_MATCHING_ZONES_ERROR = `${prefix}/REQUEST_MATCHING_ZONES_ERROR`;

export const REQUEST_MATCHING_ZONE_DATA = `${prefix}/REQUEST_MATCHING_ZONE_DATA`;
export const REQUEST_MATCHING_ZONE_DATA_SUCCESS = `${prefix}/REQUEST_MATCHING_ZONE_DATA_SUCCESS`;
export const REQUEST_MATCHING_ZONE_DATA_ERROR = `${prefix}/REQUEST_MATCHING_ZONE_DATA_ERROR`;
export const RESET_MATCHING_ZONE_DATA = `${prefix}/RESET_MATCHING_ZONE_DATA`;
