import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
    height: '100%',
    paddingTop: 75,
  },
  sideBarContent: {
    overflowY: 'auto !important',
    height: '100%',
    padding: 20,
  },
  sideBarCustomHeight: {
    height: `calc(100% - 50px)`,
    overflowY: 'auto !important',
  },
  sideBarNoScroll: {
    overflowY: 'none',
  },
});
