import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  dimensionIcon: {
    border: `1px solid ${palette.sinay.neutral['90']}`,
    padding: 5,
    borderRadius: 5,
    width: 38,
    height: 38,
    cursor: 'pointer',
  },
  selectedDimensionIcon: {
    border: `2px solid ${palette.sinay.primary['40']}`,
    backgroundColor: palette.sinay.primary['90'],
    padding: 5,
    borderRadius: 5,
    width: 38,
    height: 38,
    cursor: 'pointer',
  },
}));
