import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, buttons }) => ({
  createProjectSection: {
    paddingBottom: 15,
    borderBottom: '1px solid #E3E1EC',
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 5,
  },
  subtitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 10,
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 3,
  },
  itemCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: 5,
    border: '1px solid #E5E7EB',
    borderRadius: 8,
    padding: 15,
    width: '100%',
  },
  selectedItemCard: {
    border: `1px solid ${palette.sinay.primary['50']}`,
    backgroundColor: '#FBF8FF',
  },
  clickableItemCard: {
    cursor: 'pointer',
  },
  disabledItemCard: {
    color: palette.sinay.neutral['40'],
    backgroundColor: palette.sinay.neutral['90'],
  },
  cardHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerDataContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  },
  itemIconTitle: {
    marginBottom: 10,
  },
  itemTitle: {
    fontSize: 14,
    fontWeight: 600,
    margin: 0,
  },
  itemSubtitle: {
    fontSize: 12,
    margin: 0,
    color: '#525252',
  },
  regionalTag: {
    fontSize: 12,
    padding: 5,
    backgroundColor: palette.sinay.secondary['90'],
    borderRadius: 4,
    color: '#404040',
    width: 86,
    textAlign: 'center',
  },
  worldwideTag: {
    fontSize: 12,
    padding: 5,
    backgroundColor: palette.sinay.tertiary['90'],
    borderRadius: 4,
    color: '#404040',
    width: 86,
    textAlign: 'center',
  },
  cursor: {
    cursor: 'pointer',
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
    alignItems: 'center',
    width: '100%',
  },
  switchItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 0,
    alignItems: 'center',
    marginBottom: 3,
    '& p': {
      marginBottom: 0,
      fontSize: 13,
    },
  },
  inactiveToggle: {
    ...buttons.outlined.neutral,
    border: '1px solid #DBD9E0',
    borderRadius: 8,
    padding: 6,
    minWidth: '50%',
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'row',
      gap: 8,
      fontSize: 13,
    },
  },
  activeToggle: {
    ...buttons.outlined.primary,
    border: '1px solid #DBD9E0',
    color: '#0F154B',
    borderRadius: 8,
    minWidth: '50%',
    padding: 6,
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'row-reverse',
      gap: 8,
      fontSize: 13,
    },
  },
  toggleBox: {
    width: 30,
    height: 30,
    borderRadius: 8,
    backgroundColor: '#fff',
    boxShadow:
      '0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px -1px rgba(16, 24, 40, 0.10)',
  },
}));
