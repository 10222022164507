import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, buttons }) => ({
  createProjectSection: {
    paddingBottom: 15,
    borderBottom: '1px solid #E3E1EC',
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 5,
  },
  subtitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 10,
  },
  noListContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    fontSize: 12,
    color: '#46464F',
  },
  pointsListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 10,
    maxHeight: 200,
    overflowY: 'auto',
    width: '100%',
  },
  itemCard: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #E5E7EB',
    borderRadius: 8,
    padding: 10,
    width: '100%',
  },
  selectedItemCard: {
    border: '3px solid #E5E7EB',
  },
  itemRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  pointDataContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  pointIcon: {
    marginBottom: 10,
  },
  pointNameContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  pointName: {
    margin: 0,
    fontSize: 14,
    fontWeight: 600,
  },
  pointCoord: {
    margin: 0,
    fontSize: 12,
  },
  iconDelete: {
    cursor: 'pointer',
  },
  warningContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 8,
    padding: 15,
    backgroundColor: palette.sinay.neutral['95'],
    width: '100%',
  },
  warningSubtitle: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
    marginBottom: 8,
    '& p': {
      fontWeight: 600,
      marginBottom: 0,
    },
  },
  warningTextContent: {
    fontSize: 13,
    marginBottom: 0,
  },
  warningHighlight: {
    fontWeight: 600,
  },
  redirectButton: {
    ...buttons.outlined.primary,
    borderRadius: 4,
    padding: '6px 12px',
  },
  warningButtonContainer: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
  },
}));
