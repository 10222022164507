import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  warningContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 8,
    padding: 15,
    backgroundColor: palette.sinay.neutral['95'],
    width: '100%',
  },
  warningSubtitle: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
    marginBottom: 8,
    '& p': {
      fontWeight: 600,
      marginBottom: 0,
    },
  },
  warningTextContent: {
    fontSize: 13,
    marginBottom: 0,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    fontSize: 12,
    color: '#46464F',
  },
}));
