import React, { memo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectUserDetails,
  selectUserShowOverviewControl,
  selectUserDismissAllTutorials,
} from 'ducks/user/selectors';
import MyProfilePageComponent from 'components/profile/MyProfilePage';
import { changeUserSettings, getUserSettings } from 'ducks/user/actions';
import { SETTINGS_KEY } from 'constants/common';

/**
 * MyProfilePage container
 * Displays user profile information
 */
const MyProfilePage = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector(selectUserDetails);

  const showOverviewControl = useSelector(selectUserShowOverviewControl);
  const dismissAllTutorialsControl = useSelector(selectUserDismissAllTutorials);

  useEffect(() => {
    dispatch(getUserSettings());
  }, [dispatch]);

  const overviewCheckboxHandler = useCallback(({ target }) => {
    const localStorageSettings = JSON.parse(localStorage.getItem(SETTINGS_KEY));
    // Reset dismiss value in local storage setting if existant
    const localStorageOverviewValue = localStorageSettings?.showOverviewControl;
    if (localStorageOverviewValue)
      delete localStorageSettings.showOverviewControl;
    // Save updated value in localstorage
    dispatch(
      changeUserSettings({
        ...localStorageSettings,
        showOverviewControl: target.checked,
      })
    );
  });

  const tutorialCheckboxHandler = useCallback(({ target }) => {
    const localStorageSettings = JSON.parse(localStorage.getItem(SETTINGS_KEY));
    // Reset dismiss value in local storage setting if existant
    const localStorageDismissValue = localStorageSettings?.dismissAllTutorials;
    if (localStorageDismissValue)
      delete localStorageSettings.dismissAllTutorials;
    // Save updated value in localstorage
    dispatch(
      changeUserSettings({
        ...localStorageSettings,
        dismissAllTutorials: target.checked,
      })
    );
  });

  return (
    <MyProfilePageComponent
      userDetails={userDetails}
      showOverviewControl={showOverviewControl}
      dismissAllTutorialsControl={dismissAllTutorialsControl}
      overviewCheckboxHandler={overviewCheckboxHandler}
      tutorialCheckboxHandler={tutorialCheckboxHandler}
    />
  );
};

export default memo(MyProfilePage);
