import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid, Typography, IconButton, Checkbox } from '@material-ui/core';
import { Trash } from 'phosphor-react';

import { useMarkerIcon } from 'hooks/useMarkerIcon';
import { LOCATIONS_DIGIT } from 'constants/projects';
import { EMPTY_ARRAY } from 'constants/common';

import { useStyles } from './styles';

/**
 * QueryPointItem - item for query points list
 * @param {Number} lat
 * @param {Number} lng
 * @param {String} name
 * @param {Number} id
 * @param {Boolean} isComputed
 * @param {Boolean} canDeletePoint
 * @param {Boolean} selected
 * @param {Boolean} disabled
 * @param {Function} onDelete
 * @param {Function} onClick
 * @param {Function} onSelect
 * @param {boolean} isChecked
 * @returns {jsx}
 */
const QueryPointItem = memo(
  ({
    lat,
    lng,
    name,
    disabled,
    canDeletePoint,
    id,
    isComputed,
    selected,
    onDelete,
    onClick,
    onSelect,
    isChecked,
  }) => {
    const classes = useStyles();
    const { iconUrl } = useMarkerIcon({ isComputed });

    return (
      <Grid
        data-id={id}
        data-name={name}
        onClick={onClick}
        wrap="nowrap"
        item
        container
        alignItems="center"
        justifyContent={canDeletePoint ? 'space-between' : 'flex-start'}
        className={classNames(classes.pointItem, {
          [classes.pointItemSelected]: selected,
        })}
      >
        <Grid item classes={{ root: classes.pointIcon }}>
          {!onSelect ? (
            <img src={iconUrl} alt={name} className={classes.iconImage} />
          ) : (
            <Checkbox value={id} onClick={onSelect} checked={isChecked} />
          )}
        </Grid>
        <Grid item className={classes.itemWrapper}>
          <Typography className={classes.itemName}>{name}</Typography>
          <Typography className={classes.itemCoordinate}>
            ({lat.toFixed(LOCATIONS_DIGIT)}, {lng.toFixed(LOCATIONS_DIGIT)})
          </Typography>
        </Grid>
        {canDeletePoint && (
          <Grid item>
            <IconButton
              data-id={id}
              data-name={name}
              disabled={disabled}
              size="small"
              edge="end"
              onClick={onDelete}
              className={classNames(classes.deleteIconButton, {
                [classes.deleteIconButtonSelectedPoint]: selected,
              })}
            >
              {selected && (
                <Trash size={20} color="white" className={classes.deleteIcon} />
              )}
              {!selected && <Trash size={20} className={classes.deleteIcon} />}
            </IconButton>
          </Grid>
        )}
      </Grid>
    );
  }
);

/**
 * QueryPointsList - component render and manage points
 * @param {Array} points
 * @param {Number} selectedMarkers
 * @param {Function} onClick
 * @param {Function} onDelete
 * @param {Boolean} disabled
 * @param {Boolean} canDeletePoint
 * @returns {jsx}
 */
const QueryPointsList = ({
  points = [],
  selectedMarkers,
  disabled,
  canDeletePoints,
  onClick,
  onDelete,
  handleMultipleSelection,
  selectedPoints,
}) => {
  const onItemClick = useCallback(
    ({ currentTarget }) =>
      onClick({
        id: currentTarget.getAttribute('data-id'),
        name: currentTarget.getAttribute('data-name'),
      }),
    [onClick]
  );
  const onItemDelete = useCallback(
    (event) => {
      event.stopPropagation();
      onDelete({
        id: event.currentTarget.getAttribute('data-id'),
        name: event.currentTarget.getAttribute('data-name'),
      });
    },
    [onDelete]
  );
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      {points.map(({ id, ...point }) => (
        <QueryPointItem
          {...point}
          onDelete={onItemDelete}
          onClick={onItemClick}
          id={id}
          key={id}
          selected={selectedMarkers.includes(id)}
          disabled={disabled}
          canDeletePoint={canDeletePoints}
          onSelect={handleMultipleSelection}
          isChecked={selectedPoints.map((p) => p.id).includes(id)}
        />
      ))}
    </Grid>
  );
};

QueryPointsList.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ),
  selectedMarkers: PropTypes.arrayOf(PropTypes.number).isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  canDeletePoints: PropTypes.bool.isRequired,
  handleMultipleSelection: PropTypes.func,
  selectedPoints: PropTypes.array,
};

QueryPointsList.defaultProps = {
  handleMultipleSelection: null,
  selectedPoints: EMPTY_ARRAY,
};

export default memo(QueryPointsList);
