import { createSelector } from 'reselect';

import { EMPTY_ARRAY, EMPTY_VALUE } from 'constants/common';
import { RUNNING_TASK_STATUS } from 'constants/projects';

export const selectPointsData = ({ points }) => points.data;

export const selectPointsError = ({ points }) => points.error || EMPTY_VALUE;

export const selectPointsIds = ({ points }) => points.ids;

export const selectBulkData = ({ points }) => points.bulk;

export const selectDownloadingTasks = ({ points }) => points.downloadingTasks;

export const selectPointsByProject = ({ points }, projectId) =>
  points.data[projectId] || EMPTY_ARRAY;

export const selectPointsNamesByProject = createSelector(
  selectPointsByProject,
  (points) => points.map(({ name }) => name)
);

export const selectPointsNamesWithoutCurrentByProject = createSelector(
  selectPointsNamesByProject,
  (_, __, pointId) => pointId,
  (points, pointId) => points.filter(({ id }) => pointId !== id)
);

export const selectPointsCollection = createSelector(
  selectPointsIds,
  selectPointsData,
  (pointsIds, pointsData) =>
    pointsIds.reduce((acc, id) => {
      acc.push(...pointsData[id]);
      return acc;
    }, [])
);

export const selectPointById = (pointId) =>
  createSelector(selectPointsCollection, (collection) =>
    collection.find(({ id }) => id === pointId)
  );

export const selectPointTasksById = (pointId) =>
  createSelector(selectPointById(pointId), (point) => point?.tasksPoints);

export const selectPointTasksByIdAndType = (pointId, selectedType) =>
  createSelector(selectPointTasksById(pointId), (tasksPoint) =>
    tasksPoint?.filter((task) => task.processType === selectedType)
  );

export const selectPointTaskByIdAndType = (pointId, selectedType) =>
  createSelector(
    selectPointTasksByIdAndType(pointId, selectedType),
    (tasksPoint) =>
      tasksPoint && tasksPoint.length ? tasksPoint[0] : undefined
  );

export const selectPointTasksRunning = (pointId) =>
  createSelector(selectPointTasksById(pointId), (tasksPoint) => {
    for (let index = 0; index < tasksPoint.length; index += 1) {
      const task = tasksPoint[index];
      if (task.status === RUNNING_TASK_STATUS) {
        return true;
      }
    }
  });

export const selectPointTaskIsRunningByIdAndType = (pointId, selectedType) =>
  createSelector(
    selectPointTasksByIdAndType(pointId, selectedType),
    (tasksPoint) =>
      tasksPoint && tasksPoint.length
        ? tasksPoint[0].status === RUNNING_TASK_STATUS
        : false
  );

export const selectPointTaskById = (pointId, taskId) =>
  createSelector(
    selectPointById(pointId),
    (point) => point?.tasksPoints?.filter((task) => task.id === taskId)[0]
  );

export const selectPointTaskIsDownloading = (taskId) =>
  createSelector(selectDownloadingTasks, (downloadingTasks) =>
    downloadingTasks.includes(taskId)
  );

export const selectPointTaskError = (pointId, taskId) =>
  createSelector(
    selectPointTaskById(pointId, taskId),
    (taskPoint) => taskPoint?.error
  );
