import {
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
  EMPTY_VALUE,
  EMPTY_ARRAY,
} from 'constants/common';
import createReducer from 'helpers/createReducer';
import { groupAndNormalizeArrayBy } from 'helpers/data';
import { prepareAndSetError } from 'helpers/apiErrors';

import {
  RESET_BULK_DATA,
  REQUEST_POINTS,
  REQUEST_POINTS_SUCCESS,
  REQUEST_POINTS_ERROR,
  REQUEST_POINT,
  REQUEST_POINT_SUCCESS,
  REQUEST_POINT_ERROR,
  DELETE_POINT_SUCCESS,
  DELETE_PROJECT_POINTS_SUCCESS,
  CREATE_POINTS_FROM_FILE_ERROR,
  CREATE_POINTS_FROM_FILE_SUCCESS,
  CREATE_POINTS_BULK_SUCCESS,
  CREATE_POINTS_BULK_ERROR,
  CREATE_POINT_ERROR,
  CREATE_POINT_SUCCESS,
  UPDATE_POINT_ERROR,
  UPDATE_POINT_SUCCESS,
  CANCEL_POINT_TASK_JOB_SUCCESS,
  DOWNLOAD_TASK,
  DOWNLOAD_TASK_SUCCESS,
  DOWNLOAD_TASK_ERROR,
} from './types';

const initialState = {
  data: EMPTY_VALUE,
  ids: [],
  error: EMPTY_VALUE,
  status: STATUS_NOT_REQUESTED,
  bulk: STATUS_NOT_REQUESTED,
  downloadingTasks: EMPTY_ARRAY,
};

const resetBulkData = (state) => ({ ...state, bulk: EMPTY_VALUE });

const setLoadingStatus = (state) => ({ ...state, status: STATUS_LOADING });

const pushProjectPoints = (state, { points, projectId }) => {
  const { [projectId]: projectPoints, ...data } = state.data;
  data[projectId] = (projectPoints ?? []).concat(points);
  return { ...state, data };
};

const updateBulkData = (state, { points, projectId }) => ({
  ...state,
  bulk: {
    projectId,
    points,
    status: STATUS_SUCCESS,
  },
});

const removePoint = (state, { pointId, projectId }) => {
  const { [projectId]: projectPoints, ...data } = state.data;
  data[projectId] = projectPoints.filter(({ id }) => id !== pointId);

  return {
    ...state,
    ids: state.ids.filter((currentId) => currentId !== pointId),
    data,
  };
};

const removeProjectPoints = (state, { projectId }) => {
  const { [projectId]: projectPoints, ...data } = state.data;
  data[projectId] = EMPTY_ARRAY;

  return {
    ...state,
    ids: state.ids.filter((currentId) =>
      projectPoints.some(({ id }) => id === currentId)
    ),
    data,
  };
};

const createPoint = (state, { point }) => {
  const projectPoints = state.data[point.project] || EMPTY_ARRAY;
  return {
    ...state,
    ids: !state.ids.includes(point.project)
      ? [...state.ids, point.project]
      : state.ids,
    data: { ...state.data, [point.project]: [...projectPoints, point] },
  };
};

const updatePoint = (state, { point }) => {
  const { [point.project]: projectPoints = [], ...data } = state.data;
  data[point.project] = [
    ...projectPoints.filter(({ id }) => id !== point.id),
    point,
  ];

  return { ...state, data };
};

const setPoints = (state, { data }) => ({
  ...state,
  ...groupAndNormalizeArrayBy(data, 'project'),
  status: STATUS_SUCCESS,
});

const setDownloadingTask = (state, { taskId }) => ({
  ...state,
  downloadingTasks: [...state.downloadingTasks, taskId],
});

const successTaskDownload = (state, { taskId }) => ({
  ...state,
  downloadingTasks: state.downloadingTasks.filter((id) => id !== taskId),
});

const errorTaskDownload = (state, { taskId }) => ({
  ...state,
  downloadingTasks: state.downloadingTasks.filter((id) => id !== taskId),
});

export default createReducer(initialState, {
  [RESET_BULK_DATA]: resetBulkData,
  [REQUEST_POINT]: setLoadingStatus,
  [REQUEST_POINTS]: setLoadingStatus,
  [REQUEST_POINTS_SUCCESS]: setPoints,
  [CREATE_POINTS_FROM_FILE_SUCCESS]: pushProjectPoints,
  [CREATE_POINTS_BULK_SUCCESS]: updateBulkData,
  [DELETE_POINT_SUCCESS]: removePoint,
  [CREATE_POINT_SUCCESS]: createPoint,
  [UPDATE_POINT_SUCCESS]: updatePoint,
  [REQUEST_POINT_SUCCESS]: updatePoint,
  [DELETE_PROJECT_POINTS_SUCCESS]: removeProjectPoints,
  [CREATE_POINT_ERROR]: prepareAndSetError,
  [UPDATE_POINT_ERROR]: prepareAndSetError,
  [REQUEST_POINTS_ERROR]: prepareAndSetError,
  [CREATE_POINTS_FROM_FILE_ERROR]: prepareAndSetError,
  [CREATE_POINTS_BULK_ERROR]: prepareAndSetError,
  [REQUEST_POINT_ERROR]: prepareAndSetError,
  [CANCEL_POINT_TASK_JOB_SUCCESS]: updatePoint,
  [DOWNLOAD_TASK]: setDownloadingTask,
  [DOWNLOAD_TASK_SUCCESS]: successTaskDownload,
  [DOWNLOAD_TASK_ERROR]: errorTaskDownload,
});
