import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Template from 'components/common/Template';
import { SUPPORT_EMAIL } from 'common/config';

import { useStyles } from './styles';

/**
 * Description about zone grid rendering.
 * @param {Object} selectedZoneDataset
 * @param {React.ReactNode} children
 * @return {jsx}
 */
const DatasourceSummary = ({ selectedZoneDataset }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      {selectedZoneDataset.description && (
        <>
          <Template
            template={selectedZoneDataset.description}
            className={classes.textContainer}
          />
        </>
      )}
      <Typography variant="h6" align="left" className={classes.subtitle}>
        {t('dataValidation.dataUsageTitle')}
      </Typography>
      <Typography className={classes.text}>
        {t('dataValidation.dataUsageWarningInfo', { email: SUPPORT_EMAIL })}
      </Typography>
    </div>
  );
};

DatasourceSummary.propTypes = {
  selectedZoneDataset: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default React.memo(DatasourceSummary);
