import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  createPointPopup: {
    width: 380,
  },
  submitButton: {
    ...theme.buttons.fill.primary,
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  row: {
    marginBottom: 5,
  },
  fullWidth: {
    width: '100%',
  },
  coordinateText: {
    // Overwrite leaflet styles
    margin: '10px 0 !important',
    textAlign: 'center',
    fontSize: 12,
  },
  coordinateField: {
    '& .Mui-disabled': {
      background: theme.palette.lightGrey.main,
    },
  },
  modalButtonRow: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  errorText: {
    color: theme.palette.sinay.error['40'],
  },
}));
