import React from 'react';
import PropTypes from 'prop-types';

import Template from 'components/common/Template';

import { useStyles } from './styles';

/**
 * Description about zone rendering.
 * @param {Object} zone
 * @return {jsx}
 */
const ZoneInfo = ({ zone }) => {
  const classes = useStyles();

  return (
    <div>
      {zone.description ? (
        <Template
          template={zone.description}
          className={classes.textContainer}
        />
      ) : (
        <div>No description</div>
      )}
    </div>
  );
};

ZoneInfo.propTypes = {
  zone: PropTypes.shape({
    description: PropTypes.string,
  }),
};

export default React.memo(ZoneInfo);
