import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, useLocation, generatePath } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import { ROUTES } from 'constants/routes';
import {
  TUTORIAL_ZONE_PROJECT_QUERY,
  TUTORIAL_ZONE_PROJECT_QUERY_CREATE_POINT,
  TUTORIAL_ZONE_PROJECT_QUERY_GET_STATISTICS_POINT_NOT_COMPUTED,
  TUTORIAL_ZONE_PROJECT_QUERY_GET_STATISTICS_POINT_COMPUTED,
  TUTORIAL_ZONE_PROJECT_ANALYZE,
  TUTORIAL_ZONE_PROJECT_ANALYZE_DISPLAY_STATISTICS,
} from 'constants/tutorials';
import ProjectMap from 'components/maps/ProjectMap';
import ProjectSideBar from 'components/projects/ProjectSidebar';
import NewPointFeatureGroup from 'components/maps/NewPointFeatureGroup';
import SelectablePointFeatureGroup from 'components/maps/SelectablePointFeatureGroup';
import SelectableOverviewPointFeatureGroup from 'components/maps/SelectableOverviewPointFeatureGroup';
import EditPointFeatureGroup from 'components/maps/EditPointFeatureGroup';
import ProjectAnalyze from 'containers/projects/ProjectAnalyze';
import ProjectReport from 'containers/projects/ProjectReport';
import PointLocations from 'containers/points/PointLocations';
import GetMapsContainer from 'containers/buttons/GetMapsContainer';
import ProjectPolygon from 'components/maps/ProjectPolygon';
import ProjectReportList from 'containers/projects/ProjectReportList';
import CreatePointTutorial from 'components/tutorials/workZone/CreatePoint';
import GetStatisticsTutorial from 'components/tutorials/workZone/GetStatistics';
import ViewStatisticsTutorial from 'components/tutorials/workZone/ViewStatistics';
import TutorialCard from 'components/tutorials/TutorialCard/index';
import DisplayStatisticsTutorial from 'components/tutorials/workZone/DisplayStatistics';

import { useStyles } from './styles';

/**
 * ProjectDashboard - component for render side bar with info and Map with interactive
 * @param {Object} project
 * @param {Array} geometries
 * @param {Array} reports
 * @param {Array} zoneTileGeometries
 * @param {Boolean} isOverviewShown
 * @param {Boolean} isRunningProject
 * @param {Boolean} isLoadingMapsData
 * @param {Boolean} allowTileGeometries
 * @param {Boolean} isManager
 * @param {Boolean} isOfferExpired
 * @param {Boolean} mapsInOffer - if has maps in offer
 * @returns {jsx}
 */
const ProjectDashboard = ({
  project,
  isOverviewShown,
  isRunningProject,
  allowTileGeometries,
  mapsInOffer,
  isManager,
  isLoadingMapsData,
  geometries,
  reports,
  zoneTileGeometries,
  isOfferExpired,
}) => {
  const classes = useStyles();
  const [selectedMarkers, setSelectedMarkers] = useState([]);
  const [openCreatePointModal, setOpenCreatePointModal] = useState(false);
  const [openTutorialCreatePoint, setOpenTutorialCreatePoint] = useState(false);
  const [openTutorialGetStatistics, setOpenTutorialGetStatistics] = useState(
    false
  );
  const [openTutorialViewStatistics, setOpenTutorialViewStatistics] = useState(
    false
  );
  const [
    openTutorialDisplayStatistics,
    setOpenTutorialDisplayStatistics,
  ] = useState(false);
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [selectedStatType, setSelectedStatType] = useState(null);
  const [selectedVariable, setSelectedVariable] = useState(null);

  const location = useLocation();

  const selectMarkerHandler = useCallback(
    ({ id }) => {
      setSelectedMarkers((prevSelectedMarkers) =>
        prevSelectedMarkers.includes(+id)
          ? prevSelectedMarkers.filter((selectedId) => selectedId !== +id)
          : [...prevSelectedMarkers, +id]
      );
    },
    [setSelectedMarkers]
  );
  const selectSingleMarkerHandler = useCallback(
    ({ id }) => {
      setSelectedMarkers(([prevId]) => (prevId === +id ? [] : [+id]));
    },
    [setSelectedMarkers]
  );
  const selectSingleMarkerOnMap = useCallback(
    ({ sourceTarget }) => {
      selectSingleMarkerHandler(sourceTarget.properties);
    },
    [selectSingleMarkerHandler]
  );
  const selectMarkerOnMap = useCallback(
    ({ sourceTarget }) => selectMarkerHandler(sourceTarget.properties),
    [selectMarkerHandler]
  );
  const selectSingleMarkerOnMapWithoutDeselect = useCallback(
    ({ sourceTarget }) => setSelectedMarkers([+sourceTarget.properties.id]),
    [setSelectedMarkers]
  );

  const handleMarkerMenuSelection = useCallback((marker) => {
    setSelectedMarkers(([prevId]) =>
      prevId === +marker.id ? [] : [+marker.id]
    );
  });
  const handleDatasetMenuSelection = useCallback((dataset) => {
    const updatedValue = dataset !== selectedDataset ? dataset : null;
    setSelectedDataset(updatedValue);
  });
  const handleTypeMenuSelection = useCallback((type) => {
    const updatedValue = type !== selectedStatType ? type : null;
    setSelectedStatType(updatedValue);
  });
  const handleVariableMenuSelection = useCallback((variable) => {
    const updatedValue =
      variable?.id !== selectedVariable?.id ? variable : null;
    setSelectedVariable(updatedValue);
  });
  const handleClearMenu = useCallback(() => {
    setSelectedMarkers([]);
    setSelectedDataset(null);
    setSelectedStatType(null);
    setSelectedVariable(null);
  });

  const handleResetTutorial = useCallback(() => {
    setOpenTutorialDisplayStatistics(false);
  });

  // If we have deleted points in selected array, we clearing array.
  useEffect(() => {
    // Remove deleted point from selected markers
    const markersWithoutDeleted = selectedMarkers.filter((markerId) =>
      project.points.some(({ id }) => id === markerId)
    );

    // Check diff between arrays
    if (markersWithoutDeleted.length !== selectedMarkers.length) {
      setSelectedMarkers(markersWithoutDeleted);
    }
    // eslint-disable-next-line
  }, [project.points, setSelectedMarkers]);

  // Handle auto next step in tutorial if click on map to create point
  const handleAutoNextStepTutorialCreatePoint = useCallback(() => {
    setOpenCreatePointModal(true);
  });

  // Handle start tutorial if click on CTA
  const handleStartTutorial = useCallback((tutorialId) => {
    if (tutorialId === TUTORIAL_ZONE_PROJECT_QUERY_CREATE_POINT)
      setOpenTutorialCreatePoint(true);
    if (
      tutorialId ===
      TUTORIAL_ZONE_PROJECT_QUERY_GET_STATISTICS_POINT_NOT_COMPUTED
    )
      setOpenTutorialGetStatistics(true);
    if (
      tutorialId === TUTORIAL_ZONE_PROJECT_QUERY_GET_STATISTICS_POINT_COMPUTED
    )
      setOpenTutorialViewStatistics(true);
    if (tutorialId === TUTORIAL_ZONE_PROJECT_ANALYZE_DISPLAY_STATISTICS)
      setOpenTutorialDisplayStatistics(true);
  });

  // Reset tutorial props when user close tutorial to be able to restart properly tutorial
  const handleCloseTutorial = useCallback((tutorialId) => {
    if (tutorialId === TUTORIAL_ZONE_PROJECT_QUERY_CREATE_POINT) {
      setOpenTutorialCreatePoint(false);
      setOpenCreatePointModal(false); // reset
    }
    if (
      tutorialId ===
      TUTORIAL_ZONE_PROJECT_QUERY_GET_STATISTICS_POINT_NOT_COMPUTED
    ) {
      setOpenTutorialGetStatistics(false);
    }
    if (
      tutorialId === TUTORIAL_ZONE_PROJECT_QUERY_GET_STATISTICS_POINT_COMPUTED
    ) {
      setOpenTutorialViewStatistics(false);
    }
    if (tutorialId === TUTORIAL_ZONE_PROJECT_ANALYZE_DISPLAY_STATISTICS) {
      setOpenTutorialDisplayStatistics(false);
    }
  });

  // Scroll to targeted element of each tutorial step
  const handleScrollIntoSideMenu = useCallback((stepIndex) => {
    if (
      stepIndex === 1 &&
      document.getElementById('projectAnalyzePointSelection')
    )
      document.getElementById('projectAnalyzePointSelection').scrollIntoView();
    if (stepIndex === 2 && document.getElementById('fields'))
      document.getElementById('fields').scrollIntoView();
    if (stepIndex === 3 && document.getElementById('statistics'))
      document.getElementById('statistics').scrollIntoView();
    if (stepIndex === 4 && document.getElementById('variables'))
      document.getElementById('variables').scrollIntoView();
    if (stepIndex === 5 && document.getElementById('displayStatistic'))
      document.getElementById('displayStatistic').scrollIntoView();
  });

  return (
    <>
      {project.points && (
        <CreatePointTutorial
          projectPoints={project.points.length}
          isDemo={project.offerInstance.isTrial}
          openCreatePointModal={openCreatePointModal}
          forceOpenTutorial={openTutorialCreatePoint}
          handleCloseTutorial={handleCloseTutorial}
        />
      )}
      <GetStatisticsTutorial
        forceOpenTutorial={openTutorialGetStatistics}
        handleCloseTutorial={handleCloseTutorial}
      />
      <ViewStatisticsTutorial
        forceOpenTutorial={openTutorialViewStatistics}
        handleCloseTutorial={handleCloseTutorial}
      />
      <DisplayStatisticsTutorial
        forceOpenTutorial={openTutorialDisplayStatistics}
        handleCloseTutorial={handleCloseTutorial}
        selectedPointId={selectedMarkers[0]}
        selectedDataset={selectedDataset}
        selectedType={selectedStatType}
        selectedVariable={selectedVariable}
        scrollIntoSideMenu={handleScrollIntoSideMenu}
      />
      <Grid
        container
        className={classes.projectContainer}
        id="ProjectDashboard"
      >
        <Grid item className={classes.sideBarContainer}>
          <ProjectSideBar
            project={project}
            customHeight={
              !mapsInOffer &&
              location.pathname ===
                generatePath(ROUTES.project, { id: project.id })
            }
          >
            <Route exact path={ROUTES.project}>
              <PointLocations
                points={project.points}
                projectId={project.id}
                onSelectMarker={selectSingleMarkerHandler}
                selectedMarkers={selectedMarkers}
                allowEditAndCompute={isManager}
                isOfferExpired={isOfferExpired}
              />
              <TutorialCard
                tutorialZone={TUTORIAL_ZONE_PROJECT_QUERY}
                projectPointsList={project.points}
                isDemo={project.offerInstance.isTrial}
                handleStartTutorial={handleStartTutorial}
              />
              {!mapsInOffer && (
                <GetMapsContainer
                  zone={project.zone}
                  projectId={project.id}
                  allowBuyMap={!allowTileGeometries}
                />
              )}
            </Route>
            <Route exact path={ROUTES.projectAnalyze}>
              <ProjectAnalyze
                points={project.points}
                projectId={project.id}
                onSelectMarker={selectSingleMarkerHandler}
                selectedMarkerId={selectedMarkers[0]}
                selectMarkerOnMenu={handleMarkerMenuSelection}
                selectDatasetOnMenu={handleDatasetMenuSelection}
                selectTypeOnMenu={handleTypeMenuSelection}
                selectVariableOnMenu={handleVariableMenuSelection}
                handleClearMenu={handleClearMenu}
                handleResetTutorial={handleResetTutorial}
              />
              <TutorialCard
                tutorialZone={TUTORIAL_ZONE_PROJECT_ANALYZE}
                projectPointsList={project.points}
                isDemo={project.offerInstance.isTrial}
                handleStartTutorial={handleStartTutorial}
              />
            </Route>
            <Route exact path={ROUTES.projectReport}>
              <ProjectReport
                points={project.points}
                projectId={project.id}
                projectName={project.name}
                onSelectMarker={selectMarkerHandler}
                selectedMarkers={selectedMarkers}
                setSelectedMarkers={setSelectedMarkers}
                allowGenerateReport={!isOfferExpired}
              />
            </Route>
          </ProjectSideBar>
        </Grid>
        <Route exact path={ROUTES.projectReport}>
          {!!reports.length && (
            <Grid item className={classes.sideSideBarContainer}>
              <ProjectReportList
                reports={reports}
                allowDeleteReport={isManager && !isOfferExpired}
                allowAddComment={!isOfferExpired}
              />
            </Grid>
          )}
        </Route>
        <Grid
          item
          className={classes.contentContainer}
          id="ProjectMapContainer"
        >
          <ProjectMap
            wrapperClass={classes.mapContainer}
            project={project}
            geometries={geometries}
            zoneTileGeometries={zoneTileGeometries}
            allowTileGeometries={!isLoadingMapsData && allowTileGeometries}
          >
            <Route exact path={ROUTES.project}>
              {isRunningProject ? (
                <ProjectPolygon project={project} id="ProjectPolygon" />
              ) : (
                <NewPointFeatureGroup
                  project={project}
                  canCreatePoint={isManager && !isOfferExpired}
                  hasTutorial
                  handleAutoNextStepTutorialCreatePoint={
                    handleAutoNextStepTutorialCreatePoint
                  }
                />
              )}
              <EditPointFeatureGroup
                project={project}
                selectedMarkers={selectedMarkers}
                onSelectMarker={selectSingleMarkerOnMapWithoutDeselect}
              />
            </Route>
            <Route exact path={ROUTES.projectAnalyze}>
              <ProjectPolygon project={project} />
              <SelectableOverviewPointFeatureGroup
                points={project.points}
                selectedMarkers={selectedMarkers}
                selectMarker={selectSingleMarkerOnMap}
                isOverviewShown={isOverviewShown}
              />
            </Route>
            <Route exact path={ROUTES.projectReport}>
              <ProjectPolygon project={project} />
              <SelectablePointFeatureGroup
                points={project.points}
                selectedMarkers={selectedMarkers}
                selectMarker={selectMarkerOnMap}
                onlyComputed
              />
            </Route>
          </ProjectMap>
        </Grid>
      </Grid>
    </>
  );
};

ProjectDashboard.propTypes = {
  geometries: PropTypes.array.isRequired,
  reports: PropTypes.array.isRequired,
  zoneTileGeometries: PropTypes.array.isRequired,
  project: PropTypes.object.isRequired,
  isRunningProject: PropTypes.bool,
  isLoadingMapsData: PropTypes.bool.isRequired,
  allowTileGeometries: PropTypes.bool,
  mapsInOffer: PropTypes.bool,
  isManager: PropTypes.bool.isRequired,
  isOfferExpired: PropTypes.bool,
};

ProjectDashboard.defaultProps = {
  isRunningProject: false,
  isOfferExpired: false,
};

export default React.memo(ProjectDashboard);
