export const API_URL = process.env.REACT_APP_API_URL || '/';

export const SEARCH_API_URL = process.env.REACT_APP_SEARCH_API_URL;

export const COOKIE_SESSION_KEY = process.env.REACT_APP_COOKIE_SESSION_KEY;

export const CSRF_TOKEN_KEY = process.env.REACT_APP_CSRF_TOKEN_KEY;

export const CSRF_HEADER_NAME = 'x-csrftoken';

export const SUPPORT_EMAIL = 'support.metocean-analytics@sinay.fr';

export const LOGIN_PAGE_MP4_URL =
  process.env.REACT_APP_LOGIN_PAGE_MP4_VIDEO_URL;
export const LOGIN_PAGE_WEBM_URL =
  process.env.REACT_APP_LOGIN_PAGE_WEBM_VIDEO_URL;

export const DEFAULT_CLOSE_NOTIFICATION_TIME = 10000;

export const UPDATE_PROJECT_TIMEOUT = 5000;

export const UPDATE_POINT_TIMEOUT = 5000;

export const UPDATE_MARINE_CONTRACTORS_TIMEOUT = 5000;

export const UPDATE_REPORT_DELAY = 5000;

export const UPDATE_SATELLITE_COMPARISON_TIMEOUT = 5000;

export const LINK_TO_ABOUT_US = process.env.REACT_APP_OPENOCEAN_ABOUT_US_LINK;

export const UPDATE_PLANNING_DELAY = 5000;

export const CHECK_HIJACK_SESSION_DELAY = 300000;

export const REACT_APP_VERSION_INFO = process.env.REACT_APP_VERSION_INFO;

export const REACT_APP_GTM_ID = process.env.REACT_APP_GTM_ID;

export const KEYCLOAK_CONF_URL = process.env.REACT_APP_KEYCLOAK_CONF_URL;
