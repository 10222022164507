import { makeStyles } from '@material-ui/core/styles';

import { theme } from 'common/theme';

export const useStyles = makeStyles(() => ({
  datasetCardsListContainer: {
    padding: 10,
    overflowX: 'scroll',
    marginBottom: 30,
    '&::-webkit-scrollbar': {
      height: 5,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      backgroundColor: theme.palette.sinay.primary['90'],
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.sinay.primary['40'],
    },
  },
  datasetCardsList: {
    width: '200%',
    overflowX: 'hidden',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    marginBottom: 10,
  },
  datasetCard: {
    minWidth: 480,
    maxWidth: 540,
    minHeight: 400,
    border: '2px solid #E3E1EC',
    borderRadius: 3,
    background: 'white',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    /* eslint-disable-next-line */
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.2), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    marginBottom: 5,
  },
  datasetCardSelected: {
    minWidth: 480,
    maxWidth: 540,
    height: 'auto',
    minHeight: 400,
    borderRadius: 3,
    background: 'white',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    border: `3px solid ${theme.palette.sinay.primary['40']}`,
    boxShadow: 'none',
    marginBottom: 5,
  },
  datasetCardHeader: {
    height: 100,
    backgroundColor: theme.palette.sinay.primary['90'],
    padding: '10px 20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    gap: 40,
  },
  datasetCardHeaderSection: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    width: '50%',
    alignItems: 'center',
  },
  datasetCardHeaderLabel: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    justifyContent: 'start',
    color: theme.palette.sinay.primary['10'],
    fontWeight: 600,
  },
  datasetIconContainer: {
    padding: 15,
    backgroundColor: 'white',
    borderRadius: 15,
  },
  datasetCardContent: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  datasetCardContentSection: {
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '20px 40px',
    paddingBottom: 15,
    borderBottom: '1px solid #e3e1ec',
  },
  datasetCardContentFullSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    gap: 15,
  },
  datasetCardContentSectionItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
    width: '42%',
  },
  datasetCardContentSectionItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    fontSize: 12,
  },
  datasetCardContentSectionItemTitle: {
    fontWeight: 600,
  },
  datasetCardContentNoIcon: {
    width: 22,
  },
}));
