import { createTheme } from '@material-ui/core/styles';

const sinayPalette = {
  primary: {
    0: '#000000',
    10: '#000866',
    20: '#07179a',
    25: '#1b28a3',
    30: '#2936af',
    35: '#3744ba',
    40: '#4451c7',
    50: '#5e6be2',
    60: '#7886fe',
    70: '#9ba4ff',
    80: '#bdc2ff',
    90: '#dfe0ff',
    95: '#f1efff',
    98: '#fbf8ff',
    99: '#fffbff',
    100: '#ffffff',
  },
  secondary: {
    0: '#000000',
    10: '#002018',
    20: '#00382c',
    25: '#004436',
    30: '#005140',
    35: '#006b56',
    40: '#116b57',
    50: '#00876d',
    60: '#00a485',
    70: '#00c19d',
    80: '#87d6bd',
    90: '#a2f2d8',
    95: '#b9ffe7',
    98: '#e6fff4',
    99: '#f3fff9',
    100: '#ffffff',
  },
  tertiary: {
    0: '#000000',
    10: '#301400',
    20: '#4f2500',
    25: '#5f2f00',
    30: '#6d390a',
    35: '#814100',
    40: '#895020',
    50: '#b86000',
    60: '#d87821',
    70: '#f8923a',
    80: '#ffb782',
    90: '#ffdcc5',
    95: '#ffede3',
    98: '#fff8f5',
    99: '#fffbff',
    100: '#ffffff',
  },
  neutral: {
    0: '#000000',
    4: '#000000',
    6: '#000000',
    10: '#1c1b1d',
    12: '#1c1b1d',
    17: '#1c1b1d',
    20: '#313031',
    22: '#313031',
    24: '#313031',
    25: '#313031',
    30: '#474648',
    35: '#474648',
    40: '#5f5e5f',
    50: '#787678',
    60: '#929091',
    70: '#adaaac',
    80: '#c9c6c7',
    87: '#dbd9e0',
    90: '#e5e1e3',
    92: '#eae7ef',
    94: '#efedf4',
    95: '#f3f0f1',
    96: '#f5f2fa',
    98: '#fbf8ff',
    99: '#fffbff',
    100: '#ffffff',
  },
  error: {
    0: '#000000',
    10: '#410002',
    20: '#690005',
    25: '#7e0007',
    30: '#93000a',
    35: '#a80710',
    40: '#ba1a1a',
    50: '#de3730',
    60: '#ff5449',
    70: '#ff897d',
    80: '#ffb4ab',
    90: '#ffdad6',
    95: '#ffedea',
    98: '#fff8f7',
    99: '#fffbff',
    100: '#ffffff',
  },
};

export const palette = {
  // sinay design system
  sinay: sinayPalette,
  primary: { main: '#3643BA', light: '#DFE0FF', middle: '#BDC2FF' },
  secondary: { main: '#33F9CD', light: '#A2F2D8', middle: '#87D6BD' },
  tertiary: { main: '#FF973F', light: '#FFDCC5', middle: '#FFB782' },
  error: { main: '#BA1A1A', light: '#FFDAD6' },

  white: { main: '#fff' },
  black: { main: '#1B1B21' },

  background: { paper: '#FAFAFA' },
  // TODO: clean

  hijack: { main: '#560319' },
  danger: { main: '#a00103', middle: '#d9534f', light: '#f2dede' },
  lightDanger: { main: '#f5eaea' },
  lightGrey: { main: '#e5e5e5', light: '#f5f5f5' },
  sandy: { main: '#eee0cd' },
  grey: {
    main: '#C0C0C0',
    middle: '#6d737f',
    light: '#999999',
    lightBlue: '#cdd3df',
  },
  blue: {
    deep: '#161FBC',
    main: '#2b809f',
    middle: '#7DA5AC',
    light: '#91C0C8',
    graph: '#3643BA',
  },
  green: {
    deep: '#105700',
    light: '#D0EED0',
  },
  links: { main: '#0078A8' },
  graph: { main: '#3643BA' },
  lightGreen: { main: '#A9C68A' },
  statuses: {
    pending: '#FF6000',
    running: '#FFFFFF',
    finished: '#439c6d',
    cancelled: '#FF6000',
    failed: '#FF6000',
  },
  deepBlueColorRange: [
    'rgb(230,0,0) 0%',
    'rgb(255,9,0) 10%',
    'rgb(248,247,0) 35%',
    'rgb(0,230,248) 65%',
    'rgb(0,0,128) 100%',
  ],
  linearColorRange: [
    'hsl(0, 80%, 50%) 0%',
    'hsl(60, 80%, 50%) 25%',
    'hsl(120, 80%, 50%) 50%',
    'hsl(180, 80%, 50%) 75%',
    'hsl(240, 80%, 50%) 100%',
  ],
  redToGreenRange: [
    'rgb(255, 0, 0) 0%',
    'rgb(255, 255, 0) 75%',
    'rgb(0, 255, 0) 90%',
    'rgb(0, 131, 9) 100%',
  ],
  graphLines: {
    green: '#439c6d',
    red: '#ce3c3d',
    blue: '#161fbc',
  },
  table: {
    header: '#BDC2FF',
    firstColumn: '#BDC2FF',
    columnEven: '#DFE0FF',
    columnOdd: '#F2EFF7',
    border: `1px solid #FFF`,
  },
};

const typography = {
  fontFamily: ['Source Sans Pro', 'Inter', 'IBM Plex Sans'].join(','),
};

const filledButtons = {
  primary: {
    fontFamily: 'Inter',
    borderRadius: 50,
    color: palette.white.main,
    backgroundColor: palette.sinay.primary['40'],
    textTransform: 'none',
    padding: '8px 25px',
    '&:hover': {
      backgroundColor: palette.sinay.primary['50'],
    },
    '&:disabled': {
      backgroundColor: palette.sinay.neutral['90'],
    },
    '&:focus': {
      outline: 'none',
    },
  },
  secondary: {
    fontFamily: 'Inter',
    borderRadius: 50,
    color: palette.white.main,
    backgroundColor: palette.sinay.secondary['40'],
    textTransform: 'none',
    padding: '8px 25px',
    '&:hover': {
      backgroundColor: palette.sinay.secondary['50'],
    },
    '&:disabled': {
      backgroundColor: palette.sinay.neutral['90'],
    },
    '&:focus': {
      outline: 'none',
    },
  },
  tertiary: {
    fontFamily: 'Inter',
    borderRadius: 50,
    color: palette.white.main,
    backgroundColor: palette.sinay.tertiary['40'],
    textTransform: 'none',
    padding: '8px 25px',
    '&:hover': {
      backgroundColor: palette.sinay.tertiary['50'],
    },
    '&:disabled': {
      backgroundColor: palette.sinay.neutral['90'],
    },
    '&:focus': {
      outline: 'none',
    },
  },
  error: {
    fontFamily: 'Inter',
    borderRadius: 50,
    color: palette.white.main,
    backgroundColor: palette.sinay.error['40'],
    textTransform: 'none',
    padding: '8px 25px',
    '&:hover': {
      backgroundColor: palette.sinay.error['50'],
    },
    '&:disabled': {
      backgroundColor: palette.sinay.neutral['90'],
    },
    '&:focus': {
      outline: 'none',
    },
  },
};

const outlinedButtons = {
  primary: {
    fontFamily: 'Inter',
    borderRadius: 50,
    color: palette.sinay.primary['40'],
    backgroundColor: palette.sinay.primary['90'],
    textTransform: 'none',
    boxShadow: 'none',
    fontWeight: 600,
    padding: '8px 25px',
    '&:hover': {
      backgroundColor: palette.sinay.primary['80'],
    },
    '&:disabled': {
      backgroundColor: palette.sinay.neutral['90'],
    },
    '&:focus': {
      outline: 'none',
    },
  },
  secondary: {
    fontFamily: 'Inter',
    borderRadius: 50,
    color: palette.sinay.secondary['40'],
    backgroundColor: palette.sinay.secondary['90'],
    textTransform: 'none',
    boxShadow: 'none',
    fontWeight: 600,
    padding: '8px 25px',
    '&:hover': {
      backgroundColor: palette.sinay.secondary['80'],
    },
    '&:disabled': {
      backgroundColor: palette.sinay.neutral['90'],
    },
    '&:focus': {
      outline: 'none',
    },
  },
  tertiary: {
    fontFamily: 'Inter',
    borderRadius: 50,
    color: palette.sinay.tertiary['40'],
    textTransform: 'none',
    backgroundColor: palette.sinay.tertiary['90'],
    boxShadow: 'none',
    fontWeight: 600,
    padding: '8px 25px',
    '&:hover': {
      backgroundColor: palette.sinay.tertiary['80'],
    },
    '&:disabled': {
      backgroundColor: palette.sinay.neutral['90'],
    },
    '&:focus': {
      outline: 'none',
    },
  },
  neutral: {
    fontFamily: 'Inter',
    borderRadius: 50,
    color: palette.sinay.neutral['40'],
    textTransform: 'none',
    backgroundColor: palette.sinay.neutral['98'],
    border: `1px solid ${palette.sinay.neutral['70']}`,
    boxShadow: 'none',
    fontWeight: 600,
    padding: '8px 25px',
    '&:hover': {
      backgroundColor: palette.sinay.neutral['95'],
    },
    '&:disabled': {
      backgroundColor: palette.sinay.neutral['90'],
    },
    '&:focus': {
      outline: 'none',
    },
  },
};

const elevatedButtons = {
  primary: {
    fontFamily: 'Inter',
    borderRadius: 50,
    color: palette.sinay.primary['40'],
    backgroundColor: palette.sinay.primary['100'],
    boxShadow:
      '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    textTransform: 'none',
    fontWeight: 600,
    padding: '8px 25px',
    '&:hover': {
      backgroundColor: palette.sinay.primary['95'],
    },
    '&:disabled': {
      color: palette.sinay.neutral['90'],
    },
    '&:focus': {
      outline: 'none',
    },
  },
  secondary: {
    fontFamily: 'Inter',
    borderRadius: 50,
    color: palette.sinay.secondary['40'],
    backgroundColor: palette.sinay.secondary['100'],
    boxShadow:
      '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    fontWeight: 600,
    textTransform: 'none',
    padding: '8px 25px',
    '&:hover': {
      backgroundColor: palette.sinay.secondary['95'],
    },
    '&:disabled': {
      color: palette.sinay.neutral['90'],
    },
    '&:focus': {
      outline: 'none',
    },
  },
  tertiary: {
    fontFamily: 'Inter',
    borderRadius: 50,
    color: palette.sinay.tertiary['40'],
    backgroundColor: palette.sinay.tertiary['100'],
    boxShadow:
      '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    fontWeight: 600,
    textTransform: 'none',
    padding: '8px 25px',
    '&:hover': {
      backgroundColor: palette.sinay.tertiary['95'],
    },
    '&:disabled': {
      color: palette.sinay.neutral['90'],
    },
    '&:focus': {
      outline: 'none',
    },
  },
  neutral: {
    borderRadius: 50,
    color: palette.sinay.neutral['40'],
    backgroundColor: palette.sinay.neutral['100'],
    boxShadow:
      '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    fontWeight: 600,
    textTransform: 'none',
    padding: '8px 25px',
    '&:hover': {
      backgroundColor: palette.sinay.neutral['95'],
    },
    '&:focus': {
      outline: 'none',
    },
  },
  error: {
    fontFamily: 'Inter',
    borderRadius: 50,
    color: palette.sinay.error['40'],
    backgroundColor: palette.sinay.error['100'],
    boxShadow:
      '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    fontWeight: 600,
    textTransform: 'none',
    padding: '8px 25px',
    '&:hover': {
      backgroundColor: palette.sinay.error['95'],
    },
    '&:disabled': {
      color: palette.sinay.neutral['90'],
    },
    '&:focus': {
      outline: 'none',
    },
  },
};

export const tutorialModal = {
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 15,
  },
  title: {
    fontWeight: 600,
  },
  iconBg: {
    backgroundColor: '#DFE0FF',
    padding: 5,
    borderRadius: 5,
    color: palette.sinay.primary['40'],
    width: 40,
    height: 40,
  },
  tooltipContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 10,
    '& p': {
      textAlign: 'center',
      color: '#46464F',
      margin: 'auto',
      fontSize: 14,
      paddingTop: 3,
    },
  },
  dotsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
  },
  activeDot: {
    backgroundColor: palette.sinay.primary['40'],
    borderRadius: 15,
    width: 10,
    height: 10,
  },
  inactiveDot: {
    backgroundColor: palette.sinay.primary['80'],
    borderRadius: 15,
    width: 10,
    height: 10,
  },
};

export const theme = createTheme({
  defaultTransition: '.16s',
  navbarHeight: 80,
  baseSideBarWidth: 310,
  projectMenuHeight: 60,
  dashboardToolbarHeight: 80,
  zIndexMap: 400,
  zIndexNavBar: 1100,
  zIndexMax: 1200,
  palette,
  typography,
  buttons: {
    fill: filledButtons,
    outlined: outlinedButtons,
    elevated: elevatedButtons,
  },
  tutorialModal,
});
