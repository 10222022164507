import React, { memo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import EditPointForm from 'components/forms/EditPointForm';
import { createPoint } from 'ducks/points/actions';
import { getPointGeometry } from 'helpers/map';
import { selectPointsNamesByProject } from 'ducks/points/selectors';
import { selectProjectZoneByProjectId } from 'ducks/projects/selectors';

/**
 * Container for point create form
 * @param {Function} onClose
 * @param {Object} project
 * @param {Object} props
 * @param {Boolean} canCreatePoint
 * @returns {jsx}
 */
const CreatePointContainer = ({
  onClose,
  project,
  canCreatePoint,
  ...props
}) => {
  const dispatch = useDispatch();
  const { existingNames, projectZone } = useSelector((state) => ({
    existingNames: selectPointsNamesByProject(state, project.id),
    projectZone: selectProjectZoneByProjectId(state, project.id),
  }));

  const [prefillPointName, setPrefillPointName] = useState('');

  // Set default point name for next point created
  useEffect(() => {
    const pointIndex = existingNames ? existingNames.length + 1 : 1;
    const findName = findValidPointName(pointIndex);
    setPrefillPointName(findName);
  }, [existingNames, setPrefillPointName]);

  const findValidPointName = useCallback((pointIndex) => {
    const attemptsNumber = 10;
    /* eslint-disable-next-line no-plusplus */
    for (let index = pointIndex; index < pointIndex + attemptsNumber; index++) {
      const nextPointDefaultName = `Point${index}`;
      const nameValid = checkPointNameNotExist(nextPointDefaultName);
      if (nameValid) {
        return nextPointDefaultName;
      }
    }
  });
  const checkPointNameNotExist = useCallback((pointName) =>
    existingNames && existingNames.length > 0
      ? !existingNames.includes(pointName)
      : true
  );

  const handleSuccess = useCallback(
    ({ lat, lng, name }) => {
      dispatch(
        createPoint({
          geom: getPointGeometry({ lat, lng }),
          project: project.id,
          name,
        })
      );
      onClose();
    },
    [dispatch, project.id, onClose]
  );

  return (
    <EditPointForm
      {...props}
      name={prefillPointName}
      allowRename={canCreatePoint}
      disabled={!canCreatePoint}
      onCancel={onClose}
      onSuccess={handleSuccess}
      existingNames={existingNames}
      zone={projectZone}
    />
  );
};

CreatePointContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  canCreatePoint: PropTypes.bool.isRequired,
};

export default memo(CreatePointContainer);
