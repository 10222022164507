import { makeStyles } from '@material-ui/core/styles';

import { theme } from 'common/theme';

export const useStyles = makeStyles(() => ({
  row: {
    marginBottom: 30,
  },
  fullWidth: {
    width: '100%',
  },
  modalButtonRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalButton: {
    margin: '0 5px',
    minWidth: 100,
  },
  helperText: {
    height: 14,
    wordWrap: 'break-word',
  },
  removeButton: {
    margin: '0 5px',
    minWidth: 100,
    ...theme.buttons.elevated.error,
  },
  cancelButton: {
    ...theme.buttons.elevated.neutral,
  },
  saveButton: {
    ...theme.buttons.fill.primary,
  },
  errorText: {
    color: theme.palette.sinay.error['40'],
  },
}));
