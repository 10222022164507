import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import {
  selectProjectsCollection,
  selectProjectsData,
} from 'ducks/projects/selectors';
import { updateProjectCollaboratorsWaitingRequest } from 'ducks/app/actions';
import { useStyles } from 'components/modals/GrantAccessForCollaboratorModal/styles';
import GrantAccessForCollaboratorForm from 'components/forms/GrantAccessForCollaboratorForm';
import { EMPTY_VALUE } from 'constants/common';
import { getProjectCollaboratorsIds } from 'helpers/projects';
import { selectUserDetails } from 'ducks/user/selectors';

/**
 * GrantAccessForCollaborator container
 * Contains logic and data for GrantAccessForCollaboratorModal
 * @param id - modal id
 * @param collaboratorId - collaborator id
 * @param user - object with user information
 * @param projectsAvailableToUser - array of projects ids. use if user(collaborator) is not new and added to some projects
 * @param filteredProjects - array of filtered projects. use if you need search / filter by project
 */
const GrantAccessForCollaborator = ({
  id,
  collaboratorId,
  filteredProjects,
  projectsAvailableToUser,
  user = EMPTY_VALUE,
}) => {
  const dispatch = useDispatch();
  const projectsData = useSelector(selectProjectsData);
  const projectsCollection = useSelector(selectProjectsCollection);
  const currentUser = useSelector(selectUserDetails);

  /**
   * Use onSuccess instead updateProjectsCollaborators and use handleSubmit from useFom,
   * when useForm is ready for forms without validation
   */
  const updateProjectsCollaborators = useCallback(
    ({ selectedProjects, changedProjects }) => {
      const data = getProjectCollaboratorsIds(
        selectedProjects,
        changedProjects,
        projectsAvailableToUser,
        projectsData,
        collaboratorId,
        currentUser?.id
      );
      dispatch(
        updateProjectCollaboratorsWaitingRequest({
          data,
          modalId: id,
          user,
        })
      );
    },
    [
      dispatch,
      id,
      collaboratorId,
      projectsData,
      user,
      projectsAvailableToUser,
      currentUser,
    ]
  );

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography gutterBottom variant="body1" className={classes.formText}>
        {t('projects.invite.accessText')}
      </Typography>
      <GrantAccessForCollaboratorForm
        projects={filteredProjects || projectsCollection}
        updateProjectsCollaborators={updateProjectsCollaborators}
        collaboratorId={collaboratorId}
        projectsAvailableToUser={projectsAvailableToUser}
      />
    </>
  );
};

GrantAccessForCollaborator.propTypes = {
  id: PropTypes.string.isRequired,
  collaboratorId: PropTypes.number.isRequired,
  filteredProjects: PropTypes.arrayOf(PropTypes.object),
  projectsAvailableToUser: PropTypes.arrayOf(PropTypes.number),
  user: PropTypes.object,
};

export default React.memo(GrantAccessForCollaborator);
