import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  createProjectSection: {
    paddingBottom: 15,
    borderBottom: '1px solid #E3E1EC',
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 5,
  },
  subtitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 10,
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 3,
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 5,
    border: '1px solid #E5E7EB',
    borderRadius: 8,
    padding: 15,
    width: '100%',
  },
  selectedItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 5,
    fontSize: 14,
    marginBottom: 5,
    border: `1px solid ${palette.sinay.primary['50']}`,
    backgroundColor: '#FBF8FF',
    borderRadius: 8,
    padding: 17,
    width: '100%',
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    fontWeight: 600,
  },
  offerEnd: {
    fontSize: 10,
    color: '#4B5563',
  },
  cursor: {
    cursor: 'pointer',
  },
}));
