import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  createProjectSection: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 5,
  },
  subtitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 10,
  },
  row: {
    marginBottom: 5,
    width: '100%',
  },
  fullWidth: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E5E7EB',
    },
  },
  helperText: {
    color: palette.sinay.error['40'],
    fontSize: 10,
    marginTop: 5,
  },
}));
