import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  overviewContentContainer: {
    padding: 10,
  },
  tabRoot: {
    minHeight: 20,
    minWidth: 0,
    padding: '15px 30px',
    backgroundColor: '#E4E1E9',
    textTransform: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  tabSelected: {
    backgroundColor: '#F5F2FA',
    borderBottom: `3px solid ${palette.secondary.main}`,
  },
  contentContainer: {
    padding: 30,
    backgroundColor: '#FFF',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
}));
