import {
  BUOY_CURRENT_ICON,
  BUOY_WAVE_ICON,
  BUOY_WIND_ICON,
} from 'constants/icons';

import {
  COMPUTED_LEGEND_ICON,
  GRID_LEGEND_ICON,
  NOT_COMPUTED_LEGEND_ICON,
  ZONE_LEGEND_ICON,
} from './icons';

export const POINT_POSITION = 'point';
export const GEOMETRY_POSITION = 'geometry';
export const TILE_LAYER_COORDINATE_POSTFIX = '{z}/{x}/{y}.png';
export const TILE_LAYER_STATIC_PREFIX = '/static/Tiles/';

export const MAP_LAYERS = {
  openStreet: 'osmLayer',
  worldStreet: 'wsmLayer',
  nationalGeo: 'ngmLayer',
  worldImagery: 'wimLayer',
  googleHybrid: 'ghmLayer',
};

const GH_SERVER_URL = `https://mt.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}`;

export const MAP_LAYERS_CONFIG = {
  [MAP_LAYERS.worldStreet]: {
    name: 'World Street Map',
    path: 'Esri.WorldStreetMap',
  },
  [MAP_LAYERS.googleHybrid]: {
    name: 'Google Hybrid',
    path: GH_SERVER_URL,
    custom: true,
  },
  [MAP_LAYERS.nationalGeo]: {
    name: 'National Geographic',
    path: 'Esri.NatGeoWorldMap',
  },
  [MAP_LAYERS.openStreet]: { name: 'OpenStreetMap', path: 'OpenStreetMap' },
  [MAP_LAYERS.worldImagery]: {
    name: 'World Imagery',
    path: 'Esri.WorldImagery',
  },
};

export const GRATICULE_DEFAULT_INTERVAL = 20;

export const GRATICULE_DEFAULT_ZOOM_INTERVALS = [
  { start: 0, end: 2, interval: 45, digits: 0 },
  { start: 3, end: 4, interval: 15, digits: 0 },
  { start: 5, end: 6, interval: 5, digits: 0 },
  { start: 7, end: 8, interval: 1, digits: 0 },
  { start: 9, end: 10, interval: 0.5, digits: 1 },
  { start: 11, end: 20, interval: 0.1, digits: 1 },
];

export const MAP_LABEL_CLASSNAME = 'leaflet-grid-label';

export const GLOBAL_MAP_DEFAULT_POSITION = { lat: 15, lng: 5, zoom: 3 };
export const GLOBAL_MAP_MIN_ZOOM_POSITION = { lat: 15, lng: 5, zoom: 1 };

export const PATH_TAG = 'path';

export const LEAFLET_EVENT_STOP_FLAG = '_stopped';

export const GRID_NAMES = {
  wind: 'WIND',
  currents: 'CURRENTS',
  waves: 'WAVES',
  wavesTp: 'WAVES-TP',
  waterHeights: 'WATER-HEIGHTS',
  temperature: 'TEMPERATURE',
  salinity: 'SALINITY',
  energy: 'ENERGY',
};

export const AREA_GROUP_NAME = 'Area';
export const POINTS_GROUP_NAME = 'Points';
export const BUOYS_GROUP_NAME = 'Buoys';
export const WIND_GROUP_NAME = GRID_NAMES.wind;
export const CURRENTS_GROUP_NAME = GRID_NAMES.currents;
export const WAVES_GROUP_NAME = GRID_NAMES.waves;
export const WATER_HEIGHTS_GROUP_NAME = GRID_NAMES.waterHeights;
export const TEMPERATURE_GROUP_NAME = GRID_NAMES.temperature;
export const ENERGY_GROUP_NAME = GRID_NAMES.energy;

export const AVAILABLE_GRIDS = [
  { id: 'wind', name: GRID_NAMES.wind },
  { id: 'currents', name: GRID_NAMES.currents },
  { id: 'waves', name: GRID_NAMES.waves },
  { id: 'wavesTp', name: GRID_NAMES.wavesTp },
  { id: 'waterHeights', name: GRID_NAMES.waterHeights },
  { id: 'temperature', name: GRID_NAMES.temperature },
  { id: 'salinity', name: GRID_NAMES.salinity },
  { id: 'energy', name: GRID_NAMES.energy },
];

export const GRIDS_GROUP_NAME = 'Grids';

export const POINTS_LEGEND = 'pointsLegend';
export const BUOYS_LEGEND = 'buoysLegend';
export const POINT_COMPUTED_LEGEND = 'pointComputedLegend';
export const POINT_NOT_COMPUTED_LEGEND = 'pointNotComputedLegend';
export const ZONE_LEGEND = 'zoneLegend';
export const GRID_LEGEND = 'gridLegend';
export const CURRENT_BUOY_LEGEND = 'currentBuoyLegend';
export const WAVE_BUOY_LEGEND = 'waveBuoyLegend';
export const WIND_BUOY_LEGEND = 'windBuoyLegend';

export const LEGENDS_PARAMS = {
  [POINT_COMPUTED_LEGEND]: {
    icon: COMPUTED_LEGEND_ICON,
    label: 'Point(computed)',
  },
  [POINT_NOT_COMPUTED_LEGEND]: {
    icon: NOT_COMPUTED_LEGEND_ICON,
    label: 'Point(not computed)',
  },
  [ZONE_LEGEND]: {
    icon: ZONE_LEGEND_ICON,
    label: 'Zone',
  },
  [GRID_LEGEND]: {
    icon: GRID_LEGEND_ICON,
    label: 'Numerical simulation grid',
  },
  [CURRENT_BUOY_LEGEND]: {
    icon: BUOY_CURRENT_ICON,
    label: 'Current buoy',
  },
  [WAVE_BUOY_LEGEND]: {
    icon: BUOY_WAVE_ICON,
    label: 'Wave buoy',
  },
  [WIND_BUOY_LEGEND]: {
    icon: BUOY_WIND_ICON,
    label: 'Wind buoy',
  },
};

export const LEGENDS_BY_GROUP_NAME = {
  [BUOYS_GROUP_NAME]: BUOYS_LEGEND,
  [POINTS_GROUP_NAME]: POINTS_LEGEND,
  [AREA_GROUP_NAME]: ZONE_LEGEND,
  [WIND_GROUP_NAME]: GRID_LEGEND,
  [WATER_HEIGHTS_GROUP_NAME]: GRID_LEGEND,
  [WAVES_GROUP_NAME]: GRID_LEGEND,
  [CURRENTS_GROUP_NAME]: GRID_LEGEND,
  [TEMPERATURE_GROUP_NAME]: GRID_LEGEND,
  [ENERGY_GROUP_NAME]: GRID_LEGEND,
};

export const MAP_CONTROLS_POSITION = {
  topRight: 'topright',
  topLeft: 'topleft',
  bottomLeft: 'bottomleft',
  bottomRight: 'bottomright',
};

export const MAP_CONTROL_CONTAINER_CLASS = '.leaflet-control-container';
export const MAP_CONTROL_POSITION_CLASS_PREFIX = '.leaflet-';

export const MAP_CONTROLS_JSON_PARAMETERS = {
  jsonCallback: 'json_callback',
};

export const MAP_PROPERTIES = {
  zoom: 'zoom',
  latitude: 'lat',
  longitude: 'lon',
};

export const GEOMETRY_TYPES = {
  point: 'Point',
};

export const BASE_LAYER_Z_INDEX = 0;
export const STAT2D_Z_INDEX = 1;
export const CUSTOM_LAYERS_Z_INDEX = 2;

export const EDIT_POINT_Y_OFFSET = 200;
export const CREATE_PROJECT_DATASET_OFFSET = 200;

export const DEFAULT_CENTERING_MAP_DELAY = 50;

export const DASHBOARD_STATIC_MAP_COEFFICIENT = 0.15;
