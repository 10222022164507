import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { Binoculars } from 'phosphor-react';

import { TUTORIAL_ZONE_PROJECT_QUERY_GET_STATISTICS_POINT_COMPUTED } from 'constants/tutorials';

import { useStyles } from './styles';

/**
 * ViewStatisticsTutorial
 * @param { boolean } forceOpenTutorial // trigger start tutorial from button click
 * @param { function }  handleCloseTutorial // handle update force open tutorial in parent component
 * @returns {jsx}
 */
const ViewStatisticsTutorial = ({ forceOpenTutorial, handleCloseTutorial }) => {
  const classes = useStyles();

  const STEPS = [
    // Hack - ghost step as index 0 to fix bug first step must be placement center
    {
      placement: 'center',
      target: 'body',
      content: '',
      styles: {
        buttonNext: {
          display: 'none',
        },
      },
    },
    // Unique step
    {
      placement: 'right',
      target: '#NavTabs',
      spotlightPadding: 2,
      title: (
        <div className={classes.titleContainer}>
          <Binoculars className={classes.iconBg} />
          <div className={classes.title}>Explore Your Data</div>
        </div>
      ),
      content: (
        <div className={classes.tooltipContent}>
          <p>
            Go to <strong>Analyze</strong> to display statistics or{' '}
            <strong>Report</strong> to export your data.
          </p>
        </div>
      ),
      styles: {
        buttonNext: {
          borderRadius: 50,
          border: 0,
          color: '#fff',
          backgroundColor: '#4451c7',
          textTransform: 'none',
          padding: '10px 24px',
          fontSize: 14,
          margin: '0 30%',
          width: '100%',
        },
      },
      locale: {
        last: 'Got it',
      },
    },
  ];

  const [runTutorial, setRunTutorial] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps] = useState(STEPS);

  // Init tutorial if click on button start
  useEffect(() => {
    if (forceOpenTutorial) {
      setRunTutorial(true);
      setStepIndex(1); // Hack stepindex 0 is ghost step - go to index 1
    }
  }, [forceOpenTutorial]);

  // Need to set our running state to false, so we can restart if we click start again.
  const resetTutorial = () => {
    setRunTutorial(false);
    setStepIndex(1); // Hack stepindex 0 is ghost step - go to index 1
    handleCloseTutorial(
      TUTORIAL_ZONE_PROJECT_QUERY_GET_STATISTICS_POINT_COMPUTED
    );
  };

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
      action === ACTIONS.CLOSE ||
      action === ACTIONS.STOP
    ) {
      resetTutorial();
    } else if (
      index === stepIndex &&
      [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)
    ) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndex(nextStepIndex);
    }
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      run={runTutorial}
      stepIndex={stepIndex}
      steps={steps}
      continuous
      hideBackButton
      styles={{
        options: {
          zIndex: 10000,
        },
        beacon: {
          display: 'none',
        },
        tooltip: {
          borderRadius: 16,
          padding: '15px 10px',
        },
        tooltipFooter: {
          marginTop: 0,
        },
        floaterStyles: {
          arrow: {
            display: 'none',
          },
        },
      }}
    />
  );
};

ViewStatisticsTutorial.propTypes = {
  forceOpenTutorial: PropTypes.bool,
  handleCloseTutorial: PropTypes.func,
};

export default React.memo(ViewStatisticsTutorial);
