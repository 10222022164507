import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MapTrifold, MapPin, Trash, Warning } from 'phosphor-react';
import classNames from 'classnames';
import { Button } from '@material-ui/core';

import { ROUTES } from 'constants/routes';

import { useStyles } from './styles';

const PointItemRow = ({ point, selectedPoint, deletePoint }) => {
  const classes = useStyles();
  const handleDeletePoint = useCallback(() => {
    deletePoint(point.name);
  });
  return (
    <div
      className={classNames(classes.itemCard, {
        [classes.selectedItemCard]:
          selectedPoint && selectedPoint.name === point.name,
      })}
    >
      <div className={classes.itemRowContainer}>
        <div className={classes.pointDataContainer}>
          <div className={classes.pointIcon}>
            <MapPin size={20} />
          </div>
          <div className={classes.pointNameContainer}>
            <p className={classes.pointName}>{point.name}</p>
            <p className={classes.pointCoord}>
              {point.lat.toFixed(4)} | {point.lng.toFixed(4)}
            </p>
          </div>
        </div>

        <Trash
          size={20}
          onClick={handleDeletePoint}
          id={point.name}
          className={classes.iconDelete}
        />
      </div>
    </div>
  );
};

const WarningOverlapDataset = ({ overlapDataset }) => {
  const classes = useStyles();

  const openNewTabProjectsList = useCallback(() => {
    const searchInput = overlapDataset.name;
    const path = `${ROUTES.home}?search=${searchInput}`;
    window.open(path, '_blank');
  });

  return (
    <div id="WarningOverlapDataset" className={classes.warningContainer}>
      <div className={classes.warningSubtitle}>
        <Warning size={20} />
        <p>Project overlap</p>
      </div>
      <p className={classes.warningTextContent}>
        This dataset is already used in other projects. Please verify whether
        your selected location is already included to avoid duplication.
      </p>
      <div className={classes.warningButtonContainer}>
        <Button
          className={classes.redirectButton}
          onClick={openNewTabProjectsList}
        >
          View Projects
        </Button>
      </div>
    </div>
  );
};

const NewProjectPointsList = ({
  pointsList,
  selectedPoint,
  handleUpdatePointsList,
  overlapDataset,
}) => {
  const classes = useStyles();

  const handleDeletePoint = useCallback((nameToDelete) => {
    const updatedList = pointsList.filter(({ name }) => name !== nameToDelete);
    handleUpdatePointsList(updatedList);
  });

  useEffect(() => {
    if (overlapDataset) {
      document.getElementById('NewProjectPointsList').scrollIntoView();
    }
  }, [overlapDataset]);

  return (
    <div id="NewProjectPointsList" className={classes.createProjectSection}>
      <div className={classes.subtitleContainer}>
        <MapTrifold size={24} />
        <div className={classes.subtitle}>Point Selection</div>
      </div>
      {pointsList.length === 0 && (
        <div className={classes.noListContainer}>
          Click on the map to place your point.
        </div>
      )}
      {pointsList.length > 0 && (
        <div className={classes.pointsListContainer}>
          {pointsList &&
            pointsList.map((point) => (
              <PointItemRow
                key={point.name}
                point={point}
                selectedPoint={selectedPoint}
                deletePoint={handleDeletePoint}
              />
            ))}
        </div>
      )}
      {overlapDataset && (
        <WarningOverlapDataset overlapDataset={overlapDataset} />
      )}
    </div>
  );
};

NewProjectPointsList.propTypes = {
  pointsList: PropTypes.array.isRequired,
  selectedPoint: PropTypes.object,
  handleUpdatePointsList: PropTypes.func,
  overlapDataset: PropTypes.object,
};

export default React.memo(NewProjectPointsList);
