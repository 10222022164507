import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NewProjectWrapper from 'components/projects/NewProject/NewProjectWrapper';
import { selectOffers } from 'ducks/offers/selectors';
import { requestOffers } from 'ducks/offers/actions';

/**
 * NewProject container
 */
const NewProjectContainer = () => {
  const dispatch = useDispatch();
  const offers = useSelector(selectOffers);

  useEffect(() => {
    dispatch(requestOffers());
  }, [dispatch]);

  return <NewProjectWrapper offers={offers} />;
};

export default NewProjectContainer;
