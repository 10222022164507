import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  header: {
    margin: '10px 0',
  },
  subtitle: {
    margin: '10px 0',
    fontSize: 16,
    fontWeight: 600,
  },
  text: {
    fontSize: 13,
  },
  textContainer: {
    '& p': {
      fontSize: 13,
    },
    '& h3': {
      margin: '10px 0',
      fontSize: 16,
      fontWeight: 600,
      textAlign: 'left !important',
    },
  },
});
