import React from 'react';
import { Warning } from 'phosphor-react';

import { useStyles } from './styles';

const NoMatchingZonesMessages = ({ errorNoMatchingZone }) => {
  const classes = useStyles();
  return errorNoMatchingZone ? (
    <div className={classes.warningContainer}>
      <div className={classes.warningSubtitle}>
        <Warning size={20} />
        <p>No dataset available</p>
      </div>
      <p className={classes.warningTextContent}>
        There is no matching zone in your offer for this set of points
      </p>
    </div>
  ) : (
    <div className={classes.info}>
      Place at least one point to select a dataset.
    </div>
  );
};

export default React.memo(NoMatchingZonesMessages);
