import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  ({ navbarHeight, projectMenuHeight, baseSideBarWidth, zIndexNavBar }) => ({
    projectContainer: {
      position: 'fixed',
      top: navbarHeight + projectMenuHeight,
      zIndex: zIndexNavBar + 1,
      right: 0,
      left: 0,
      bottom: 0,
    },
    sideBarContainer: {
      width: baseSideBarWidth,
    },
    sideSideBarContainer: {
      height: projectMenuHeight - '50px',
      overflowY: 'auto',
      width: baseSideBarWidth,
    },
    contentContainer: {
      flexGrow: '1',
      height: '100%',
      overflowX: 'auto',
    },
    mapContainer: {
      height: '100%',
      width: '100%',
    },
  })
);
