import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ tutorialModal, palette }) => ({
  titleContainer: {
    ...tutorialModal.titleContainer,
  },
  title: {
    ...tutorialModal.title,
  },
  iconBg: {
    ...tutorialModal.iconBg,
  },
  tooltipContent: {
    ...tutorialModal.tooltipContent,
  },
  dotsContainer: {
    ...tutorialModal.dotsContainer,
  },
  activeDot: {
    ...tutorialModal.activeDot,
  },
  inactiveDot: {
    ...tutorialModal.inactiveDot,
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    paddingTop: 10,
  },
  checkboxLabel: {
    fontSize: 12,
    marginBottom: 0,
    color: palette.sinay.neutral['40'],
  },
}));
