import {
  REQUEST_ZONES,
  REQUEST_ZONES_IF_NEED,
  REQUEST_ZONES_ERROR,
  REQUEST_ZONES_SUCCESS,
  REQUEST_ZONE,
  REQUEST_ZONE_SUCCESS,
  REQUEST_ZONE_ERROR,
  REQUEST_MATCHING_ZONES,
  REQUEST_MATCHING_ZONES_SUCCESS,
  REQUEST_MATCHING_ZONES_ERROR,
  REQUEST_MATCHING_ZONE_DATA,
  REQUEST_MATCHING_ZONE_DATA_SUCCESS,
  REQUEST_MATCHING_ZONE_DATA_ERROR,
  RESET_MATCHING_ZONE_DATA,
} from './types';

export const requestZones = () => ({
  type: REQUEST_ZONES,
});

export const requestZonesIfNeed = () => ({
  type: REQUEST_ZONES_IF_NEED,
});

export const requestZonesSuccess = (zones) => ({
  type: REQUEST_ZONES_SUCCESS,
  zones,
});

export const requestZonesError = (error) => ({
  type: REQUEST_ZONES_ERROR,
  error,
});

export const requestZone = (id) => ({
  type: REQUEST_ZONE,
  id,
});

export const requestZoneSuccess = (zone) => ({
  type: REQUEST_ZONE_SUCCESS,
  zone,
});

export const requestZoneError = (error) => ({
  type: REQUEST_ZONE_ERROR,
  error,
});

export const requestMatchingZones = (bounds, offerId) => ({
  type: REQUEST_MATCHING_ZONES,
  bounds,
  offerId,
});

export const requestMatchingZonesSuccess = (zone) => ({
  type: REQUEST_MATCHING_ZONES_SUCCESS,
  zone,
});

export const requestMatchingZonesError = (error) => ({
  type: REQUEST_MATCHING_ZONES_ERROR,
  error,
});

export const requestMatchingZoneData = (id) => ({
  type: REQUEST_MATCHING_ZONE_DATA,
  id,
});

export const requestMatchingZoneDataSuccess = (zone) => ({
  type: REQUEST_MATCHING_ZONE_DATA_SUCCESS,
  zone,
});

export const requestMatchingZoneDataError = (error) => ({
  type: REQUEST_MATCHING_ZONE_DATA_ERROR,
  error,
});

export const resetMatchingZoneData = () => ({
  type: RESET_MATCHING_ZONE_DATA,
});
