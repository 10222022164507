import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Radio, Button } from '@material-ui/core';

import { useModal } from 'hooks/useModal';
import BaseModal from 'components/common/BaseModal';
import { formatDate } from 'helpers/date';
import { DATE_FORMATS } from 'constants/common';

import { useStyles } from './styles';

const OfferCard = ({ offer, selectedOffer, handleChangeOffer }) => {
  const classes = useStyles();
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(offer.id === selectedOffer.id);
  }, [selectedOffer, offer]);

  const changeOffer = useCallback(() => {
    handleChangeOffer(offer);
  });

  return (
    <div onClick={changeOffer} className={classes.itemContainer}>
      <Radio checked={isSelected} color="primary" />
      <div
        className={classNames(classes.offerWrapper, {
          [classes.selectedOfferWrapper]: isSelected,
        })}
      >
        <div>
          <div className={classes.name}>{offer.name}</div>
          <div className={classes.description}>{offer.description}</div>
        </div>
        <div>
          <span className={classes.label}>End Offer :</span>{' '}
          {formatDate(offer.offerEnd, DATE_FORMATS.shortMonthFormat)}
        </div>
      </div>
    </div>
  );
};

const NewProjectSelectOfferModal = ({
  selectedOffer,
  notExpiredOffers,
  id,
  handleUpdateSelectedOffer,
}) => {
  const { onClose } = useModal(id);
  const classes = useStyles();
  const [updatedOffer, setUpdatedOffer] = useState(selectedOffer);

  const handleChangeOffer = useCallback((offer) => {
    setUpdatedOffer(offer);
  });

  const handleCancel = useCallback(() => {
    onClose();
  });
  const handleSaveOffer = useCallback(() => {
    handleUpdateSelectedOffer(updatedOffer);
    onClose();
  });

  const controls = (
    <div className={classes.buttonsContainer}>
      <Button onClick={handleCancel} className={classes.cancelButton}>
        Cancel
      </Button>
      <Button onClick={handleSaveOffer} className={classes.saveButton}>
        Save
      </Button>
    </div>
  );

  return (
    <BaseModal
      title="Change Offer"
      onClose={onClose}
      controls={controls}
      modalClass={classes.container}
    >
      <div>
        <div className={classes.subtitle}>
          List of offers available for your account :
        </div>

        <div className={classes.listWrapper}>
          {notExpiredOffers.map((offer) => (
            <OfferCard
              key={offer.id}
              offer={offer}
              selectedOffer={updatedOffer}
              handleChangeOffer={handleChangeOffer}
            />
          ))}
        </div>
      </div>
    </BaseModal>
  );
};

NewProjectSelectOfferModal.propTypes = {
  selectedOffer: PropTypes.object,
  notExpiredOffers: PropTypes.array,
  id: PropTypes.string.isRequired,
  handleUpdateSelectedOffer: PropTypes.func,
};

export default React.memo(NewProjectSelectOfferModal);
