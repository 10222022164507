import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  textContainer: {
    '& p': {
      fontSize: 13,
    },
    '& h3': {
      margin: '10px 0',
      fontSize: 16,
      fontWeight: 600,
    },
  },
}));
