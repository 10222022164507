import React, { useState, useEffect, useCallback } from 'react';
import { Clock } from 'phosphor-react';

import { AVAILABLE_GRIDS } from 'constants/map';
import DatasetIcon from 'components/common/DatasetIcon';

import { useStyles } from './styles';

const DatasetDetails = ({
  dataset,
  type,
  selectedDatasetType,
  forceSelectedDatasetDimension,
  handleSelectionDatasetDimension,
}) => {
  const classes = useStyles();

  const [availableDatasetDimensions, setAvailableDatasetDimensions] = useState(
    []
  );
  const [selectedDatasetDimension, setSelectedDatasetDimension] = useState(
    null
  );

  // Init selection with first value by default if no value selected previously
  useEffect(() => {
    const initValue =
      forceSelectedDatasetDimension || availableDatasetDimensions[0];
    setSelectedDatasetDimension(initValue);
    handleSelectionDatasetDimension(initValue);
  }, [dataset, availableDatasetDimensions, handleSelectionDatasetDimension]);

  // Check which dataset dimension are available for selected dataset
  useEffect(() => {
    setAvailableDatasetDimensions([]); // reset
    AVAILABLE_GRIDS.forEach((dimension) => {
      if (dataset[dimension.id] && dataset[dimension.id].length > 0) {
        setAvailableDatasetDimensions((prevState) => [...prevState, dimension]);
      }
    });
  }, [dataset, setAvailableDatasetDimensions]);

  const handleChangeDatasetDimension = useCallback((newId) => {
    const newDatasetDimension = AVAILABLE_GRIDS.find(
      (item) => item.id === newId
    );
    setSelectedDatasetDimension(newDatasetDimension);
    handleSelectionDatasetDimension(newDatasetDimension);
  });

  return (
    <div className={classes.detailsContainer}>
      <div className={classes.iconsWrapper}>
        {availableDatasetDimensions.map((availableDimension) => (
          <DatasetIcon
            key={availableDimension.id}
            id={availableDimension.id}
            customClass={
              availableDimension.id === selectedDatasetDimension?.id
                ? 'selectedDimensionIcon'
                : 'dimensionIcon'
            }
            handleClick={handleChangeDatasetDimension}
          />
        ))}
      </div>
      <div className={classes.detailsWrapper}>
        <div className={classes.dimensionLabel}>
          {selectedDatasetDimension?.name}
        </div>
        <div className={classes.dimensionDetailsContainer}>
          {selectedDatasetDimension?.id &&
            dataset[selectedDatasetDimension?.id] && (
              <>
                {/* Spatial resolution */}
                {dataset[selectedDatasetDimension.id][0] &&
                  dataset[selectedDatasetDimension.id][0].spatialResolution && (
                    <div className={classes.dimensionDetailsItemContainer}>
                      <DatasetIcon id="resolution" customSize={20} />
                      <div className={classes.dimensionDetailsContent}>
                        <div className={classes.dimensionDetailsItemLabel}>
                          Spatial Resolution
                        </div>
                        <div>
                          {
                            dataset[selectedDatasetDimension.id][0]
                              ?.spatialResolution
                          }{' '}
                          {
                            dataset[selectedDatasetDimension.id][0]
                              ?.spatialResolutionUnits
                          }
                        </div>
                      </div>
                    </div>
                  )}
                {/* Temporal resolution */}
                {dataset[selectedDatasetDimension.id][0] &&
                  dataset[selectedDatasetDimension.id][0].timeStep && (
                    <div className={classes.dimensionDetailsItemContainer}>
                      <Clock size={20} color="#3643BA" />
                      <div className={classes.dimensionDetailsContent}>
                        <div className={classes.dimensionDetailsItemLabel}>
                          Temporal Resolution
                        </div>
                        <div>
                          {dataset[selectedDatasetDimension.id][0]?.timeStep}{' '}
                          {
                            dataset[selectedDatasetDimension.id][0]
                              ?.timeStepUnits
                          }
                        </div>
                      </div>
                    </div>
                  )}
              </>
            )}
        </div>
      </div>
      {/* tag on selected card */}
      {type === 'regional' && selectedDatasetType === type && (
        <div className={classes.regionalTag}>Regional</div>
      )}
      {type === 'worldwide' && selectedDatasetType === type && (
        <div className={classes.worldwideTag}>Worldwide</div>
      )}
    </div>
  );
};

export default React.memo(DatasetDetails);
