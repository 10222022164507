import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  detailsContainer: {
    marginTop: 5,
    position: 'relative',
  },
  iconsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    marginTop: 8,
    marginBottom: 10,
  },
  detailsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  dimensionLabel: {
    textTransform: 'uppercase',
    color: palette.sinay.primary['40'],
    fontWeight: 600,
    fontSize: 14,
  },
  dimensionDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  },
  dimensionDetailsItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 5,
    fontSize: 11,
  },
  dimensionDetailsContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  dimensionDetailsItemLabel: {
    fontWeight: 600,
  },
  regionalTag: {
    fontSize: 12,
    padding: 5,
    backgroundColor: palette.sinay.secondary['90'],
    borderRadius: 4,
    color: '#404040',
    width: 86,
    textAlign: 'center',
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  worldwideTag: {
    fontSize: 12,
    padding: 5,
    backgroundColor: palette.sinay.tertiary['90'],
    borderRadius: 4,
    color: '#404040',
    width: 86,
    textAlign: 'center',
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
}));
