/* eslint-disable */
import { takeLatest, put, call, select, takeEvery } from 'redux-saga/effects';

import {
  getZones,
  getZone,
  getMatchingZones
} from 'services/zones';

import { 
  REQUEST_ZONE,
  REQUEST_ZONES,
  REQUEST_ZONES_IF_NEED,
  REQUEST_MATCHING_ZONES,
  REQUEST_MATCHING_ZONE_DATA,
} from './types';
import {
  requestZones,
  requestZonesError,
  requestZonesSuccess,
  requestZoneError,
  requestZoneSuccess,
  requestMatchingZonesSuccess,
  requestMatchingZonesError,
  requestMatchingZoneDataSuccess,
  requestMatchingZoneDataError,
} from './actions';
import { selectZones } from './selectors';

/**
 * Request zone
 */
function* requestZoneWorker({ id }) {
  try {
    const zone = yield call(getZone, id);
    yield put(requestZoneSuccess(zone));
  } catch (error) {
    yield put(requestZoneError(error));
  }
}

/**
 * Check that zones collection exists, if not, requests it
 */
function* requestZonesIfNeedWorker() {
  const zones = yield select(selectZones);
  if (!Object.keys(zones.data).length) {
    yield put(requestZones());
  }
}

/**
 * Requests all zones
 */
function* requestZonesWorker() {
  try {
    const zones = yield call(getZones);
    yield put(requestZonesSuccess(zones));
  } catch (error) {
    yield put(requestZonesError(error));
  }
}

/**
 * Request zones which contain given bounds
 */
function* requestMatchingZonesWorker({bounds, offerId}) {
  try {
    if(bounds) {
      const zones = yield call(getMatchingZones, {bounds, offerId});
      yield put(requestMatchingZonesSuccess(zones));
    } else {
      // Reset matching zones if no points (= no bounds)
      yield put(requestMatchingZonesSuccess([]));
    }
  } catch (error) {
    yield put(requestMatchingZonesError(error));
  }
}

/**
 * Request zone details for a matching zone
 */
function* requestMatchingZoneDataWorker({id}) {
  try {
    const zone = yield call(getZone, id);
    yield put(requestMatchingZoneDataSuccess(zone));
  } catch (error) {
    yield put(requestMatchingZoneDataError(error));
  }
}


export default function* mainSaga() {
  yield takeLatest(REQUEST_ZONE, requestZoneWorker);
  yield takeLatest(REQUEST_ZONES, requestZonesWorker);
  yield takeLatest(REQUEST_ZONES_IF_NEED, requestZonesIfNeedWorker);
  yield takeLatest(REQUEST_MATCHING_ZONES, requestMatchingZonesWorker);
  yield takeEvery(REQUEST_MATCHING_ZONE_DATA, requestMatchingZoneDataWorker);

}
