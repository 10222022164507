import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { PencilSimpleLine, Lightning } from 'phosphor-react';

import { useModal } from 'hooks/useModal';
import { NEW_PROJECT_SELECT_OFFER_MODAL } from 'constants/modals';
import { trackOfferSelection } from 'ducks/trackers/actions/projectManagment';
import { formatDate } from 'helpers/date';
import { DATE_FORMATS } from 'constants/common';

import { useStyles } from './styles';

const NewProjectOffer = ({
  selectedOffer,
  notExpiredOffers,
  handleSaveOffer,
}) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const dispatch = useDispatch();

  const openSelectOfferModal = useCallback(() =>
    openModal(NEW_PROJECT_SELECT_OFFER_MODAL, {
      selectedOffer,
      notExpiredOffers,
      handleUpdateSelectedOffer,
    })
  );

  const handleUpdateSelectedOffer = useCallback((updatedOffer) => {
    handleSaveOffer(updatedOffer);
  });

  useEffect(() => {
    if (selectedOffer) dispatch(trackOfferSelection(selectedOffer));
  }, [selectedOffer]);

  return (
    <div id="NewProjectOffer" className={classes.createProjectSection}>
      <div className={classes.subtitleContainer}>
        <Lightning size={24} />
        <div className={classes.subtitle}>Offer Selection</div>
      </div>

      {selectedOffer && (
        <div key={selectedOffer.id} className={classes.selectedItemContainer}>
          <div className={classes.labelContainer}>
            <div className={classes.label}>{selectedOffer.name}</div>
            {selectedOffer.offerEnd && (
              <div className={classes.offerEnd}>
                Valid until :{' '}
                {formatDate(
                  selectedOffer.offerEnd,
                  DATE_FORMATS.shortMonthFormat
                )}
              </div>
            )}
          </div>
          {notExpiredOffers.length > 1 && (
            <PencilSimpleLine
              size={20}
              onClick={openSelectOfferModal}
              className={classes.cursor}
            />
          )}
        </div>
      )}
    </div>
  );
};

NewProjectOffer.propTypes = {
  selectedOffer: PropTypes.object,
  notExpiredOffers: PropTypes.array,
  handleSaveOffer: PropTypes.func,
};

export default React.memo(NewProjectOffer);
