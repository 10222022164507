import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, buttons }) => ({
  tutorialCardContainer: {
    borderTop: '3px solid #eee',
    paddingTop: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  tutorialCardTitle: {
    fontWeight: 600,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: palette.sinay.primary['40'],
    paddingBottom: 10,
  },
  tutorialButton: {
    ...buttons.outlined.primary,
    padding: '8px 20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  centerButton: {
    justifyContent: 'center',
    margin: 'auto',
    width: 'auto',
  },
  tutorialIcon: {
    marginRight: 8,
  },
  fullWidth: {
    justifyContent: 'center',
    width: '100%',
  },
}));
