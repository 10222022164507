import React, { memo } from 'react';
import { Typography, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import NumberField from 'components/forms/fields/NumberField';

import { useStyles } from './styles';

/**
 * CoordinateField component - display field for lat and lng
 * @param {String} name
 * @param {Number} step
 * @param {Number} latMin
 * @param {Number} latMax
 * @param {Number} lngMin
 * @param {Number} lngMax
 * @param {Number} value.lat
 * @param {Number} value.lng
 * @param {Object} errors
 * @return {jsx}
 */
const CoordinateField = ({
  value,
  latMin,
  latMax,
  lngMin,
  lngMax,
  errors,
  step,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const isLatitudeInsideZone = latMax > value.lat && latMin < value.lat;
  const isLongitudeInsideZone = lngMax > value.lng && lngMin < value.lng;

  return (
    <>
      <div className={classes.row}>
        <div className={classes.fullWidth}>
          <NumberField
            {...props}
            variant="outlined"
            label={t('points.labels.latitude')}
            className={classes.coordinateField}
            fullWidth
            step={step}
            error={errors.lat}
            min={latMin}
            max={latMax}
            name="lat"
            size="medium"
            value={value.lat}
          />
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.fullWidth}>
          <NumberField
            {...props}
            variant="outlined"
            label={t('points.labels.longitude')}
            className={classes.coordinateField}
            fullWidth
            step={step}
            error={errors.lng}
            min={lngMin}
            max={lngMax}
            name="lng"
            size="medium"
            value={value.lng}
          />
        </div>
      </div>
      {(!isLatitudeInsideZone || !isLongitudeInsideZone) && (
        <FormHelperText error>{t('points.errors.outsideZone')}</FormHelperText>
      )}
      <Typography
        gutterBottom
        variant="body2"
        className={classes.coordinateText}
      >
        {t('points.coordinateText')}
      </Typography>
    </>
  );
};

CoordinateField.propTypes = {
  value: PropTypes.shape({
    lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  latMin: PropTypes.number,
  latMax: PropTypes.number,
  lngMin: PropTypes.number,
  lngMax: PropTypes.number,
  errors: PropTypes.shape({
    lat: PropTypes.string,
    lng: PropTypes.string,
  }),
  step: PropTypes.number,
};

CoordinateField.defaultProps = {
  value: {},
  step: 0.1,
};

export default memo(CoordinateField);
