import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { TextField, Grid } from '@material-ui/core';
import { CaretUp, CaretDown } from 'phosphor-react';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';

import { NUMBER_REGEXP_WITH_COMMA } from 'constants/regexp';

import { useStyles } from './styles';

/**
 * Create custom event for custom input button
 * @param {Object} target
 */
const createNumberChangeEvent = (target) => ({
  target,
});

/**
 * Replace comma on point and return number
 * @param {String} value
 * @return {Number}
 */
const convertInputValueToNumber = (value) => +value.replace(',', '.');

/**
 * Convert input value. Get separator from old value and replace point on separator and keep same precision.
 * @param {Number} currentValue
 * @param {String} oldValue
 * @return {string}
 * @example
 *  in: currentValue - 0.1234, oldValue - 0,2234
 *  out: 0,1234
 *
 *  in: currentValue - 6.1, oldValue - 6.1111
 *  out: 6.1
 *
 *  in: currentValue - 5.23, oldValue - 6
 *  out: 5.23
 */
const convertInputValueToOldFormat = (currentValue, oldValue = '') => {
  const [, separator, floatValue] = oldValue.match(NUMBER_REGEXP_WITH_COMMA);

  if (!floatValue || !separator || Number(floatValue) === 0) {
    return String(currentValue);
  }

  const preparedResult = +currentValue.toFixed(floatValue.length);

  return String(preparedResult).replace('.', separator);
};
/**
 * Add step to value
 * @param {String} value
 * @param {Number} step
 * @param {Number} [min]
 * @param {Number} [max]
 * @return {string}
 */
const changeInputValueByStep = ({ value, step, min, max }) => {
  const result = +convertInputValueToNumber(value) + step;

  if (min && min > result) {
    return convertInputValueToOldFormat(min, value);
  }

  if (max && max < result) {
    return convertInputValueToOldFormat(max, value);
  }

  return convertInputValueToOldFormat(result, value);
};

/**
 * Number text field, allow comma separator
 * @param {String} error
 * @param {String|Number} value
 * @param {Number} step
 * @param {Number} min
 * @param {Number} max
 * @param {String} className
 * @param {Function} onChange
 * @param {String} name
 * @param {String} id
 * @param {Object} props
 * @return {jsx}
 */
const NumberField = ({
  error,
  value,
  step,
  className,
  onChange,
  name,
  min,
  max,
  id,
  helperText,
  ...props
}) => {
  const classes = useStyles();
  const inputRef = useRef();
  const [inputValue, setInputValue] = useState(String(value));

  const decrementHandler = useCallback(() => {
    const newValue = changeInputValueByStep({
      value: inputValue,
      step: -step,
      min,
      max,
    });
    setInputValue(newValue);
  }, [step, min, max, inputValue]);

  const incrementHandler = useCallback(() => {
    const newValue = changeInputValueByStep({
      value: inputValue,
      step,
      min,
      max,
    });
    setInputValue(newValue);
  }, [step, min, max, inputValue]);

  const handleChange = useCallback(
    (event) => {
      const { target } = event;

      if (!NUMBER_REGEXP_WITH_COMMA.test(target.value)) {
        return setInputValue((prevValue) => prevValue);
      }

      return setInputValue(target.value);
    },
    [setInputValue]
  );

  const handlerKeyPress = useCallback(
    (event) => {
      switch (event.code) {
        case 'ArrowUp':
          return incrementHandler();
        case 'ArrowDown':
          return decrementHandler();
        default:
      }
    },
    [decrementHandler, incrementHandler]
  );

  useEffect(() => {
    const stringValue = String(inputValue);

    onChange(
      createNumberChangeEvent({
        value:
          stringValue === ''
            ? undefined
            : convertInputValueToNumber(stringValue),
        name,
        id,
      })
    );
    // eslint-disable-next-line
  }, [inputValue]);

  useEffect(() => {
    if (!inputRef) {
      return;
    }

    const element = inputRef.current;

    element.addEventListener('keydown', handlerKeyPress);
    return () => element.removeEventListener('keydown', handlerKeyPress);
  }, [inputRef, handlerKeyPress]);

  return (
    <>
      <TextField
        {...props}
        inputRef={inputRef}
        className={classNames(classes.numberField, className)}
        error={!!error}
        inputProps={{ step, autoComplete: 'off' }}
        value={inputValue}
        name={name}
        id={id}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <Grid container direction="column" className={classes.controlGroup}>
              <CaretUp
                className={classes.control}
                onClick={incrementHandler}
                size={16}
              />
              <CaretDown
                className={classes.control}
                onClick={decrementHandler}
                size={16}
              />
            </Grid>
          ),
        }}
      />
      {helperText && (
        <FormHelperText error className={classes.helperText}>
          {error}
        </FormHelperText>
      )}
    </>
  );
};

NumberField.propTypes = {
  error: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  helperText: PropTypes.bool,
};

NumberField.defaultProps = {
  value: 0,
  step: 0.1,
  helperText: true,
};

export default memo(NumberField);
