import React, { memo } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { PlusCircle, CheckCircle } from 'phosphor-react';

import { useStyles } from './styles';

/**
 * CheckboxIconButton component.
 * Works as a checkbox.
 * @param { boolean } isChecked
 * @param checkboxHandler -  handler for checkbox toggling
 * @param props
 */
const CheckboxIconButton = ({ isChecked, checkboxHandler, ...props }) => {
  const classes = useStyles();

  return (
    <IconButton className={classes.icon} onClick={checkboxHandler} {...props}>
      {isChecked ? <CheckCircle size={24} /> : <PlusCircle size={24} />}
    </IconButton>
  );
};

CheckboxIconButton.propTypes = {
  isChecked: PropTypes.bool,
};

export default memo(CheckboxIconButton);
