import React, { memo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
  BaseMapPopup,
  BaseMapPopupContent,
  BaseMapPopupTitle,
} from 'components/common/BaseMapPopup';
import NumberField from 'components/forms/fields/NumberField';

import { useStyles } from './styles';

const NewPointForm = ({ pointsList, newPoint, handleAddNewPointToList }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [prefillPointName, setPrefillPointName] = useState('');
  const [newPointName, setNewPointName] = useState('');
  const [newPointLat, setNewPointLat] = useState(newPoint.lat);
  const [newPointLng, setNewPointLng] = useState(newPoint.lng);
  const [existingNames, setExistingNames] = useState([]);
  const [displayErrorNameExists, setDisplayErrorNameExists] = useState(false);

  // Update list of exisiting names for points
  useEffect(() => {
    if (pointsList && pointsList.length > 0) {
      const pointsNamesList = pointsList.map((point) => point.name);
      setExistingNames(pointsNamesList);
    }
  }, [pointsList, setExistingNames]);

  // Set default point name for next point created
  useEffect(() => {
    const pointIndex = existingNames ? existingNames.length + 1 : 1;
    const findName = findValidPointName(pointIndex);
    setPrefillPointName(findName);
    // Init input with prefilled value
    setNewPointName(findName);
  }, [existingNames]);

  const findValidPointName = useCallback((pointIndex) => {
    const attemptsNumber = 10;
    /* eslint-disable-next-line no-plusplus */
    for (let index = pointIndex; index < pointIndex + attemptsNumber; index++) {
      const nextPointDefaultName = `Point${index}`;
      const nameValid = checkPointNameNotExist(nextPointDefaultName);
      if (nameValid) {
        return nextPointDefaultName;
      }
    }
  });
  const checkPointNameNotExist = useCallback((pointName) =>
    existingNames && existingNames.length > 0
      ? !existingNames.includes(pointName)
      : true
  );

  const handleSubmit = useCallback(() => {
    const newPointData = {
      name: newPointName || prefillPointName,
      lat: newPointLat,
      lng: newPointLng,
    };
    handleAddNewPointToList(newPointData);
  });

  const handleChange = useCallback((data) => {
    const inputName = data.target.name;
    const inputValue = data.target.value;
    switch (inputName) {
      case 'name': {
        setNewPointName(inputValue);
        const nameExists =
          existingNames.length > 0 && existingNames.includes(inputValue);
        setDisplayErrorNameExists(nameExists);
        break;
      }
      case 'lat':
        setNewPointLat(inputValue);
        break;
      case 'lng':
        setNewPointLng(inputValue);
        break;
      default:
        break;
    }
  });

  const removePrefill = useCallback((data) => {
    const inputValue = data.target.value;
    if (inputValue === prefillPointName) {
      setNewPointName('');
    }
  });

  const resetPrefill = useCallback((data) => {
    const inputValue = data.target.value;
    if (inputValue === '') {
      setNewPointName(prefillPointName);
    }
  });

  return (
    <BaseMapPopup className={classes.createPointPopup} id="CreatePointPopup">
      <BaseMapPopupTitle>Create point</BaseMapPopupTitle>
      <BaseMapPopupContent>
        <div className={classes.formWrapper}>
          <div className={classes.row}>
            <div className={classes.fullWidth}>
              <TextField
                variant="outlined"
                label={t('points.labels.name')}
                fullWidth
                name="name"
                size="medium"
                placeholder={t('points.labels.namePlaceholder')}
                value={newPointName}
                onChange={handleChange}
                onFocus={removePrefill}
                onBlur={resetPrefill}
              />
              {displayErrorNameExists && (
                <div className={classes.errorText}>
                  Point name already exists
                </div>
              )}
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.fullWidth}>
              <NumberField
                variant="outlined"
                label={t('points.labels.latitude')}
                className={classes.coordinateField}
                fullWidth
                name="lat"
                size="medium"
                helperText={false}
                value={newPointLat}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.fullWidth}>
              <NumberField
                variant="outlined"
                label={t('points.labels.longitude')}
                className={classes.coordinateField}
                fullWidth
                name="lng"
                size="medium"
                helperText={false}
                value={newPointLng}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={classes.modalButtonRow}>
            <Button
              className={classes.submitButton}
              onClick={handleSubmit}
              disabled={newPointName.length === 0 || displayErrorNameExists}
            >
              {t('buttons.save')}
            </Button>
          </div>
        </div>
      </BaseMapPopupContent>
    </BaseMapPopup>
  );
};

NewPointForm.propTypes = {
  pointsList: PropTypes.array,
  newPoint: PropTypes.object.isRequired,
  handleAddNewPointToList: PropTypes.func,
};

export default memo(NewPointForm);
