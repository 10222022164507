import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ tutorialModal }) => ({
  titleContainer: {
    ...tutorialModal.titleContainer,
  },
  title: {
    ...tutorialModal.title,
  },
  iconBg: {
    ...tutorialModal.iconBg,
  },
  tooltipContent: {
    ...tutorialModal.tooltipContent,
    flexDirection: 'column',
  },
  tooltipHint: {
    fontSize: '13px !important',
    color: '#777680 !important',
  },
  dotsContainer: {
    ...tutorialModal.dotsContainer,
  },
  activeDot: {
    ...tutorialModal.activeDot,
  },
  inactiveDot: {
    ...tutorialModal.inactiveDot,
  },
}));
