import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line
import Joyride, { ACTIONS, EVENTS, STATUS, LIFECYCLE } from 'react-joyride';
import { MapPin, Database, ChartBar, Graph } from 'phosphor-react';

import { TUTORIAL_ZONE_PROJECT_ANALYZE_DISPLAY_STATISTICS } from 'constants/tutorials';
import filterVarIcon from 'assets/images/project/filter/variables.png';

import { useStyles } from './styles';

/**
 * DisplayStatisticsTutorial
 * @param { boolean } forceOpenTutorial // trigger start tutorial from button click
 * @param { function }  handleCloseTutorial // handle update force open tutorial in parent component
 * @param { number } selectedPointId // handle selected point in side menu
 * @param { string } selectedDataset // handle selected dataset in side menu
 * @param { string } selectedType // handle selected statistic type in side menu
 * @param { object } selectedVariable // handle selected variable in side menu
 * @param { function } scrollIntoSideMenu

 * @returns {jsx}
 */
const DisplayStatisticsTutorial = ({
  forceOpenTutorial,
  handleCloseTutorial,
  selectedPointId,
  selectedDataset,
  selectedType,
  selectedVariable,
  scrollIntoSideMenu,
}) => {
  const classes = useStyles();

  const STEPS = [
    // Hack - ghost step as index 0 to fix bug first step must be placement center
    {
      placement: 'center',
      target: 'body',
      content: '',
      styles: {
        buttonNext: {
          display: 'none',
        },
      },
    },
    // 1rst step
    {
      placement: 'auto',
      target: '#projectAnalyzePointSelection',
      title: (
        <div className={classes.dotsContainer}>
          <div className={classes.activeDot}></div>
          <div className={classes.inactiveDot}></div>
          <div className={classes.inactiveDot}></div>
          <div className={classes.inactiveDot}></div>
          <div className={classes.inactiveDot}></div>
        </div>
      ),
      content: (
        <>
          <div className={classes.titleContainer}>
            <MapPin className={classes.iconBg} />
            <div className={classes.title}>Analyze Your Selected Point</div>
          </div>
          <div className={classes.tooltipContent}>
            <p>Select the desired point for statistics analysis.</p>
          </div>
        </>
      ),
      styles: {
        buttonNext: {
          borderRadius: 50,
          border: 0,
          color: '#fff',
          backgroundColor: '#4451c7',
          textTransform: 'none',
          padding: '10px 24px',
          fontSize: 14,
          margin: '0 25%',
          width: '100%',
        },
        buttonBack: {
          display: 'none',
        },
      },
      locale: {
        next: 'Next: Datasets',
      },
      spotlightClicks: true,
      disableOverlayClose: true,
    },
    // 2nd step
    {
      placement: 'auto',
      target: '#fields',
      title: (
        <div className={classes.dotsContainer}>
          <div className={classes.activeDot}></div>
          <div className={classes.activeDot}></div>
          <div className={classes.inactiveDot}></div>
          <div className={classes.inactiveDot}></div>
          <div className={classes.inactiveDot}></div>
        </div>
      ),
      content: (
        <>
          <div className={classes.titleContainer}>
            <Database className={classes.iconBg} />
            <div className={classes.title}>Choose a Dataset</div>
          </div>
          <div className={classes.tooltipContent}>
            <p>Select a field dataset you wish to analyze.</p>
          </div>
        </>
      ),
      styles: {
        buttonNext: {
          borderRadius: 50,
          border: 0,
          color: '#fff',
          backgroundColor: '#4451c7',
          textTransform: 'none',
          padding: '10px 24px',
          fontSize: 14,
          margin: '0 0 0 40px',
          width: '100%',
        },
        buttonBack: {
          borderRadius: 50,
          color: '#4451c7',
          backgroundColor: '#fff',
          border: '1px solid #777680',
          textTransform: 'none',
          padding: '10px 24px',
          fontSize: 14,
          margin: '0 30px 0 0',
        },
      },
      locale: {
        next: 'Next: Statistic Types',
      },
      spotlightClicks: true,
      disableOverlayClose: true,
    },
    //  3rd step
    {
      placement: 'auto',
      target: '#statistics',
      title: (
        <div className={classes.dotsContainer}>
          <div className={classes.activeDot}></div>
          <div className={classes.activeDot}></div>
          <div className={classes.activeDot}></div>
          <div className={classes.inactiveDot}></div>
          <div className={classes.inactiveDot}></div>
        </div>
      ),
      content: (
        <>
          <div className={classes.titleContainer}>
            <ChartBar className={classes.iconBg} />
            <div className={classes.title}>Select One Statistic Type</div>
          </div>
          <div className={classes.tooltipContent}>
            <p>
              Once a statistic is displayed, you can go back to select another.
              Repeat this process to explore as many statistics as you like.
            </p>
            <p className={classes.tooltipHint}>
              Click the ‘?’ icon for more details about each statistic.
            </p>
          </div>
        </>
      ),
      styles: {
        buttonNext: {
          borderRadius: 50,
          border: 0,
          color: '#fff',
          backgroundColor: '#4451c7',
          textTransform: 'none',
          padding: '10px 24px',
          fontSize: 14,
          width: '100%',
        },
        buttonBack: {
          borderRadius: 50,
          color: '#4451c7',
          backgroundColor: '#fff',
          border: '1px solid #777680',
          textTransform: 'none',
          padding: '10px 24px',
          fontSize: 14,
          margin: '0 30px 0 0',
        },
      },
      locale: {
        next: 'Next: Variable Parameters',
      },
      spotlightClicks: true,
      disableOverlayClose: true,
    },
    //  4th step
    {
      placement: 'auto',
      target: '#variables',
      title: (
        <div className={classes.dotsContainer}>
          <div className={classes.activeDot}></div>
          <div className={classes.activeDot}></div>
          <div className={classes.activeDot}></div>
          <div className={classes.activeDot}></div>
          <div className={classes.inactiveDot}></div>
        </div>
      ),
      content: (
        <>
          <div className={classes.titleContainer}>
            <img src={filterVarIcon} alt="" className={classes.iconBg} />
            <div className={classes.title}>Select a Variable Parameter</div>
          </div>
          <div className={classes.tooltipContent}>
            <p>Choose a variable to visualize.</p>
          </div>
        </>
      ),
      styles: {
        buttonNext: {
          borderRadius: 50,
          border: 0,
          color: '#fff',
          backgroundColor: '#4451c7',
          textTransform: 'none',
          padding: '10px 24px',
          fontSize: 14,
          width: '100%',
        },
        buttonBack: {
          borderRadius: 50,
          color: '#4451c7',
          backgroundColor: '#fff',
          border: '1px solid #777680',
          textTransform: 'none',
          padding: '10px 24px',
          fontSize: 14,
          margin: '0 30px 0 0',
        },
      },
      locale: {
        next: 'Next: Display Statistics',
      },
      spotlightClicks: true,
      disableOverlayClose: true,
    },
    // Last step
    {
      placement: 'auto',
      target: '#displayStatistic',
      title: (
        <div className={classes.dotsContainer}>
          <div className={classes.activeDot}></div>
          <div className={classes.activeDot}></div>
          <div className={classes.activeDot}></div>
          <div className={classes.activeDot}></div>
          <div className={classes.activeDot}></div>
        </div>
      ),
      content: (
        <>
          <div className={classes.titleContainer}>
            <Graph className={classes.iconBg} />
            <div className={classes.title}>Your Filters Have Been Applied</div>
          </div>
          <div className={classes.tooltipContent}>
            <p>
              Click <strong>Display Statistics</strong> to view graphical data
              for your point selection.
            </p>
          </div>
        </>
      ),
      styles: {
        buttonNext: {
          borderRadius: 50,
          border: 0,
          color: '#fff',
          backgroundColor: '#4451c7',
          textTransform: 'none',
          padding: '10px 24px',
          fontSize: 14,
          width: '100%',
          margin: '0 0 0 40px',
        },
        buttonBack: {
          borderRadius: 50,
          color: '#4451c7',
          backgroundColor: '#fff',
          border: '1px solid #777680',
          textTransform: 'none',
          padding: '10px 24px',
          fontSize: 14,
          margin: '0 30px 0 0',
        },
      },
      locale: {
        last: 'Got it',
      },
      spotlightClicks: true,
    },
  ];

  // Init tutorial if no point on projects
  const [runTutorial, setRunTutorial] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps] = useState(STEPS);

  const [handlerSelectedPointId, setHandlerSelectedPointId] = useState(
    selectedPointId
  );
  const [handlerSelectedDataset, setHandlerSelectedDataset] = useState(
    selectedDataset
  );
  const [handlerSelectedType, setHandlerSelectedType] = useState(selectedType);
  const [handlerSelectedVariable, setHandlerSelectedVariable] = useState(
    selectedVariable
  );

  // Init tutorial if click on button start
  useEffect(() => {
    if (forceOpenTutorial) {
      handleInitStep();
      setTimeout(() => {
        setRunTutorial(true);
      }, '50');
    } else {
      resetTutorial();
    }
  }, [forceOpenTutorial]);

  // Go next step auto when point is selected
  useEffect(() => {
    setHandlerSelectedPointId(selectedPointId);
    if (selectedPointId && stepIndex === 1) {
      goToStepIndex(2);
    }
  }, [selectedPointId]);

  // Go next step auto when dataset is selected
  useEffect(() => {
    setHandlerSelectedDataset(selectedDataset);
    if (selectedDataset && stepIndex === 2) {
      goToStepIndex(3);
    }
  }, [selectedDataset]);

  // Go next step auto when statistic type is selected
  useEffect(() => {
    setHandlerSelectedType(selectedType);
    if (selectedType && stepIndex === 3) {
      goToStepIndex(4);
    }
  }, [selectedType]);

  // Go next step auto when variable is selected
  useEffect(() => {
    setHandlerSelectedVariable(selectedVariable);
    if (selectedVariable && stepIndex === 4) {
      goToStepIndex(5);
    }
  }, [selectedVariable]);

  // Need to set our running state to false, so we can restart if we click start again.
  const resetTutorial = () => {
    setRunTutorial(false);
    setStepIndex(1); // Hack stepindex 0 is ghost step - go to index 1
    handleCloseTutorial(TUTORIAL_ZONE_PROJECT_ANALYZE_DISPLAY_STATISTICS);
  };

  const handleInitStep = () => {
    setRunTutorial(false); // reset
    // Start tutorial at corresponding step according to fields already selected
    if (
      handlerSelectedPointId &&
      !handlerSelectedDataset &&
      !handlerSelectedType &&
      !handlerSelectedVariable
    ) {
      goToStepIndex(2);
    } else if (
      handlerSelectedPointId &&
      handlerSelectedDataset &&
      !handlerSelectedType &&
      !handlerSelectedVariable
    ) {
      goToStepIndex(3);
    } else if (
      handlerSelectedPointId &&
      handlerSelectedDataset &&
      handlerSelectedType &&
      !handlerSelectedVariable
    ) {
      goToStepIndex(4);
    } else if (
      handlerSelectedPointId &&
      handlerSelectedDataset &&
      handlerSelectedType &&
      handlerSelectedVariable
    ) {
      goToStepIndex(5);
    } else {
      goToStepIndex(1); // Hack stepindex 0 is ghost step - go to index 1
    }
  };

  const goToStepIndex = (index) => {
    scrollIntoSideMenu(index);
    setStepIndex(index);
  };

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type, lifecycle } = data;
    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
      (status === STATUS.PAUSED && action !== ACTIONS.NEXT) ||
      (action === ACTIONS.CLOSE && lifecycle === LIFECYCLE.COMPLETE) ||
      action === ACTIONS.STOP
    ) {
      resetTutorial();
    } else if (
      index === stepIndex &&
      [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)
    ) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      goToStepIndex(nextStepIndex);
    }
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      run={runTutorial}
      stepIndex={stepIndex}
      steps={steps}
      continuous
      disableScrolling
      styles={{
        options: {
          zIndex: 10000,
        },
        beacon: {
          display: 'none',
        },
        tooltip: {
          borderRadius: 16,
          padding: '15px',
        },
        tooltipFooter: {
          marginTop: 0,
        },
        floaterStyles: {
          arrow: {
            display: 'none',
          },
        },
      }}
    />
  );
};

DisplayStatisticsTutorial.propTypes = {
  forceOpenTutorial: PropTypes.bool,
  handleCloseTutorial: PropTypes.func,
  selectedPointId: PropTypes.number,
  selectedDataset: PropTypes.string,
  selectedType: PropTypes.string,
  selectedVariable: PropTypes.object,
  scrollIntoSideMenu: PropTypes.func,
};

export default React.memo(DisplayStatisticsTutorial);
