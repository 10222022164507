import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { MapPin, MapTrifold, TagChevron } from 'phosphor-react';
import Checkbox from '@material-ui/core/Checkbox';

import { TUTORIAL_ZONE_PROJECT_QUERY_CREATE_POINT } from 'constants/tutorials';
import { SETTINGS_KEY } from 'constants/common';

import { useStyles } from './styles';

/**
 * CreatePointTutorial
 * @param { number } projectPoints
 * @param { Boolean } isDemo
 * @param { boolean } openCreatePointModal
 * @param { boolean } forceOpenTutorial // trigger start tutorial from button click
 * @param { function }  handleCloseTutorial // handle update force open tutorial in parent component
 * @returns {jsx}
 */
const CreatePointTutorial = ({
  projectPoints,
  isDemo,
  openCreatePointModal,
  forceOpenTutorial,
  handleCloseTutorial,
}) => {
  const classes = useStyles();

  // Init tutorial if no point on projects
  const [runTutorial, setRunTutorial] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState(STEPS);
  const [
    handlerOpenCreatePointModal,
    setHandlerOpenCreatePointModal,
  ] = useState(openCreatePointModal);
  const [dismissAllTutorials, setDismissAllTutorials] = useState(false);

  // Warning : set checkboxHandler before STEPS
  const checkboxHandler = useCallback(
    ({ target }) => {
      const localStorageSettings = JSON.parse(
        localStorage.getItem(SETTINGS_KEY)
      );
      const localStorageDismissValue =
        localStorageSettings?.dismissAllTutorials;
      // Reset dismiss value in local storage setting if existant
      if (localStorageDismissValue)
        delete localStorageSettings.dismissAllTutorials;
      // Update value in localstorage
      localStorage.setItem(
        SETTINGS_KEY,
        JSON.stringify({
          ...localStorageSettings,
          dismissAllTutorials: target.checked,
        })
      );
      setDismissAllTutorials(target.checked);
    },
    [setDismissAllTutorials]
  );

  const STEPS = [
    // 1rst step
    {
      title: (
        <div className={classes.titleContainer}>
          <MapPin className={classes.iconBg} />
          <div className={classes.title}>Create a point</div>
        </div>
      ),
      content: (
        <>
          <div className={classes.tooltipContent}>
            <p>
              Save all desired points on the map and then access the computed
              statistics.
            </p>
          </div>
          <div className={classes.checkboxContainer}>
            <Checkbox
              name="dismissTutorial"
              checked={dismissAllTutorials}
              onChange={checkboxHandler}
              color="primary"
            />
            <p className={classes.checkboxLabel}>Dismiss all tutorials</p>
          </div>
        </>
      ),
      placement: 'center',
      target: 'body',
      styles: {
        tooltipContent: {
          padding: '20px 10px 5px 10px',
        },
        buttonNext: {
          borderRadius: 50,
          border: 0,
          color: '#fff',
          backgroundColor: '#4451c7',
          textTransform: 'none',
          padding: '10px 24px',
          fontSize: 14,
          marginRight: 10,
          width: '45%',
        },
        buttonSkip: {
          borderRadius: 50,
          border: 0,
          color: '#4451c7',
          backgroundColor: '#fff',
          boxShadow:
            '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
          textTransform: 'none',
          padding: '8px 25px',
          fontSize: 14,
          marginLeft: 10,
          fontWeight: 600,
          width: '85%',
        },
      },
      locale: {
        next: "Let's Begin",
        skip: 'Close',
      },
      showSkipButton: true,
    },
    // 2nd step
    {
      target: '#ProjectMapContainer',
      placement: 'top-end',
      offset: -250,
      spotlightPadding: 0,
      title: (
        <div className={classes.titleContainer}>
          <MapTrifold className={classes.iconBg} />
          <div className={classes.title}>Select point location</div>
        </div>
      ),
      content: (
        <div className={classes.tooltipContent}>
          <p>Click on map where you want to set point.</p>
        </div>
      ),
      styles: {
        buttonNext: {
          display: 'none',
        },
        floaterStyles: {
          arrow: {
            display: 'none',
          },
        },
      },
      spotlightClicks: true,
      disableOverlayClose: true,
    },
    // 3rst step
    {
      target: '#ProjectMapContainer',
      placement: 'top-end',
      offset: -400,
      spotlightPadding: 0,
      title: (
        <div className={classes.titleContainer}>
          <TagChevron className={classes.iconBg} />
          <div className={classes.title}>Name Your Point</div>
        </div>
      ),
      content: (
        <div className={classes.tooltipContent}>
          <p>Assign a name to your point and save.</p>
        </div>
      ),
      styles: {
        buttonNext: {
          display: 'none',
        },
      },
      spotlightClicks: true,
      disableOverlayClose: true,
    },
    // Last step
    {
      target: '#ButtonComputeStatistics',
      placement: 'auto',
      title: (
        <div className={classes.titleContainer}>
          <MapPin className={classes.iconBg} />
          <div className={classes.title}>Point Succesfully Created!</div>
        </div>
      ),
      content: (
        <div className={classes.tooltipContent}>
          <p>
            Click <strong>Compute Statistics</strong> to analyze the data.
          </p>
        </div>
      ),
      styles: {
        buttonNext: {
          display: 'none',
        },
      },
    },
  ];
  const lastStepIndex = STEPS.length - 1;

  // Init tutorial if click on button start
  useEffect(() => {
    if (forceOpenTutorial) {
      setRunTutorial(true);
      setStepIndex(0);
    }
  }, [forceOpenTutorial]);

  // Init auto tutorial if no points and no dismissAllTutorials
  useEffect(() => {
    // Check settings in localstorage at init
    const localStorageSettings = JSON.parse(localStorage.getItem(SETTINGS_KEY));
    const localStorageDismissValue = localStorageSettings?.dismissAllTutorials;
    // If not set, init value to false
    if (!localStorageDismissValue) {
      localStorage.setItem(
        SETTINGS_KEY,
        JSON.stringify({ ...localStorageSettings, dismissAllTutorials: false })
      );
    } else {
      // Else, update state with value from local storage
      setDismissAllTutorials(localStorageDismissValue);
    }
    if (projectPoints === 0 && !isDemo && !localStorageDismissValue) {
      setTimeout(() => {
        setRunTutorial(true);
        setStepIndex(0);
      }, '50');
    }
  }, []);

  // Update steps display with updated value of dismissAllTutorials
  useEffect(() => {
    setSteps(STEPS);
  }, [dismissAllTutorials]);

  // Go next step auto when createpoint popup is open
  useEffect(() => {
    if (openCreatePointModal) {
      setHandlerOpenCreatePointModal(true);
      handleNextStep();
    }
  }, [openCreatePointModal]);

  // Go last step auto when new point is created
  useEffect(() => {
    if (stepIndex === 2) {
      setStepIndex(3);
    }
  }, [projectPoints]);

  // Need to set our running state to false, so we can restart if we click start again.
  const resetTutorial = () => {
    setRunTutorial(false);
    setStepIndex(0);
    handleCloseTutorial(TUTORIAL_ZONE_PROJECT_QUERY_CREATE_POINT);
  };

  const handleNextStep = () => {
    setStepIndex(stepIndex + 1);
  };

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
      action === ACTIONS.CLOSE ||
      action === ACTIONS.STOP
    ) {
      resetTutorial();
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      if (index === 0 && handlerOpenCreatePointModal) {
        setStepIndex(2);
      } else if (index < lastStepIndex) {
        setStepIndex(nextStepIndex);
      }
    } else if (index === lastStepIndex) {
      setHandlerOpenCreatePointModal(false); // Reset
    } else if (index === 0 && action === 'prev') {
      // If restart tutorial after delete point and open create point modal, reset and force restart after delay
      resetTutorial();
      setTimeout(() => {
        setRunTutorial(true);
        setStepIndex(0);
      }, '50');
    }
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      run={runTutorial}
      stepIndex={stepIndex}
      steps={steps}
      continuous
      hideBackButton
      styles={{
        options: {
          zIndex: 10000,
        },
        beacon: {
          display: 'none',
        },
        tooltip: {
          borderRadius: 16,
          padding: '15px 10px',
        },
        tooltipFooter: {
          marginTop: 0,
        },
        floaterStyles: {
          arrow: {
            display: 'none',
          },
        },
      }}
    />
  );
};

CreatePointTutorial.propTypes = {
  projectPoints: PropTypes.number.isRequired,
  isDemo: PropTypes.bool.isRequired,
  openCreatePointModal: PropTypes.bool,
  forceOpenTutorial: PropTypes.bool,
  handleCloseTutorial: PropTypes.func,
};

export default React.memo(CreatePointTutorial);
