import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ tutorialModal }) => ({
  titleContainer: {
    ...tutorialModal.titleContainer,
  },
  title: {
    ...tutorialModal.title,
  },
  iconBg: {
    ...tutorialModal.iconBg,
  },
  tooltipContent: {
    ...tutorialModal.tooltipContent,
  },
}));
