import React, { useEffect, useCallback } from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ChooseOfferType from 'components/modals/ChooseOfferType';
import { selectOffers } from 'ducks/offers/selectors';
import { requestOffers } from 'ducks/offers/actions';
import { trackOfferSelection } from 'ducks/trackers/actions/projectManagment';
import { useModal } from 'hooks/useModal';
import { ROUTES } from 'constants/routes';
import { selectCurrentUser } from 'ducks/user/selectors';
import { prepareTrialProjectName } from 'helpers/projects';
import { createProjectWithBulkPoints } from 'ducks/app/actions';

/**
 * ChooseProjectTypeModal - container for choose offers modal
 * @param {String} id
 * @returns {jsx}
 */
const ChooseProjectTypeModal = ({ id }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const offers = useSelector(selectOffers);
  const notFreeTrial = offers.filter((offer) => offer.name !== 'Free Trial');
  const notExpiredOffers = notFreeTrial.filter((offer) => !offer.expired);
  const currentUser = useSelector(selectCurrentUser);

  const { onClose } = useModal(id);

  useEffect(() => {
    dispatch(requestOffers());
  }, [dispatch]);

  const redirectToCreateProject = useCallback(
    ({ id: offerInstance, name, isTrial, zones: [zone] }) => {
      // GTM tracking data
      dispatch(trackOfferSelection(name));

      if (!isTrial) {
        history.push(
          generatePath(ROUTES.createProject, { offerId: offerInstance })
        );
        return onClose();
      }
      dispatch(
        createProjectWithBulkPoints({
          isTrial,
          collaborators: [currentUser.id],
          offerInstance,
          zone: zone.id,
          name: prepareTrialProjectName({
            offerName: name,
            zoneName: zone.name,
          }),
        })
      );
    },
    [history, currentUser.id, dispatch, onClose]
  );

  useEffect(() => {
    if (
      notExpiredOffers.length === 1 &&
      notExpiredOffers.some((offer) => offer.name === 'On Demand')
    ) {
      const onDemandeOffer = notExpiredOffers.find(
        (offer) => offer.name === 'On Demand'
      );
      redirectToCreateProject({
        id: onDemandeOffer.id,
        name: onDemandeOffer.name,
        isTrial: onDemandeOffer.isTrial,
        zones: onDemandeOffer.zones,
      });
    }
  }, [notExpiredOffers, redirectToCreateProject]);

  return (
    <ChooseOfferType
      offers={notExpiredOffers}
      onClose={onClose}
      onSelect={redirectToCreateProject}
    />
  );
};

ChooseProjectTypeModal.propTypes = {
  id: PropTypes.string.isRequired,
};

export default React.memo(ChooseProjectTypeModal);
