const prefix = '@@app';

export const APP_INIT = `${prefix}/APP_INIT`;
export const APP_HIJACK_MODE_ENABLE = `${prefix}/APP_HIJACK_MODE_ENABLE`;
export const APP_HIJACK_EJECT = `${prefix}/APP_HIJACK_MODE_STOP`;

export const CREATE_PROJECT_WITH_BULK_POINTS = `${prefix}/CREATE_PROJECT_WITH_BULK_POINTS`;
export const CREATE_COMPUTED_PROJECT_WITH_REDIRECT = `${prefix}/CREATE_COMPUTED_PROJECT_WITH_REDIRECT`;

export const INVITE_USER_WAITING_REQUEST = `${prefix}/INVITE_USER_WAITING_REQUEST`;

export const UPDATE_PROJECT_COLLABORATORS_WAITING_REQUEST = `${prefix}/UPDATE_PROJECT_COLLABORATORS_WAITING_REQUEST`;

export const EDIT_USER_PROFILE_WAITING_REQUEST = `${prefix}/EDIT_USER_PROFILE_WAITING_REQUEST`;

export const RESEND_USER_INVITATION_WAITING_REQUEST = `${prefix}/RESEND_USER_INVITATION_WAITING_REQUEST`;

export const REVOKE_USER_INVITATION_WAITING_REQUEST = `${prefix}/REVOKE_USER_INVITATION_WAITING_REQUEST`;

export const UPLOAD_PLANNING_WAITING_REQUEST = `${prefix}/UPLOAD_PLANNING_WAITING_REQUEST`;

export const CREATE_PLANNING_WAITING_REQUEST = `${prefix}/CREATE_PLANNING_WAITING_REQUEST`;

export const USER_SIGNUP_WAITING_REQUEST = `${prefix}/USER_SIGNUP_WAITING_REQUEST`;

export const REQUEST_INIT = `${prefix}/REQUEST_INIT`;
