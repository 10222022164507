import React, { useCallback } from 'react';
import { Database, Wind, ThermometerSimple, Waves, Drop } from 'phosphor-react';
import classNames from 'classnames';

import wavesIcon from 'assets/images/icons/waves.svg';
import currentsIcon from 'assets/images/icons/currents.svg';
import calendarIcon from 'assets/images/icons/calendar-dots.svg';
import gridIcon from 'assets/images/icons/grid-nine.svg';

import { useStyles } from './styles';

const DatasetIcon = ({ id, customClass, customSize, handleClick }) => {
  const classes = useStyles();

  const handleOnClick = useCallback(() => {
    handleClick(id);
  });

  switch (id) {
    case 'database':
      return <Database size={customSize || 24} color="#0F154B" />;
    case 'resolution':
      return <img src={gridIcon} alt="" width={customSize || '24'} />;
    case 'calendar':
      return <img src={calendarIcon} alt="" width={customSize || '24'} />;
    case 'wind':
      return (
        <Wind
          onClick={handleOnClick}
          size={customSize || 24}
          color="#0F154B"
          className={classNames({
            [classes[customClass]]: customClass,
          })}
        />
      );
    case 'waves':
      return (
        <img
          src={wavesIcon}
          onClick={handleOnClick}
          role="presentation"
          alt=""
          width={customSize || '24'}
          className={classNames({
            [classes[customClass]]: customClass,
          })}
        />
      );
    case 'waterHeights':
      return (
        <Waves
          size={customSize || 24}
          onClick={handleOnClick}
          color="#0F154B"
          className={classNames({
            [classes[customClass]]: customClass,
          })}
        />
      );
    case 'currents':
      return (
        <img
          src={currentsIcon}
          onClick={handleOnClick}
          role="presentation"
          alt=""
          width={customSize || '24'}
          className={classNames({
            [classes[customClass]]: customClass,
          })}
        />
      );
    case 'salinity':
      return (
        <Drop
          onClick={handleOnClick}
          size={customSize || 24}
          color="#0F154B"
          className={classNames({
            [classes[customClass]]: customClass,
          })}
        />
      );
    case 'temperature':
      return (
        <ThermometerSimple
          onClick={handleOnClick}
          size={customSize || 24}
          color="#0F154B"
          className={classNames({
            [classes[customClass]]: customClass,
          })}
        />
      );
    default:
      break;
  }
};

export default React.memo(DatasetIcon);
