import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ buttons }) => ({
  downloadProgress: {
    marginLeft: '45%',
  },
  progressBar: {
    textAlign: 'center',
  },
  actionButtons: {
    ...buttons.outlined.secondary,
  },
}));
