import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, buttons }) => ({
  container: {
    width: 800,
  },
  subtitle: {
    textAlign: 'center',
    marginBottom: 20,
  },
  listWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  itemWrapper: {
    border: `1px solid ${palette.sinay.neutral[80]}`,
    padding: 10,
    width: '100%',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  selectedItemWrapper: {
    border: `1px solid ${palette.sinay.primary['50']}`,
    backgroundColor: '#FBF8FF',
  },
  name: {
    fontWeight: 600,
    marginBottom: 5,
  },
  label: {
    fontWeight: 600,
  },
  description: {
    fontSize: 12,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cancelButton: {
    ...buttons.outlined.primary,
  },
  saveButton: {
    ...buttons.fill.primary,
  },
  detailsContainer: {
    margin: '8px 0',
    width: '100%',
  },
  subtitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: 10,
  },
  itemName: {
    fontSize: 14,
    color: '#404040',
    fontWeight: 600,
  },
  cursor: {
    cursor: 'pointer',
  },
  dimensionSelectorWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 25,
  },
  iconsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
  },
  detailsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  dimensionLabel: {
    textTransform: 'uppercase',
    color: palette.sinay.primary['40'],
    fontWeight: 600,
    fontSize: 14,
  },
  dimensionDetailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  },
  dimensionDetails2ndRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: 5,
  },
  dimensionDetailsItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 5,
    fontSize: 11,
    width: '30%',
    wordBreak: 'break-all',
  },
  dimensionDetailsItemNotesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 5,
    fontSize: 11,
    width: '60%',
    wordBreak: 'break-all',
  },
  dimensionDetailsContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    fontSize: 12,
  },
  dimensionDetailsItemLabel: {
    fontWeight: 600,
  },
}));
