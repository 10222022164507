import { generatePath } from 'react-router-dom';

import request from 'helpers/request';
import { ZONES_URL, ZONE_URL, MATCHING_ZONES_URL } from 'constants/api';

export const getZones = () => request.get(ZONES_URL);

export const getZone = (id) => request.get(generatePath(ZONE_URL, { id }));

export const getMatchingZones = ({ bounds, offerId }) =>
  request.post(generatePath(MATCHING_ZONES_URL, { id: offerId }), {
    ...bounds,
  });
