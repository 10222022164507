import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useHistory, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { AppBar, Toolbar, MenuItem, MenuList, Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import {
  trackLogoClick,
  trackMyProjectsClick,
} from 'ducks/trackers/actions/navigation';
import { CONTACT_US_MODAL } from 'constants/modals';
import logo from 'assets/images/logo_sinay.svg';
import { ROUTES } from 'constants/routes';
import { UserBar } from 'components/common/UserBar';
import NativeLink from 'components/common/NativeLink';
import { selectCurrentUserIsManager } from 'ducks/user/selectors';
import { CreateProjectButton } from 'components/projects';
import ProjectCollaborators from 'containers/projects/ProjectCollaborators';
import { useModal } from 'hooks/useModal';
import { trackCreateProject } from 'ducks/trackers/actions/projectManagment';
import { selectAppIsInHijackMode } from 'ducks/app/selectors';

import { useStyles } from './styles';

/**
 * Navbar component.
 * Adds header with mui AppBar.
 * @param { Array } links
 * @param { string|node } header
 * @param { user } user
 * @param { string|node } rightToolbar
 * @param { function } inviteOnClick
 * @param { function } logout
 * @param { boolean } isUserBarNotUsed
 */
const Navbar = ({ links, user, logout, projectMenu, isUserBarNotUsed }) => {
  const hijackMode = useSelector(selectAppIsInHijackMode);
  const classes = useStyles({ hasMenu: !!projectMenu });
  const isManager = useSelector(selectCurrentUserIsManager);
  const location = useLocation();
  const specificRoutes = ['/project', '/downtime', '/data-validation'];
  const history = useHistory();

  const { t } = useTranslation();
  const { openModal } = useModal();

  const dispatch = useDispatch();
  // track click on logo
  const sendLogoClickTrackingData = useCallback(
    () => dispatch(trackLogoClick({})),
    [dispatch]
  );

  // track click on 'My Projects'
  const sendMyProjectsClickTrackingData = useCallback(
    () => dispatch(trackMyProjectsClick({})),
    [dispatch]
  );

  // redirect to page new project creation
  const openNewProjectPage = useCallback(() => {
    history.push(generatePath(ROUTES.newProject));
    dispatch(trackCreateProject());
  }, [history]);

  const openContactUsModale = useCallback(() => {
    openModal(CONTACT_US_MODAL);
  }, [openModal]);

  const shouldDisplayProjectButton = (path) => path.pathname === '/';

  const shouldDisplaySpecificButton = (path, routes) =>
    routes.some((route) => path.pathname.startsWith(route));

  return (
    <>
      <AppBar className={hijackMode ? classes.hijackAppBar : classes.appBar}>
        <Toolbar
          className={classNames(classes.toolbar, {
            [classes.smallToolbar]: isUserBarNotUsed,
          })}
        >
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className={classes.navebarContainer}
            >
              <Grid item className={classes.navbarItem}>
                <Link
                  title="home"
                  to={ROUTES.home}
                  className={classes.logo}
                  onClick={sendLogoClickTrackingData}
                >
                  <img src={logo} alt={t('alts.logo')} />
                </Link>
                <div className={classes.menuContainer}>
                  <MenuList className={classes.menuItems}>
                    {links.map(({ url, text, isRelative, active }) => {
                      const LinkComponent = isRelative ? Link : NativeLink;
                      const linkClassName = classNames(classes.link, {
                        active,
                      });
                      if (url === ROUTES.home) {
                        return (
                          <MenuItem key={url} className={classes.linkContainer}>
                            <LinkComponent
                              to={url}
                              className={linkClassName}
                              onClick={sendMyProjectsClickTrackingData}
                            >
                              {text}
                            </LinkComponent>
                          </MenuItem>
                        );
                      }
                      if (url === 'CONTACT_US') {
                        return (
                          <MenuItem key={url} className={classes.linkContainer}>
                            <LinkComponent
                              onClick={openContactUsModale}
                              className={linkClassName}
                            >
                              <span className={classes.link}>{text}</span>
                            </LinkComponent>
                          </MenuItem>
                        );
                      }
                      return (
                        <MenuItem key={url} className={classes.linkContainer}>
                          <LinkComponent to={url} className={linkClassName}>
                            {text}
                          </LinkComponent>
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </div>

                {isManager && shouldDisplayProjectButton(location) && (
                  <CreateProjectButton onClick={openNewProjectPage} />
                )}

                {isManager &&
                  shouldDisplaySpecificButton(location, specificRoutes) && (
                    <ProjectCollaborators />
                  )}

                {!isUserBarNotUsed && (
                  <UserBar
                    user={user}
                    wrapperClass={classes.avatar}
                    loginUrl={ROUTES.login}
                    logout={logout}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.projectMenuContainer}
          >
            <Grid item className={classes.projectMenuItem}>
              {projectMenu}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Toolbar className={classes.offset} />
    </>
  );
};

Navbar.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      text: PropTypes.string,
      isRelative: PropTypes.bool,
    })
  ).isRequired,
  user: PropTypes.object,
  logout: PropTypes.func,
  projectMenu: PropTypes.node,
  isUserBarNotUsed: PropTypes.bool,
};

export default memo(Navbar);
