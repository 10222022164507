import React, { useState, useEffect, useMemo, useCallback } from 'react';
// eslint-disable-next-line
import Joyride, { ACTIONS, EVENTS, STATUS, LIFECYCLE } from 'react-joyride';
import { MapPin, MapTrifold, TagChevron } from 'phosphor-react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';

import {
  selectProjectsData,
  selectProjectsIsSuccess,
} from 'ducks/projects/selectors';
import { TUTORIAL_ZONE_NEW_PROJECT } from 'constants/tutorials';
import { SETTINGS_KEY } from 'constants/common';

import { useStyles } from './styles';

/**
 * NewProjectTutorial
 * @param { boolean } forceOpenTutorial trigger manual start of tutorial
 * @param { boolean } openCreatePointModal if modal create point is open
 * @param { array } pointsList List of points to create on new project
 * @param { number } countOffers Number of offer available in user account
 * @param { function } scrollIntoSideMenu Handle scroll in side menu to corresponding step section
 * @returns {jsx}
 */
const NewProjectTutorial = ({
  forceOpenTutorial,
  openCreatePointModal,
  pointsList,
  countOffers,
  scrollIntoSideMenu,
  handleCloseTutorial,
}) => {
  const classes = useStyles();

  const [runTutorial, setRunTutorial] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState(STEPS);
  const [
    handlerOpenCreatePointModal,
    setHandlerOpenCreatePointModal,
  ] = useState(openCreatePointModal);
  const [dismissAllTutorials, setDismissAllTutorials] = useState(false);

  // Warning : set checkboxHandler before STEPS
  const checkboxHandler = useCallback(
    ({ target }) => {
      const localStorageSettings = JSON.parse(
        localStorage.getItem(SETTINGS_KEY)
      );
      const localStorageDismissValue =
        localStorageSettings?.dismissAllTutorials;
      // Reset dismiss value in local storage setting if existant
      if (localStorageDismissValue)
        delete localStorageSettings.dismissAllTutorials;
      // Update value in localstorage
      localStorage.setItem(
        SETTINGS_KEY,
        JSON.stringify({
          ...localStorageSettings,
          dismissAllTutorials: target.checked,
        })
      );
      setDismissAllTutorials(target.checked);
    },
    [setDismissAllTutorials]
  );

  const STEPS = [
    // index 0 - intro
    {
      title: (
        <div className={classes.titleContainer}>
          <MapPin className={classes.iconBg} />
          <div className={classes.title}>Create A New Project</div>
        </div>
      ),
      content: (
        <>
          <div className={classes.tooltipContent}>
            <p>
              Save a list of points and select the best statistics dataset
              available for your needs.
            </p>
          </div>
          <div className={classes.checkboxContainer}>
            <Checkbox
              name="dismissTutorial"
              checked={dismissAllTutorials}
              onChange={checkboxHandler}
              color="primary"
            />
            <p className={classes.checkboxLabel}>Dismiss all tutorials</p>
          </div>
        </>
      ),
      placement: 'center',
      target: 'body',
      styles: {
        tooltipContent: {
          padding: '20px 10px 5px 10px',
        },
        buttonNext: {
          borderRadius: 50,
          border: 0,
          color: '#fff',
          backgroundColor: '#4451c7',
          textTransform: 'none',
          padding: '10px 24px',
          fontSize: 14,
          marginRight: 10,
          width: '45%',
        },
        buttonSkip: {
          borderRadius: 50,
          border: 0,
          color: '#4451c7',
          backgroundColor: '#fff',
          boxShadow:
            '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
          textTransform: 'none',
          padding: '8px 25px',
          fontSize: 14,
          marginLeft: 10,
          fontWeight: 600,
          width: '85%',
        },
      },
      locale: {
        next: "Let's Begin",
        skip: 'Close',
      },
      showSkipButton: true,
    },
    // index 1 - Click on map
    {
      target: '#NewProjectMapContainer',
      placement: 'top-start',
      offset: -250,
      spotlightPadding: 0,
      title: (
        <div className={classes.dotsContainer}>
          <div className={classes.activeDot}></div>
          <div className={classes.inactiveDot}></div>
          <div className={classes.inactiveDot}></div>
          <div className={classes.inactiveDot}></div>
        </div>
      ),
      content: (
        <>
          <div className={classes.titleContainer}>
            <MapTrifold className={classes.iconBg} />
            <div className={classes.title}>Select point location</div>
          </div>
          <div className={classes.tooltipContent}>
            <p>Click on the map to set point location.</p>
          </div>
        </>
      ),
      styles: {
        buttonNext: {
          display: 'none',
        },
        floaterStyles: {
          arrow: {
            display: 'none',
          },
        },
      },
      spotlightClicks: true,
      disableOverlayClose: true,
    },
    // index 2 - Name your point
    {
      target: '#NewProjectMapContainer',
      placement: 'top-start',
      offset: -400,
      spotlightPadding: 0,
      title: (
        <div className={classes.dotsContainer}>
          <div className={classes.activeDot}></div>
          <div className={classes.inactiveDot}></div>
          <div className={classes.inactiveDot}></div>
          <div className={classes.inactiveDot}></div>
        </div>
      ),
      content: (
        <>
          <div className={classes.titleContainer}>
            <TagChevron className={classes.iconBg} />
            <div className={classes.title}>Name your point</div>
          </div>
          <div className={classes.tooltipContent}>
            <p>
              Assign a name to your location, adjust its coordinates and save.
            </p>
          </div>
        </>
      ),
      styles: {
        buttonNext: {
          display: 'none',
        },
      },
      spotlightClicks: true,
      disableOverlayClose: true,
    },
    // index 3 - Offer details
    {
      target: '#NewProjectOffer',
      placement: 'auto',
      title: (
        <div className={classes.dotsContainer}>
          <div className={classes.activeDot}></div>
          <div className={classes.activeDot}></div>
          <div className={classes.inactiveDot}></div>
          <div className={classes.inactiveDot}></div>
        </div>
      ),
      content: (
        <>
          <div className={classes.titleContainer}>
            <MapPin className={classes.iconBg} />
            <div className={classes.title}>Select your Offer</div>
          </div>
          <div className={classes.tooltipContent}>
            <p>
              If your account has several offers, you can choose another offer
              for this project.
            </p>
          </div>
        </>
      ),
      styles: {
        buttonNext: {
          borderRadius: 50,
          border: 0,
          color: '#fff',
          backgroundColor: '#4451c7',
          textTransform: 'none',
          padding: '10px 24px',
          fontSize: 14,
          margin: '0 30%',
          width: '100%',
        },
      },
      locale: {
        next: 'Next',
      },
      spotlightClicks: false,
    },
    // index 4 - Dataset details
    {
      target: '#NewProjectDataset',
      placement: 'auto',
      title: (
        <div className={classes.dotsContainer}>
          <div className={classes.activeDot}></div>
          <div className={classes.activeDot}></div>
          <div className={classes.activeDot}></div>
          <div className={classes.inactiveDot}></div>
        </div>
      ),
      content: (
        <>
          <div className={classes.titleContainer}>
            <MapPin className={classes.iconBg} />
            <div className={classes.title}>Select your Dataset</div>
          </div>
          <div className={classes.tooltipContent}>
            <p>
              Depending on your point and offer selection, several datasets
              (Regional or Worldwide) may be available. Compare their details
              and select the data source best-suited to your needs.
            </p>
          </div>
        </>
      ),
      styles: {
        buttonNext: {
          borderRadius: 50,
          border: 0,
          color: '#fff',
          backgroundColor: '#4451c7',
          textTransform: 'none',
          padding: '10px 24px',
          fontSize: 14,
          margin: '0 30%',
          width: '100%',
        },
      },
      locale: {
        next: 'Got it !',
      },
      spotlightClicks: false,
    },
    // index 5 - Name project (last step)
    {
      target: '#NewProjectName',
      placement: 'auto',
      title: (
        <div className={classes.dotsContainer}>
          <div className={classes.activeDot}></div>
          <div className={classes.activeDot}></div>
          <div className={classes.activeDot}></div>
          <div className={classes.activeDot}></div>
        </div>
      ),
      content: (
        <>
          <div className={classes.titleContainer}>
            <TagChevron className={classes.iconBg} />
            <div className={classes.title}>Save your Project</div>
          </div>
          <div className={classes.tooltipContent}>
            <p>
              Name your project and click Create Project to save your selection
              and open the project. You will then be able to run computations
              and access statistics.
            </p>
          </div>
        </>
      ),
      styles: {
        buttonNext: {
          borderRadius: 50,
          border: 0,
          color: '#fff',
          backgroundColor: '#4451c7',
          textTransform: 'none',
          padding: '10px 24px',
          fontSize: 14,
          margin: '0 30%',
          width: '100%',
        },
      },
      locale: {
        last: 'Finish',
      },
      spotlightClicks: false,
    },
  ];
  const lastStepIndex = STEPS.length - 1;

  const userProjects = useSelector(selectProjectsData);
  const userProjectsSucesss = useSelector(selectProjectsIsSuccess);
  // Check if user has already project with computed points (to remove tutorial start auto)
  const { hasProjectWithComputedPoints, checkComputedPoints } = useMemo(() => {
    let hasComputedPoints = false;
    let isChecked = false;
    if (
      userProjectsSucesss &&
      userProjects &&
      Object.values(userProjects).length > 0
    ) {
      const allPoints = [];
      Object.values(userProjects).forEach((project) => {
        allPoints.push(...project.points);
      });
      if (allPoints && allPoints.length > 0) {
        hasComputedPoints = Boolean(
          allPoints.find((point) => point.isComputed)
        );
        isChecked = true;
      } else {
        isChecked = true; // no points
      }
    } else {
      isChecked = true; // no project
    }
    return {
      hasProjectWithComputedPoints: hasComputedPoints,
      checkComputedPoints: isChecked,
    };
  }, [userProjects, userProjectsSucesss]);

  // Init auto tutorial if no computed project and no dismissAllTutorials
  useEffect(() => {
    // Check settings in localstorage at init
    const localStorageSettings = JSON.parse(localStorage.getItem(SETTINGS_KEY));
    const localStorageDismissValue = localStorageSettings?.dismissAllTutorials;
    // If not set, init value to false
    if (!localStorageDismissValue) {
      localStorage.setItem(
        SETTINGS_KEY,
        JSON.stringify({ ...localStorageSettings, dismissAllTutorials: false })
      );
    } else {
      // Else, update state with value from local storage
      setDismissAllTutorials(localStorageDismissValue);
    }
    // Trigger init auto tutorial if no computed project and no dismissAllTutorials
    if (
      userProjectsSucesss &&
      checkComputedPoints &&
      !hasProjectWithComputedPoints &&
      !localStorageDismissValue
    ) {
      setTimeout(() => {
        setRunTutorial(true);
        setStepIndex(0);
      }, '50');
    }
  }, [checkComputedPoints, hasProjectWithComputedPoints, userProjectsSucesss]);

  // Init tutorial if click on button start
  useEffect(() => {
    if (forceOpenTutorial) {
      handleInitStep();
      setTimeout(() => {
        setRunTutorial(true);
      }, '50');
    } else {
      resetTutorial();
    }
  }, [forceOpenTutorial]);

  // Update steps display with updated value of dismissAllTutorials
  useEffect(() => {
    setSteps(STEPS);
  }, [dismissAllTutorials]);

  // Go next step auto when createpoint popup is open
  useEffect(() => {
    if (openCreatePointModal) {
      setHandlerOpenCreatePointModal(true);
      goToStepIndex(2);
    }
  }, [openCreatePointModal]);

  // Go next step when new point is created
  useEffect(() => {
    if (pointsList.length > 0) {
      setHandlerOpenCreatePointModal(false); // reset
      goToStepIndex(3);
    }
  }, [pointsList]);

  // Start tutorial at corresponding step according to fields already selected
  const handleInitStep = () => {
    setRunTutorial(false); // reset
    if (pointsList && pointsList.length > 0) {
      // Go to step offer if already 1 point created
      goToStepIndex(3);
    } else if (
      pointsList &&
      pointsList.length > 0 &&
      countOffers &&
      countOffers === 1
    ) {
      // Go to step dataset if already 1 point created + only 1 offer available
      goToStepIndex(4);
    } else {
      // Default init 0
      goToStepIndex(0);
    }
  };

  const goToStepIndex = (index) => {
    scrollIntoSideMenu(index);
    setStepIndex(index);
  };

  // Need to set our running state to false, so we can restart if we click start again.
  const resetTutorial = () => {
    setRunTutorial(false);
    setStepIndex(0);
    setHandlerOpenCreatePointModal(false);
    handleCloseTutorial(TUTORIAL_ZONE_NEW_PROJECT);
  };

  const handleJoyrideCallback = (data) => {
    const { action, index, status, lifecycle, type } = data;
    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
      (status === STATUS.PAUSED && action !== ACTIONS.NEXT) ||
      (action === ACTIONS.CLOSE && lifecycle === LIFECYCLE.COMPLETE) ||
      action === ACTIONS.STOP
    ) {
      resetTutorial();
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      if (index === 0 && handlerOpenCreatePointModal) {
        goToStepIndex(2);
      } else if (index < lastStepIndex) {
        goToStepIndex(nextStepIndex);
      } else {
        resetTutorial();
      }
    } else if (index === 0 && action === 'prev') {
      // If restart tutorial after delete point and open create point modal, reset and force restart after delay
      resetTutorial();
      setTimeout(() => {
        setRunTutorial(true);
        goToStepIndex(0);
      }, '50');
    }
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      run={runTutorial}
      stepIndex={stepIndex}
      steps={steps}
      continuous
      hideBackButton
      disableScrollParentFix
      styles={{
        options: {
          zIndex: 10000,
        },
        beacon: {
          display: 'none',
        },
        tooltip: {
          borderRadius: 16,
          padding: '15px 10px',
        },
        tooltipFooter: {
          marginTop: 0,
        },
        floaterStyles: {
          arrow: {
            display: 'none',
          },
        },
      }}
    />
  );
};

NewProjectTutorial.propTypes = {
  forceOpenTutorial: PropTypes.bool,
  openCreatePointModal: PropTypes.bool,
  pointsList: PropTypes.array,
  countOffers: PropTypes.number,
  scrollIntoSideMenu: PropTypes.func,
  handleCloseTutorial: PropTypes.func,
};

export default React.memo(NewProjectTutorial);
